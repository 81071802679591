import React, { useContext, useRef } from "react"
import { FirebaseContext } from "../Firebase/index.js"
import { useReactToPrint } from "react-to-print"
import PortfolioContent from "../portfolio/PortfolioContent.js"
import "./portfolio.css"

/**
 * A user's Portfolio tab
 */
const Portfolio = () => {
  const { profile, loading, user } = useContext(FirebaseContext)

  // this pdf export code is not currently being used
  const portfolioRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => portfolioRef.current,
  })
  const handlePdfExport = () => {
    if (typeof window !== "undefined") {

      setTimeout(function () {
        handlePrint()
      }, 1000)
    }
  }

  if (loading || !profile) {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "10em",
            marginBottom: "10em",
            fontSize: "20px",
          }}
        >
          Loading ...
        </div>
      </>
    )
  }

  return (
    <PortfolioContent 
      profile={profile}
      isPublicFacing={false}
      userId={user.membership.userId}
    />
  )
}

export default Portfolio
