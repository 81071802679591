import {
  createHeader,
  createSkillsInterestsBullet,
  createSpacingLine,
} from "./componentHelpers"
  
export const renderSkillsInterestsSection = (type, items) => {
  if (!type || !items || !items.length) {
    return []
  }
  
  const itemsString = items.join(", ")
  
  return [
    ...createHeader(type.toUpperCase()),
    createSkillsInterestsBullet(itemsString),
    createSpacingLine,
  ]
}
