import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/pro-regular-svg-icons"
import FileDisplayer from "../../user-files/FileDisplayer.js";
import useSortableItem from "../../drag-and-drop/useSortableItem.js";
import "./UploadThumbnail.css"

/**
 * A single, draggable file thumbnail in the storyboard add/edit modal
 * @prop {userFile} file
 * @prop {function} onDelete
 */
const UploadThumbnail = ({file, onDelete}) => {
  const { itemProperties, isDragging, isSorting } = useSortableItem({id: file.fileId})

  return (
    <div className="upload-thumbnail" {...itemProperties}>
      <div className="file-preview">
        <FileDisplayer file={file} isThumbnail={true}/>
      </div>
      <button className="delete-button" onClick={() => onDelete()} style={{display: (isDragging || isSorting) ? "none" : undefined}} >
        <FontAwesomeIcon icon={faTrash} size="1x" />
      </button> 
    </div>
  )
}

export default UploadThumbnail
