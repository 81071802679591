import { createHeader, createBulletPoint, createSpacingLine, createSubHeader } from "./componentHelpers";

export const renderWorkSection = (workExperiences) => {
  if (!workExperiences || workExperiences.length === 0) {
    return []
  }

  return [
    ...createHeader("PROFESSIONAL EXPERIENCE"),
    ...workExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const title = experience.title || "JOB TITLE"
        const employer = experience.employer || "EMPLOYER"
        const startDate = experience.startDate || "STARTDATE"
        const endDate = experience.endDate || "Present"
        const location = experience.location || "LOCATION"
        const bulletPoints = experience.bulletPoints || []

        return [
          ...createSubHeader(title, employer, location, startDate, endDate),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ];
};
