import "@dnd-kit/core"
/**
 * Combine multiple collision detection algorithms into a single one
 * @param {CollisionDetection[]} algorithmList - array of algorithms, in the order they should be attempted
 * @returns {CollisionDetection}
 */
const combineCollisionAlgorithms = (algorithmList) => {
  const finalAlgorithm = algorithmList[algorithmList.length - 1]
  return (args) => {
    for (const algorithm of algorithmList)  {
      const collisions = algorithm(args);
      if (collisions.length > 0 || algorithm === finalAlgorithm) {
        return collisions;
      }
    }
  }
}

export {
  combineCollisionAlgorithms,
}
