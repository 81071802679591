import React from "react"
import ResumeTimelineExperience from "./ResumeTimelineExperience.js"
import "./ResumeTimeline.css"

/**
 * Displays all resume experiences of a single experience type
 */
const ResumeTimeline = ({
  type,
  filteredExperiences,
  isPublicFacing,
}) => {
  const isExperienceArrayFilled = filteredExperiences =>
    Array.isArray(filteredExperiences) && filteredExperiences.length

  if (!isExperienceArrayFilled(filteredExperiences)) {
    return null
  }

  return (
    <div className="resume-timeline">
      {isExperienceArrayFilled(filteredExperiences) &&
        filteredExperiences.map((experienceData, index) => (
          <ResumeTimelineExperience
            key={experienceData.id}
            type={type}
            experienceData={experienceData}
            precedingExperience={
              filteredExperiences[index - 1] ?? null
            }
            isPublicFacing={isPublicFacing}
            isLastExperience={index === filteredExperiences.length - 1}
          />
        ))
      }
    </div>
  )
}

export default ResumeTimeline
