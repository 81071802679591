import React, { useState } from "react"
import ResumeColumns from "./ResumeColumns.js"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal.js"
import { FormattedMessage } from "react-intl"
import { experienceTypeData } from "./utils/experienceTypes"
import useResumeData from "./useResumeData.js"
import "./resume.css"
import "../portfolio/placeholder-section-styles.css"

/**
 * Base component for resume, includes editor and public version
 */
const Resume = ({
  userId,
  isPublicFacing,
}) => {
  const { experiences, skills, interests } = useResumeData(userId)
  const [selectedExperienceType, setSelectedExperienceType] = useState(null)
  const isEmpty = (experiences.length === 0 && skills.length === 0 && interests.length === 0)

  if (isEmpty && isPublicFacing) {
    return null;
  }

  return (
    <>
    {/* Modal is outside of the empty resume component so it doesn't disappear
      * while the user is adding skills/interests */}
      {!isPublicFacing && (
        <ResumeExperienceEditorModal
          isNewExperience={true}
          show={selectedExperienceType !== null}
          type={selectedExperienceType}
          closeTimelineModal={() => setSelectedExperienceType(null)}
        />
      )}
      {isEmpty ? (
        <div className="empty-resume-section">
          <div className="placeholder-title">
            <FormattedMessage
              id="resume"
              defaultMessage="Resume"
            />
          </div>
          <div className="placeholder-instructions">
            <FormattedMessage
              id="resume-placeholder-instructions"
              defaultMessage="To get started, click on the buttons below or the <i>Add Experience</i> button above."
            />
          </div>
          <div className="empty-resume-suggestions">
            {experienceTypeData.map(type => (
              <button 
                key={type.type} 
                className="suggestion-button" 
                onClick={() => setSelectedExperienceType(type.type)}
              >
                {type.label}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="resume">
          <ResumeColumns
            userId={userId}
            isPublicFacing={isPublicFacing}
            experiences={experiences}
            skills={skills}
            interests={interests}
          />
        </div>
      )}
    </>
  )
}

export default Resume
