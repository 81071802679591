const convertMimeToExtension = (mimeType) => {
  switch (mimeType) {
    case "image/jpg":
      return "jpg"
    case "image/jpeg":
      return "jpeg"
    case "image/png":
      return "png"
    case "video/mp4":
      return "mp4"
    case "application/pdf":
      return "pdf"
    case "application/msword":
      return "doc"
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx"
    default:
      return mimeType
  }
}

export default convertMimeToExtension
