import React, { useEffect, useState, useContext } from "react"
import { FirebaseContext } from "../components/Firebase"
import LoadingSpinner from "../components/loading/LoadingSpinner.js"
import PortfolioContent from "../components/portfolio/PortfolioContent.js"
import { navigate } from "gatsby"

const ProfilePage = props => {
  const profileId = props["*"]
  const { firebase } = useContext(FirebaseContext)
  const [profile, setProfile] = useState(null)
  const [loadingPlans, setLoadingPlans] = useState(true)

  const [error, setError] = useState(null)

  useEffect(() => {
    if (firebase && profileId) {
      // attempt to load requested profile
      try {
        const fetchData = async () => {
          const publicProfile = await firebase.loadPublicProfile(profileId)
          if (publicProfile) {
            setProfile(publicProfile)
          } else {
            setError(true)
          }
        }
        fetchData().catch(e => {
          setError(true)
        })
      } catch (e) {
        console.error("error!", e)
      }
    }
  }, [firebase, profileId])

  const isPublicSection = section =>
    profile?.profilePublicSections?.[section] !== false

  if (error) {
    // return <>Bad request</>
    navigate('/403')
  }

  if (!profile && isPublicSection("career_plans") && loadingPlans) {
    return (
      <>
        <LoadingSpinner />
      </>
    )
  }

  return (
    <PortfolioContent
      isPublicFacing={true}
      userId={profileId}
      profile={profile}
    />
  )
}

export default ProfilePage
