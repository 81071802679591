import useWindowDimensions from "./useWindowDimensions";

/**
 * Uses window dimensions to check if the user is on a mobile device.
 * (There could be better ways to determine this)
 * @returns isMobile - true if this is a mobile device
 */
const useCheckIfMobile = () => {
  const { width } = useWindowDimensions()
  const isMobile = width < 480

  return {
    isMobile,
  }
}

export default useCheckIfMobile
