import React, { useState, useContext, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { sendStandardToast } from "../toasts/standard-toast"
import SubmitButton from "../portfolio/SubmitButton"

const PortfolioShareModal = ({ show, togglePortfolioShareModal }) => {
  const [checked, setChecked] = useState(false)
  const { firebase, profile } = useContext(FirebaseContext)
  const location = useLocation()
  const { protocol, hostname, port } = location

  const { locale } = useIntl()

  const handleCheckboxChange = async (e) => {
    if (e.target.checked) {
      handleSubmit()
    } else {
      // Delete the public profile without confirmation
      const success = await firebase.makeProfilePrivate()
      if (!success) {
        console.error("couldnt set firebase public setting")
      } else {
        setChecked(false)
      }
    }
  }

  const handleSubmit = async () => {
    const success = await firebase.makeProfilePublic()
    if (!success) {
      setChecked(false)
    } else {
      setChecked(true)
    }
  }

  const publicPortfolioURL = `https://${hostname}${locale === "es-ES" ? "/es-ES" : ""}/profile/${profile.userId}`

  const handleCopy = () => {
    navigator.clipboard.writeText(publicPortfolioURL)

    sendStandardToast({
      heading: <FormattedMessage id="portfolio-share-model-profile-link-copied" defaultMessage="Profile link copied to clipboard" />,
      type: "success",
    })
  }

  useEffect(() => {
    // set the preview image to current image, once available
    if (profile && profile.profileIsPublic === true) {
      setChecked(true)
    }
  }, [profile])

  return (
    <Modal className="portfolio-share-modal" show={show} onHide={() => togglePortfolioShareModal()} size="md" animation={false}>
      <Modal.Header closeButton>
        <div className="eyebrow component-eyebrow">
          <FormattedMessage id="portfolio-share-model-profile-sharing" defaultMessage="Profile Sharing" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className="toggle-row"
          style={{
            backgroundColor: "whitesmoke",
            fontWeight: "bold",
          }}
        >
          <label style={{ fontWeight: "bold" }}>
            <FormattedMessage id="portfolio-share-model-profile-sharing-is-on" defaultMessage="Profile sharing is" />{" "}
            {checked ? <FormattedMessage id="switch-on" defaultMessage="ON" /> : <FormattedMessage id="switch-off" defaultMessage="OFF" />}
          </label>

          <Form.Check
            id={`portfolio-public-switch-toggle`}
            className="portfolio-toggle"
            type="switch"
            // label={isPublic ? "Public" : "Private"}
            checked={checked}
            onChange={(e) => handleCheckboxChange(e)}
          />
        </div>

        {/* {checked && (
          <>
            <div className="toggle-row">
              <label>
                {" "}
                <FormattedMessage id="portfolio-share-model-personal-information" defaultMessage="Personal information" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="personal_information" />
            </div>{" "}
            <div className="toggle-row">
              <label>
                {" "}
                <FormattedMessage id="portfolio-share-model-resume" defaultMessage="Resume" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="resume" />
            </div>
            <div className="toggle-row">
              <label>
                <FormattedMessage id="portfolio-share-model-interests-and-skills" defaultMessage="Interests and skills" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="interests_skills" />
            </div>{" "}
            <div className="toggle-row">
              <label>
                <FormattedMessage id="portfolio-share-model-assessment" defaultMessage="Assessment" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="assessment" />
            </div>{" "}
            <div className="toggle-row">
              <label>
                <FormattedMessage id="portfolio-share-model-career-plans" defaultMessage="Career plans" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="career_plans" />
            </div>{" "}
            <div className="toggle-row">
              <label>
                <FormattedMessage id="portfolio-share-model-saved" defaultMessage="Saved" />
              </label>
              <PortfolioPublicToggle isBlank={true} section="bookmarks" />
            </div>
          </>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        {checked && (
          <>
            <label style={{ textAlign: "left" }}>
              <FormattedMessage id="portfolio-share-model-public-URL" defaultMessage="Public URL" />
            </label>
            <div style={{ display: "flex", marginBottom: "1rem" }}>
              <input
                type="text"
                style={{
                  backgroundColor: "#fff",
                  fontSize: "14px",
                  width: "90%",
                }}
                defaultValue={publicPortfolioURL}
                disabled={true}
              />
              <button
                style={{ background: "none" }}
                type="submit"
                onClick={handleCopy}
              >
                <FontAwesomeIcon color={"#40777C"} icon={faCopy} size="lg" />
              </button>
            </div>

            <a href={`${locale === "es-ES" ? "/es-ES" : ""}/profile/${profile.userId}`} target="_blank">
              <SubmitButton
                label={<FormattedMessage id="portfolio-share-model-view-public-portfolio" defaultMessage="View Public Portfolio" />}
                isFilled={true}
              />
            </a>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default PortfolioShareModal
