import React, { useContext, useState } from "react"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import OptimizerButton from "./optimizer/optimizer-button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"


/**
 * Provides free form input component for experience data.
 *
 * @param experience
 *   The experience, must implement the `dataKey` passed.
 * @param label
 *   The label of the text field.
 * @param dataKey
 *   The object property to map to the input value.
 * @param inputType
 *   If the input is a text area or text input.
 * @param required
 *   If input should display asterisk or block submission
 *
 * @return {JSX.Element}
 */
const ExperienceTextInput = ({ label, dataKey, placeholder, inputType, required }) => {
  const { experience = {}, setLocalExperienceKeyValue } = useContext(ExperienceEditorContext)

  /**
   * The current value of the input.
   */
  const [textValue, setTextValue] = useState(experience[dataKey])

  /**
   * Handler for the input to update the experience.
   *
   * @param event
   */
  const updateData = (event) => {
    const value = event.target.value
    setTextValue(value)
    setLocalExperienceKeyValue(dataKey, value)
  }

  return (
    <>
      <label>
        {label} {required && "*"}
      </label>
      <div className="form-element" style={{ flexFlow: "unset" }}>
        {inputType === "textarea" ? (
          <div style={{ display: "flex", width: "100%" }}>
            <textarea placeholder={placeholder} value={textValue ?? ""} cols="5" rows={dataKey === "description" && "3"} onChange={updateData} />
            <OptimizerButton type="description" text={textValue} updateData={updateData} />
            <button
              style={{
                backgroundColor: "white",
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
              disabled={true}
            >
              <FontAwesomeIcon icon={faTimes} size="1x" color="white" />
            </button>
          </div>
        ) : (
          <input type="text" placeholder={placeholder} value={textValue ?? ""} onChange={updateData} required={required} />
        )}
      </div>
    </>
  )
}

export default ExperienceTextInput
