import React, { useState } from "react"
import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useCustomSensors } from "./CustomSensors.js";
import { closestCenter } from "@dnd-kit/core";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import "./SortableContainer.css"

/**
 * Wrapper component for a region containing a list of items whose order can be 
 * rearranged by dragging and dropping.
 * 
 * @prop {string} className - the sortable container's css className
 * @prop {string[]} idList - array of unique ids for each draggable item, in the order they appear
 * @prop {*} setOrder - given a new id array, updates the order of the draggable items
 * @prop {CollisionDetection} collisionDetectionAlgorithm - calculates collisions
 * @prop {SortingStrategy} sortingStrategy - determines how the order of items should change based on where an item is inserted
 * @prop {string} indicatorPadding - css measurement for how much padding should be added to the drop zone indicator
 */
const SortableContainer = ({
  className="", 
  idList,
  setOrder, 
  collisionDetectionAlgorithm=closestCenter, 
  sortingStrategy=rectSortingStrategy, 
  indicatorPadding="1.5rem",
  children,
}) => {
  const [indicateDropZone, setIndicateDropZone] = useState(false)
  const sensors = useCustomSensors()

  const handleDragStart = async (event) => {
    setIndicateDropZone(true)
  }

  const handleDragEnd = async (event) => {
    const {active, over} = event
    setIndicateDropZone(false)
    
    if (active && over && active.id !== over.id) {
      const oldIndex = idList.indexOf(active.id)
      const newIndex = idList.indexOf(over.id)
      const newOrder = arrayMove(idList, oldIndex, newIndex)
      await setOrder(newOrder)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionAlgorithm}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      autoScroll={{
        threshold: {
        x: 0,
        y: 0.2,
        },
        acceleration: 5,
      }}
      modifiers = {[restrictToParentElement]}
      >
      <SortableContext 
        items={idList}
        strategy={sortingStrategy}
      >
        <div className={"sortable-container " + className}>
          <div 
            className="drop-zone-indicator" 
            style={{
              height: `calc(100% + (2 * ${indicatorPadding}))`,
              width: `calc(100% + (2 * ${indicatorPadding}))`,
              display: indicateDropZone ? "block" : "none",
            }}
          />
          {children}
        </div>
      </SortableContext>
    </DndContext>
  )
}

export default SortableContainer
