import React, { useState, useContext } from "react"
import Modal from "react-bootstrap/Modal"
import { FirebaseContext } from "../../Firebase/index.js"
import DateInput from "./DateInput.js";
import FileUploadButton from "../../user-files/FileUploadButton.js";
import SortableContainer from "../../drag-and-drop/SortableContainer.js";
import UploadThumbnail from "./UploadThumbnail.js";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter } from "@dnd-kit/core";
import useUserFiles from "../../user-files/useUserFiles.js";
import { sendStandardToast } from "../../toasts/standard-toast.js";
import { FormattedMessage, useIntl } from "react-intl";
import SubmitButton from "../../portfolio/SubmitButton.js";
import useNotifications from "../../notifications/useNotifications.js";
import allowedStoryboardFileTypes from "../allowedStoryboardFileTypes.js";
import "./MediaUploadModal.css"
import "../../portfolio/portfolio-editor-modal-styles.css"

/**
 * Modal for adding or editing a storyboard panel
 * @prop {string} id - if editing an existing storyboard panel, the unique id for that panel
 * @prop {userFile[]} newFiles - files uploaded (by using the FileUploadButton in the storyboard) before opening this modal
 */
const MediaUploadModal = ({
  isOpen, 
  handleClose, 
  savedTitle="", 
  savedDescription="",
  savedMonth="",
  savedYear="",
  savedFileIds=[], 
  id, 
  newFiles
}) => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const [filesOrder, setFilesOrder] = useState([]) 

  const { firebase, profile } = useContext(FirebaseContext)
  const { filesList, addFile } = useUserFiles(null, "storyboard", filesOrder)
  const { handleNewStoryboardProject } = useNotifications(firebase, profile)
  const { formatMessage } = useIntl()

  const processNewFiles = async (uploadedFiles) => {
    const newFileIds = uploadedFiles.map(f => addFile(f))
    setFilesOrder([...filesOrder, ...newFileIds])
  }
  
  const resetFormValues = () => {
    setTitle(savedTitle)
    setDescription(savedDescription)
    setMonth(savedMonth)
    setYear(savedYear)
    setFilesOrder(savedFileIds)
  }

  const onShow = () => {
    resetFormValues()

    if (newFiles) {
      processNewFiles(newFiles)
    }
  }

  const onHide = async () => {
    resetFormValues()
    handleClose()
  }

  const handleSave = async () => {
    if (!title || filesOrder.length === 0) {
      sendStandardToast({
        heading: <FormattedMessage id="resume-experience-editor-modal-submission-required-foll" defaultMessage="Submission requires the following" />,
        message: (
          <>
            {!title && <div>Title</div>}
            {filesOrder.length === 0 && <div>{"Uploaded file(s)"}</div>}
          </>
        ),
      })
      return
    }
    if (filesList.some(f => f.isLoading)) {
      sendStandardToast({
        heading: "Please wait for your files to finish loading",
      })
      return
    }
    if (filesList.some(f => f.errorMessage)) {
      sendStandardToast({
        heading: "Unable to save some of your files",
        message: "Please delete any files with error messages and try again",
      })
      return
    }
    if ((month && !year) || (year && !month) || (year && year.length !== 4)) {
      sendStandardToast({
        heading: <FormattedMessage id="storyboard-editor-modal-date-error" defaultMessage="Invalid Date" />,
      })
      return
    }

    try {
      const deletedFileIDs = savedFileIds.filter(id => !filesOrder.includes(id))
      await Promise.all(deletedFileIDs.map(async (id) => {
        await firebase.deleteUserFile("storyboard", id)
      }))

      const newFiles = filesList.filter(f => !f.isSaved)
      await Promise.all(newFiles.map(async (f) => {
        await firebase.uploadUserFile("storyboard", f.fileId, f.fileData, f.thumbnailData)
      }))

      if (id) {
        await firebase.editPanel(id, false, title, description, month, year, filesOrder)
      } else {
        await firebase.addPanel(title, description, month, year, filesOrder)
      }  
    
    } catch (e) {
      sendStandardToast({
        heading: "Encountered an error when saving your project.",
        message: "Please try again later.",
      })
      return
    }

    onHide()
    if (!id) {
      await handleNewStoryboardProject(profile)
    }
  }

  const handleDelete = async () => {
    await Promise.all(savedFileIds.map(async (id) => {
      await firebase.deleteUserFile("storyboard", id)
    }))
    await firebase.deletePanel(id)
    onHide()
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => onHide()}
      onShow={() => onShow()}
      centered
      animation={false}
      size="lg"
      scrollable="true"
      className="storyboard-upload-modal portfolio-editor-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {id ? (
            <FormattedMessage id="storyboard-editor-modal-edit-project" defaultMessage="Edit Project"/>
          ) : (
            <FormattedMessage id="storyboard-editor-modal-add-project" defaultMessage="Add Project"/>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label htmlFor="title">
          <FormattedMessage id="title" defaultMessage="Title"/> *
        </label>
        <input 
          id="title" 
          type="text"
          value={title} 
          onChange={(event) => setTitle(event.target.value)} 
          required={true} 
          maxLength={90}
        />

        <label htmlFor="description">
          <FormattedMessage id="description" defaultMessage="Description"/>
        </label>
        <div className="input-instructions">
          <FormattedMessage 
            id="storyboard-editor-modal-description-placeholder"
            defaultMessage="What do these files represent? What skills did you use and what did you accomplish?"
          />
        </div>
        <textarea 
          id="description" 
          rows="3" 
          value={description} 
          onChange={(event) => setDescription(event.target.value)} 
        />

        <label htmlFor="date">
          <FormattedMessage id="date" defaultMessage="Date" />
        </label>
        <DateInput 
          month={month}
          year={year}
          updateMonth={setMonth}
          updateYear={setYear}
        />

        <label>
          <FormattedMessage
            id="storyboard-editor-modal-upload-files-label"
            defaultMessage="Upload Images, Documents, or Videos"
          /> *
        </label>
        <div className="input-instructions">
          <FormattedMessage 
            id="storyboard-editor-modal-drag-drop-instructions"
            defaultMessage="You can upload jpg, jpeg, png, mp4, doc, docx, and pdf files. Drag and drop file thumbnails to change their order."
          />
        </div>
        {filesOrder.length > 0 && (
          <SortableContainer
            className="thumbnails-container"
            setOrder={setFilesOrder}
            collisionDetectionAlgorithm={closestCenter}
            sortingStrategy={rectSortingStrategy}
            idList={filesOrder}
            indicatorPadding="1rem"
          >
            {filesList.map(f => 
              <UploadThumbnail key={f.fileId} file={f} onDelete={() => setFilesOrder(filesOrder.filter(id => id !== f.fileId))}/>
            )}
          </SortableContainer>
        )}
        <FileUploadButton 
          processFiles={processNewFiles}
          instructions={formatMessage({
            id: "storyboard-editor-modal-file-upload-instructions",
            defaultMessage: "Upload files by clicking this button or dragging files from your desktop",
          })}
          allowedTypes={allowedStoryboardFileTypes}
          id={id}
        />
        
      </Modal.Body>

      <Modal.Footer>
        {id && (
          <SubmitButton
            label={<FormattedMessage id="resume-experience-editor-modal-delete" defaultMessage="Delete"/>}
            isFilled={false}
            onClick={handleDelete}
          />
        )}
        <SubmitButton
          label={<FormattedMessage id="resume-experience-editor-modal-save" defaultMessage="Save"/>}
          isFilled={true}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default MediaUploadModal
