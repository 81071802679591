import React from "react"
import ResumeTimeline from "./ResumeTimeline.js"
import ResumeList from "./ResumeList.js"
import { filterExperiencesByType } from "./utils/utils.js"
import ResumeColumn from "./ResumeColumn.js"
import { 
  experienceTypes,
  defaultResumeColumnOneOrder, 
  defaultResumeColumnTwoOrder,
} from "./utils/experienceTypes.js"
import "./ResumeColumns.css"

/**
 * A non-empty public or private resume section
 */
const ResumeColumns = ({ userId, experiences = [], skills, interests, isPublicFacing }) => {
  const processedResumeItems = {}

  experienceTypes.forEach(expType => {
    const matchingExperiences = filterExperiencesByType(experiences, expType)
    if (matchingExperiences.length > 0) {
      const experienceItem = (
        <ResumeTimeline
          type={expType}
          filteredExperiences={matchingExperiences}
          isPublicFacing={isPublicFacing}
        />
      )
      processedResumeItems[expType] = experienceItem
    }
  })

  if (skills && skills.length) {
    processedResumeItems["skills"] = (
      <ResumeList type="skills" items={skills} isPublicFacing={isPublicFacing}/>
    )
  }
  if (interests && interests.length) {
    processedResumeItems["interests"] = (
      <ResumeList type="interests" items={interests} isPublicFacing={isPublicFacing}/>
    )
  }

  return (
    <div className="resume-timelines">
      <ResumeColumn 
        userId={userId}
        columnNumber="1"
        defaultOrder={defaultResumeColumnOneOrder}
        resumeData={processedResumeItems}
        isPublicFacing={isPublicFacing}
      />
      <ResumeColumn 
        userId={userId}
        columnNumber="2"
        defaultOrder={defaultResumeColumnTwoOrder}
        resumeData={processedResumeItems}
        isPublicFacing={isPublicFacing}
      />
    </div>
  )
}

export default ResumeColumns
