import { useState, useContext } from "react"
import { v4 } from "uuid"
import { FirebaseContext } from "../Firebase"

/**
 * @typedef {Object} userFile
 * @property {string} fileId
 * @property {string} fileType
 * @property {boolean} isSaved
 * @property {boolean} isLoading
 * @property {URL} fileURL
 * @property {URL} thumbnailURL
 * @property {File} fileData
 * @property {File} thumbnailData
 * @property {string} errorMessage
 */

/**
 * Interface for working with user-uploaded files on the front-end
 * 
 * @param {string} userId - defaults to the current user's id if undefined
 * @param {string} purpose - Ex: "storyboard"
 * @param {string[]} fileIds - ids of all files to be downloaded
 * 
 * @returns {userFile[]} filesList - fileObjects corresponding to fileIds
 * @returns {function} addFile - converts a Javascript File object to a userFile 
 * object. returns a new fileId corresponding to the new userFile object 
 */
const useUserFiles = (userId, purpose, fileIds) => {
  const { firebase } = useContext(FirebaseContext)
  const [filesData, setFilesData] = useState({})

  const fetchFile = async (fileId) => {
    const fileType = await firebase.getUserFileType(userId, purpose, fileId)
    const fileURL = await firebase.getUserFileURL(userId, purpose, fileId)
    const fetchedFile = {
      fileId: fileId,
      fileType: fileType,
      isSaved: true,
      fileURL: fileURL,
    }
    if (fileType === "application/pdf") {
      fetchedFile.thumbnailURL = await firebase.getUserFileThumbnailURL(userId, purpose, fileId)
    }
    if (!fileType || !fileURL || (fileType === "application/pdf" && !fetchedFile.thumbnailURL)) {
      fetchedFile.errorMessage = "Error when attempting to download file from server"
    }
    
    setFilesData(prevData => ({...prevData, [fileId]: fetchedFile}))
  }

  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const convertBase64ToFile = (stringData) => {
    const str = atob(stringData)
    const arr = new Uint8Array(str.length)
    for (let i = 0; i < str.length; i++) {
      arr[i] = str.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: "image/png" })
    const file = new File([blob], "thumbnail", { type: "image/png" })
    return file
  }

  const generatePdfThumbnail = async (file, fileId) => {
    const pdfString = await convertFileToBase64(file)
    const modifiedString = pdfString.substring(20)

    const convertToThumbnail = firebase.functions.httpsCallable("generateThumbnail")
    let thumbnailFile = undefined
    try {
      const result = await convertToThumbnail({pdfData: modifiedString})
      const thumbnailData = result.data.thumbnailData
      thumbnailFile = convertBase64ToFile(thumbnailData)
    } catch (e) {
      console.log(e)
    }

    const updatedFile = {
      fileId: fileId,
      fileType: file.type,
      isSaved: false,
      isLoading: false,
      fileURL: URL.createObjectURL(file),
      fileData: file,
      thumbnailURL: thumbnailFile && URL.createObjectURL(thumbnailFile),
      thumbnailData: thumbnailFile,
    }
    if (!thumbnailFile) {
      updatedFile.errorMessage = "error when generating thumbnail"
    }
    setFilesData(prevData => ({...prevData, [fileId]: updatedFile}))
  }

  const addFile = (file) => {
    const newId = v4()
    const newFile = {
      fileId: newId,
      fileType: file.type,
      isSaved: false,
      isLoading: file.type === "application/pdf",
      fileURL: URL.createObjectURL(file),
      fileData: file,
    }
    setFilesData(prevData => ({...prevData, [newId]: newFile}))
    if (file.type === "application/pdf") {
      generatePdfThumbnail(file, newId)
    }
    return newId
  }

  const filesList = fileIds.map(id => {
    if (filesData[id]) {
      return filesData[id]
    } else {
      const placeholderFile = {fileId: id}
      setFilesData(prevData => ({...prevData, [id]: placeholderFile}))
      fetchFile(id)
      return placeholderFile
    }
  })

  return {
    filesList,
    addFile,
  }
}

export default useUserFiles
