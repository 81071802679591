import { useEffect, useState, useContext} from "react"
import { FirebaseContext } from "../Firebase"

/**
 * Fetch the order of the panels in a user's storyboard 
 * @param {string} userId
 * @returns {string[]} panelIds - ordered array of all the user's storyboard panel ids
 * @returns {function} updatePanelsOrder - change the order of the user's storyboard panels to match a new array of panel ids
 */
const useStoryboardPanelsOrder = (userId) => {
  const { firebase } = useContext(FirebaseContext)
  const [panelIds, setPanelIds] = useState([])

  useEffect(() => {
    if (firebase) {
      const unsubscribeFunction = firebase.subscribeToStoryboardChanges(userId, setPanelIds)
      return () => unsubscribeFunction()
    }
  }, [firebase, userId])

  const updatePanelIdsOrder = async (newPanelsOrder) => {
    setPanelIds(newPanelsOrder)
    await firebase.updatePanelsOrder(undefined, false, newPanelsOrder)
  }

  return {
    panelIds,
    updatePanelIdsOrder,
  }
}

export default useStoryboardPanelsOrder
