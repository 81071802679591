import React, { useContext, useState } from "react"
import { Dropdown } from "semantic-ui-react"
import { FirebaseContext } from "../Firebase"
import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/search.min.css"
import "semantic-ui-css/components/transition.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/icon.min.css"
import { FormattedMessage, useIntl } from "react-intl"
import ResumeList from "../resume/ResumeList"
import { 
  defaultSkillOptions, 
  defaultSkillOptionsEs,
  defaultInterestOptions,
  defaultInterestOptionsEs,
} from "./utils/skillAndInterestDefaultOptions.js"
import "./SkillsInterestsEditor.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

/**
 * Button that can be disabled. Displays a loading spinner while the onClick
 * function is running
 * 
 * TODO: this could be updated and made reusable for other components
 */
const ConditionalButton = ({isDisabled, onClick, label}) => {
  const [loading, setLoading] = useState(false)
  
  const handleClick = async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  return (
    <button 
      className={`conditional-button ${isDisabled ? "disabled" : "enabled"}`}
      onClick={isDisabled ? () => null : handleClick} 
      aria-disabled={isDisabled}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
      ) : (
        label
      )}
    </button>
  )
}

/**
 * The body of the skills and interests editor modals
 */
const SkillsInterestsEditor = ({currentItems = [], type}) => {
  const maxCharacterCount = 40
  const { locale, formatMessage } = useIntl()
  const { firebase, notifier } = useContext(FirebaseContext)
  const [options, setOptions] = useState(
    (type === "skills" && locale === "en-US") ? defaultSkillOptions :
    (type === "skills" && locale === "es-ES") ? defaultSkillOptionsEs :
    (type === "interests" && locale === "en-US") ? defaultInterestOptions :
    (type === "interests" && locale === "es-ES") ? defaultInterestOptionsEs :
    []
  )
  const [newItem, setNewItem] = useState("")

  const handleKeyDown = e => {
    const regex = new RegExp("^[\\w\\[\\] `~!@#$%^?.,&*()={}:;<>+'\"-]{1}$")
    if (e.target.value.length >= maxCharacterCount && regex.test(e.key)) {
      e.preventDefault()
    } else if (regex.test(e.key)) {
      setNewItem(e.target.value + e.key)
    } else if (e.key === "Backspace" && e.target.value.length === 1) {
      setNewItem("")
    }
  }

  const handleChange = (e, { value }) => {
    setNewItem(value)
  }

  const handleAddition = async (e, { value }) => {
    const key = value.toLowerCase().replace(/ /g, "_")
    //item needs to be added to the default options for it to stick
    setOptions(options => [...options, { key: key + "_custom", value: value, text: value }])
    setNewItem(value)
  }

  const handleAddItem = async () => {
    if (newItem === "") {
      return;
    }

    const newItemString = newItem.trim()

    if (!currentItems.includes(newItemString)) {
      await firebase.updateProfile({
        data: {[type]: [...currentItems, newItem]}
      })
      if (type === "skills") {
        await notifier.handleSkillSetting()
      } else if (type === "interests") {
        await notifier.handleInterestSetting()
      }
    }

    setNewItem("")
  }

  return (
    <div className="resume-editor-skills-interests">
      <label>
        <FormattedMessage 
          id={`skills-interests-modal-add-${type}`} 
          defaultMessage={`Add ${type.substring(0, type.length - 1)}`}
        />
      </label>
      <div className="skill-interest-add-box">
        <Dropdown
          className="multiple skill-interest-dropdown"
          options={options}
          value={newItem}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onAddItem={handleAddition}
          placeholder={newItem || formatMessage({
            id: `skills-interests-modal-search-${type}`,
            defaultMessage: `Search ${type}`
          })}
          selectOnBlur={false}
          fluid
          search
          selection
          allowAdditions
          additionLabel=""
          upward={false}
          renderLabel={label => ({
            className: "custom-portfolio-skills-label",
            content: label.text,
          })}
        />
        <ConditionalButton
          label={(
            <FormattedMessage 
              id={`skills-interests-modal-add-${type}`} 
              defaultMessage={`Add ${type.substring(0, type.length - 1)}`}
            />
          )}
          onClick={handleAddItem}
          isDisabled={newItem === ""}
        />
      </div>
      {currentItems.length > 0 && (
        <>
          <label>
            <FormattedMessage 
              id={`skills-interests-modal-delete-reorder-${type}`} 
              defaultMessage={`Delete/Reorder ${type}`}
            />
          </label>
          <div className="saved-skills-interests-container">
            <ResumeList type={type} items={currentItems} isPublicFacing={false}/>
          </div>
        </>
      )}
    </div>
  )
}

export default SkillsInterestsEditor
