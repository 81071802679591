import { createHeader, createLifeSubHeader, createSpacingLine, createBulletPoint } from "./componentHelpers"

export const renderLifeSection = (lifeExperiences) => {
  if (!lifeExperiences || lifeExperiences.length === 0) {
    return []
  }
  
  return [
    ...createHeader("LIFE EXPERIENCE"),
    ...lifeExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const milestone = experience.milestone || "MILESTONE"
        const time = experience.endDate || "TIME PERIOD"
        const bulletPoints = experience.bulletPoints || []

        return [
          ...createLifeSubHeader(milestone, time),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
