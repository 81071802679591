/**
 * @description List of school / organization options. This list contains the general configuration settings for each schools subdomain deployment and is also used in several other
 * parts of the application such as the login page, the admin dashboard, and the magic link creation process. This is sorted alphabetically by the text field.
 *
 * @typedef {Object} SchoolOrganizationOption
 * @property {string[]} sso_methods - Methods for single sign-on (e.g., "saml", "openIdConnect","google", "email"). This determines the login method for the school.Use one if using saml or openId connect, use both if using google or email. Order Matters, first entry is primary
 * @property {string} logo - URL to the school's logo.
 * @property {string} domain - The school's login URL, this is where you will be directed after selecting a school from the wingspans.com school selection dropdown menu.
 * @property {string} key - Unique key for the school, used to match the subdomain of the current deployment.
 * @property {string} text - Display name of the school for the login page.
 * @property {string[]} emails - List of email domains associated with the school that are allowed to sign up, if SSO does not match then the users account will not be created.
 * @property {string} audience - Description of the target audience for the login button, not enabled.
 * @property {string} value -  Unique key identifier for the school, federal school ID in most cases, may be the name of an org if not a school.
 * @property {("K-12" | "ADULT")} audience_type - Type of audience (e.g., "K-12", "ADULT"), used to distinguish lesson materials.
 * @property {string|null} saml_provider_id - SAML provider ID if applicable, otherwise null.
 * @property {string|null} openid_connect_provider_id - OpenID Connect provider ID if applicable, otherwise null.
 * @property {string|null} banner - URL to the login page banner image if any, otherwise null.
 * @property {string|null} banner_position - Horizontal position of the banner if any, otherwise null.
 * @property {("button" | "link" | null)} magic_link_type - Type of magic link if any, otherwise null.
 * @property {boolean} hide_name - Flag to determine if the schools name should be hidden in the signup page when their logo includes the name prominently.
 * @property {boolean} reverse_names - Flag to determine if the users first and last names should be reversed upon account creation.
 * @property {string} state - State abbreviation for the school, used for default salaries when avaliable.
 * @property {string|null} petersons_institution_id - Petersons institution ID for colleges, used for major filtering .
*/
export const schoolOrganizationOptions = [
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FThe%20MET%20logo.jpeg?alt=media&token=2c1fbedb-fc27-4869-8fd7-1308bfbb4c98",
    domain: "https://themet.wingspans.com",
    key: "themet",
    text: "The MET High School",
    emails: ["@metmail.org", "@wingspans.com"],
    audience: "Students and Faculty Login",
    value: "400166",
    audience_type: "K-12",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["openIdConnect"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FBHCCBulldogMascot.png?alt=media&token=59883130-f1bf-4f06-87f4-aa09d9292dad",
    domain: "https://bunkerhill.wingspans.com",
    key: "bunkerhill",
    text: "Bunker Hill Community College",
    emails: ["@bhcc.edu", "@wingspans.com"],
    audience: "Students and Faculty Login",
    value: "11210",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: "oidc.bunkerhill",
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/sign-up-pages%2FBHCC%20Compressed.jpg?alt=media&token=28aa6eb8-5f33-4dd9-bfe5-f11488ef1fde",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "MA",
    petersons_institution_id: "5475",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FEagle%20adj%20(CMYK).jpg?alt=media&token=6e533edb-f57b-46cf-85be-099c5ec4baa0",
    domain: "https://northlandpioneer.wingspans.com",
    key: "northlandpioneer",
    text: "Northland Pioneer Community College",
    emails: ["@npc.edu", "@mail.npc.edu", "@stu.npc.edu"],
    audience: "Students and Faculty Login",
    value: "011862",
    audience_type: "ADULT",
    saml_provider_id: "saml.northlandpioneer",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/sign-up-pages%2FNPC%20963kB%20Compressed.jpg?alt=media&token=4e4094b4-de94-4d12-884d-f350d51350b3",
    banner_position: "50%",
    magic_link_type: "link",
    hide_name: false,
    reverse_names: false,
    state: "AZ",
    petersons_institution_id: "5475",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRCC.jpg?alt=media&token=0e41f6e2-7ba4-4db4-975c-245bf6a27724",
    domain: "https://roxbury.wingspans.com",
    key: "roxbury",
    text: "Roxbury Community College",
    emails: ["@rcc.mass.edu", "@roxbury.edu"],
    audience: "Students and Faculty Login",
    value: "011930",
    audience_type: "ADULT",
    saml_provider_id: "saml.roxbury",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/sign-up-pages%2FBetter%20Roxbury%20compressed.jpg?alt=media&token=47c2f5f5-a23f-4e95-b676-9d23094be448",
    banner_position: "50%",
    magic_link_type: "link",
    hide_name: false,
    reverse_names: false,
    state: "MA",
    petersons_institution_id: "8348",
  },
  {
    sso_methods: ["google"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FNTU%20logo.jpeg?alt=media&token=ca4f6947-efa0-45f6-a8b2-6b9f9a9f0d79",
    domain: "https://navajotech.wingspans.com",
    key: "navajotech",
    text: "Navajo Tech University",
    emails: ["@navajotech.edu"],
    audience: "Students and Faculty Login",
    value: "016119",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "NM",
    petersons_institution_id: "140373",
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FEagle-Rock-School-Press-Release-Logo-white-on-purple.jpeg?alt=media&token=fdb6bc1a-e1d4-4eb2-9413-bf4ed08865d4",
    domain: null,
    key: "eaglerock",
    text: "Eagle Rock High School",
    emails: [],
    audience: "Students and Faculty Login",
    value: "060551",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "CA",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCFCC%20.jpeg?alt=media&token=5e8a0041-1132-48d5-a433-bdfad5c66119",
    domain: "https://capefear.wingspans.com",
    key: "capefear",
    text: "Cape Fear Community College",
    emails: ["@cfcc.edu", "@mail.cfcc.edu", "@AD.cfcc.edu", "@ad.cfcc.edu"],
    audience: "Students and Faculty Login",
    value: "005320",
    audience_type: "ADULT",
    saml_provider_id: "saml.capefear",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCape%20Fear%20Campus.jpeg?alt=media&token=85e75cdc-52cc-4df0-a54a-d540c96c232e",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "NC",
    petersons_institution_id: "5547",
  },
  {
    sso_methods: ["microsoft", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCCAC%20logo.jpeg?alt=media&token=f658b4ad-75bb-462a-a67a-46c6ffc0c189",
    domain: null,
    key: "alleghenycounty",
    text: "Community College of Allegheny County",
    emails: [],
    audience: "Students and Faculty Login",
    value: "003231",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "PA",
    petersons_institution_id: "5914",
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMillis%20High%20School%20logo.png?alt=media&token=f0c6ce7c-4ebc-4548-9338-fa06b72e5cf2",
    domain: null,
    key: "millishighschool",
    text: "Millis High School",
    emails: ["@millisschools.org"],
    audience: "Students and Faculty Login",
    value: "01870020",
    audience_type: "K-12",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "MA",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["google"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FSomeville%20High%20School%20S%20Logo%202022-no%20circle%20SMALLER.jpeg?alt=media&token=3b7b69cd-0d86-49c7-bcde-1bd47eec6bc3",
    domain: "https://somerville.wingspans.com",
    key: "somerville",
    text: "Somerville Public Schools",
    emails: ["@k12.somerville.ma.us"],
    audience: "Students and Faculty Login",
    value: "251089001745",
    audience_type: "K-12",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/sign-up-pages%2Fsomerville.jpeg?alt=media&token=579497c7-2cf5-4ac6-8ddf-c3697ac8dd7b",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "MA",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["google"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fcharette-hs.png?alt=media&token=f3160f6f-d39f-4de1-a490-f013ee503953",
    domain: "https://charette.wingspans.com",
    key: "charette",
    text: "Charette High School",
    emails: ["@charettepvd.org"],
    audience: "Students and Faculty Login",
    value: "81601",
    audience_type: "K-12",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: null,
    banner_position: null,
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FURI-Ram.jpg?alt=media&token=653470b4-6c46-40dd-afc3-c7b029776f46",
    domain: "https://uri.wingspans.com",
    key: "uri",
    text: "University of Rhode Island",
    emails: ["@uri.edu", "@wingspans.com"],
    audience: "Students and Faculty Login",
    value: "003414",
    audience_type: "ADULT",
    saml_provider_id: "saml.uri",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FURI.jpeg?alt=media&token=930a2f63-9d71-4d30-ba06-2ab8d2392abf",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: "9362",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FKennedy%20King%20College%20GCR%20image.png?alt=media&token=876224f5-6409-470f-a9dd-0352eb82fb71",
    domain: "https://kennedyking.wingspans.com",
    key: "kennedyking",
    text: "Kennedy King College",
    emails: ["@ccc.edu", "@student.ccc.edu"],
    audience: "",
    value: "001654",
    audience_type: "ADULT",
    saml_provider_id: "saml.kennedyking",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fkennedy%20king%20college%20campus-sm.jpeg?alt=media&token=508d2e1b-939c-421a-b524-5cc3ea9d1e92",
    banner_position: "70%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "IL",
    petersons_institution_id: "5706",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FGeorgetown_Hoyas_Logo_.png?alt=media&token=a0d64fcb-89b5-4f6e-9fc2-4b8c4935aad0",
    domain: "https://georgetown.wingspans.com",
    key: "georgetown",
    text: "Georgetown University",
    emails: ["@georgetown.edu", "@wingspans.com"],
    audience: "",
    value: "001445",
    audience_type: "ADULT",
    saml_provider_id: "saml.georgetown",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FGeorgetown%20University%20campus.jpeg?alt=media&token=cadd4898-4cd8-428a-929a-87fe729363e2",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "DC",
    petersons_institution_id: "6446",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMendocino%20College%20logo.png?alt=media&token=4199ad9c-56e3-4131-bb16-16607794c799",
    domain: "https://mendocino.wingspans.com",
    key: "mendocino",
    text: "Mendocino College",
    emails: ["@mendocino.edu", "@student.mendocino.edu", "@wingspans.com"],
    audience: "",
    value: "011672",
    audience_type: "ADULT",
    saml_provider_id: "saml.mendocino",
    openid_connect_provider_id: "oidc.mendocino",
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMendocino%20College%20campus.jpg?alt=media&token=7dfb10dc-ce65-4043-8e07-4f76213612bb",
    banner_position: "40%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "CA",
    petersons_institution_id: "7404",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Froane%20state%20logo.png?alt=media&token=dcc0b8d4-f4af-4c46-959c-bf3664330360",
    domain: "https://roanestate.wingspans.com",
    key: "roanestate",
    text: "Roane State Community College",
    emails: ["@roanestate.edu", "@wingspans.com"],
    audience: "",
    value: "009914",
    audience_type: "ADULT",
    saml_provider_id: "saml.roanestate",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRoane%20State%20Community%20College.jpeg?alt=media&token=06d752e9-971c-451e-8468-dd90fbe49e8a",
    banner_position: "10%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: true,
    state: "TN",
    petersons_institution_id: "8316",
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCU%20logo.png?alt=media&token=bf5ad201-f41f-44db-99b5-da75b4ee6363",
    domain: "https://collegeunbound.wingspans.com",
    key: "collegeunbound",
    text: "College Unbound",
    emails: ["@collegeunbound.edu", "@wingspans.com"],
    audience: "",
    value: "042836",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCollege%20Unbound%20campus.jpeg?alt=media&token=eec063a6-2810-4867-b102-ea3e93ce9f95",
    banner_position: "65%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRIRAL%2BLogo%2B(white).jpg?alt=media&token=0235f492-a811-42c7-b651-2677999946fe",
    domain: "https://riral.wingspans.com",
    key: "riral",
    text: "Rhode Island Regional Adult Learning",
    emails: ["@riral.org", "@student.riral.org", "@wingspans.com"],
    audience: "",
    value: "9001",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRIRAL%20-%20Woonsocket.jpg?alt=media&token=96e137c2-a241-4c5f-bb79-eb3e6797b1f0",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["openIdConnect"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FParkland%20college%20logo.jpeg?alt=media&token=2a2db49e-0a71-4c56-a65c-c4f99dfd8f99",
    domain: "https://parkland.wingspans.com",
    key: "parkland",
    text: "Parkland College",
    emails: ["@stu.parkland.edu", "@parkland.edu", "@wingspans.com"],
    audience: "",
    value: "007118",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: "oidc.parkland",
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FParklandCollege-ZoomBkgd_Campus-Daylight3.jpg?alt=media&token=0218ced9-321b-46ca-9a69-c7a993a588d9",
    banner_position: "88%",
    magic_link_type: "link",
    hide_name: true,
    reverse_names: false,
    state: "IL",
    petersons_institution_id: "8058",
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FEdmonds_College_Logo.png?alt=media&token=131e18be-5f71-4c22-b6c9-03fab85835f3&_gl=1*34o7sw*_ga*MTI1ODQzNDQyNi4xNjYxOTU5MjY0*_ga_CW55HF8NVT*MTY5NTg0NDIzMy4xMjUuMS4xNjk1ODQ0MjgyLjExLjAuMA..",
    domain: "https://edmonds.wingspans.com",
    key: "edmonds",
    text: "Edmonds College",
    emails: ["@edmonds.edu", "@student.edmonds.edu", "@wingspans.com"],
    audience: "",
    value: "005501",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FEdmonds%20College.jpeg?alt=media&token=52972876-16ab-4542-a326-608249b0f223&_gl=1*99kj1v*_ga*MTI1ODQzNDQyNi4xNjYxOTU5MjY0*_ga_CW55HF8NVT*MTY5NTg1MTE4NS4xMjYuMS4xNjk1ODUxMjc3LjQzLjAuMA..",
    banner_position: "20%",
    magic_link_type: "link",
    hide_name: true,
    reverse_names: false,
    state: "WA",
    petersons_institution_id: "6222",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMassHire%20-%20logo.png?alt=media&token=4d7ffaa3-f010-4464-a5e8-9256562d410f",
    domain: "https://masshiredowntown.wingspans.com",
    key: "masshiredowntown",
    text: "MassHire Career Center",
    emails: [],
    audience: "",
    value: "masshiredowntown",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fmasshire-5-1000.jpg?alt=media&token=06d9ba0f-a193-478e-90c3-ff2a3361117c",
    banner_position: "40%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "MA",
    petersons_institution_id: null,
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FIvy%20Tech%20-%20Logo.png?alt=media&token=b9205138-f61f-40aa-bf62-04db28715475",
    domain: "https://ivytech.wingspans.com",
    key: "ivytech",
    text: "Ivy Tech Community College",
    emails: [],
    audience: "",
    value: "009917",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FIvy%20Tech%20-%20Campus.jpg?alt=media&token=4ea1df5f-16bb-4861-882d-eb94c036eb4d",
    banner_position: "20%",
    magic_link_type: "button",
    hide_name: false,
    reverse_names: false,
    state: "IN",
    petersons_institution_id: '6749',
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FPasadena%20City%20College%20logo.png?alt=media&token=a51bbb86-1143-40a1-a192-951ff59cadc7",
    domain: "https://pasadena.wingspans.com",
    key: "pasadena",
    text: "Pasadena City College",
    emails: [],
    audience: "",
    value: "1261",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FPasadena%20City%20College.jpg?alt=media&token=34eb6436-680f-421e-be63-fba05146186b",
    banner_position: "20%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "CA",
    petersons_institution_id: "8064",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fmohawk-valley-logo-400.png?alt=media&token=fefb3594-e270-4a8d-aced-5da05b5df3b3",
    domain: "https://mohawkvalley.wingspans.com",
    key: "mohawkvalley",
    text: "Mohawk Valley Community College",
    emails: [],
    audience: "",
    value: "002871",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMohawk%20Valley%20Community%20College%20-%20Campus.jpeg?alt=media&token=cba124b6-9669-4315-b29b-54d39443d379",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "NY",
    petersons_institution_id: "7545",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FWashburn%20University%20-%20Logo%202.png?alt=media&token=5353fd92-9627-4d23-bc9a-e3787fde46a4",
    domain: "https://washburn.wingspans.com",
    key: "washburn",
    text: "Washburn University",
    emails: ["@washburn.edu"],
    audience: "",
    value: "001949",
    audience_type: "ADULT",
    saml_provider_id: "saml.washburn",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FWashburn%20Univeristy%20-%20campus.jpg?alt=media&token=eb40c5fb-27c6-4390-94c8-470b58ce5543",
    banner_position: "60%",
    magic_link_type: "link",
    hide_name: true,
    reverse_names: false,
    state: "KS",
    petersons_institution_id: "9577",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRio%20Salado%20College%20-%20Logo%202.jpeg?alt=media&token=e37d11fa-8ce8-4807-ba20-04877d8e6377",
    domain: "https://riosalado.wingspans.com",
    key: "riosalado",
    text: "Rio Salado College",
    emails: ["@riosalado.edu", "@domail.maricopa.edu", "@wingspans.com", "@maricopa.edu"],
    audience: "",
    value: "014483",
    audience_type: "ADULT",
    saml_provider_id: "saml.riosalado",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRio%20Salado%20Campus.jpeg?alt=media&token=95de3fbd-6e3e-4c0a-b957-ce1a72d034a2",
    banner_position: "60%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "AZ",
    petersons_institution_id: "8312",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FColumbus%20State%20-%20Logo.png?alt=media&token=5deedfda-9740-4a6e-a026-698e490fa682",
    domain: "https://columbusstate.wingspans.com",
    key: "columbusstate",
    text: "Columbus State College",
    emails: [],
    audience: "",
    value: "001561",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FColumbus%20State%20CC.jpg?alt=media&token=8ef2f19c-526e-4e0b-ba4f-b1429b5901d8",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: false,
    reverse_names: false,
    state: "GA",
    petersons_institution_id: "5905",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCommunity%20College%20of%20Philadelphia%20-%20Logo.jpeg?alt=media&token=e1d30854-7310-4101-966a-8c44dade9c1f",
    domain: "https://ccp.wingspans.com",
    key: "ccp",
    text: "Community College of Philadelphia",
    emails: [],
    audience: "",
    value: "003249",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FCommunity%20College%20of%20Philadelphia%20-%20Campus.jpeg?alt=media&token=6ad8eb22-e874-4943-b904-b39f9bd7ed38",
    banner_position: null,
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "PA",
    petersons_institution_id: "5929",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FNormandale%20Community%20College%20-%20Logo.jpeg?alt=media&token=35ecc71c-0b4f-43bb-86d3-cc02740e6e40",
    domain: "https://normandale.wingspans.com",
    key: "normandale",
    text: "Normandale Community College",
    emails: [],
    audience: "",
    value: "007954",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FNormandale%20Community%20College%20-%20Campus.jpg?alt=media&token=7895a1c0-aabc-41df-8c76-a08ed9cf7280",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "MN",
    petersons_institution_id: "7784",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FPortland%20Community%20College%20-%20Logo.jpg?alt=media&token=78efac6b-710d-466b-ac4c-7baa0ee2f400",
    domain: "https://portland.wingspans.com",
    key: "portland",
    text: "Portland Community College",
    emails: [],
    audience: "",
    value: "003213",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FPortland%20CC.jpeg?alt=media&token=aa223fe3-3526-4f39-bf27-3858e290e2a3",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "OR",
    petersons_institution_id: "8185",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FHighline%20College%20-%20Logo.png?alt=media&token=8e3176b6-72fe-4244-a0fc-e45c25a35e8e",
    domain: "https://highline.wingspans.com",
    key: "highline",
    text: "Highline College",
    emails: [],
    audience: "",
    value: "003781",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FHighline%20College%20-%20Campus.jpeg?alt=media&token=926e0183-faa6-4cc7-bb3c-ee80fa691ef4",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "WA",
    petersons_institution_id: "6635",
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMadison%20College%20-%20Logo.jpeg?alt=media&token=01c68810-ceb3-4d04-a126-788aaac06690",
    domain: "https://madisoncollege.wingspans.com",
    key: "madisoncollege",
    text: "Madison College",
    emails: ["@madisoncollege.edu"],
    audience: "",
    value: "4007",
    audience_type: "ADULT",
    saml_provider_id: "saml.madison",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FMadison%20College%20-%20Campus.jpeg?alt=media&token=1193dca6-f1bd-4928-ac55-35d261caa0b4",
    banner_position: "60%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: true,
    state: "WI",
    petersons_institution_id: "7278",
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fpolaris_MEP_logo-sm.jpg?alt=media&token=d65cff7c-f847-42d2-b8b4-a9c84d8c3200",
    domain: "https://polarismep.wingspans.com",
    key: "polarismep",
    text: "Polaris MEP",
    emails: [],
    audience: "",
    value: "polarismep",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2F072_PolarisMEP_background-sm.jpg?alt=media&token=c0baf877-574b-4f30-8812-eadbc939f589",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["google", "email"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FWesterly%20Public%20Schools%20logo.jpeg?alt=media&token=c8a2e14b-fedc-4339-9988-1b3ef40f9546",
    domain: "https://westerly.wingspans.com",
    key: "westerly",
    text: "Westerly School District",
    emails: ["@westerly.k12.ri.us", "@wpsri.org", "@students.westerlyps.org", "@students.wpsri.org", "@wingspans.com"],
    audience: "",
    value: "440117000325",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FWesterly%20Public%20Schools.jpeg?alt=media&token=14cae313-58b9-495c-8348-40e2eff2ee85",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Flubbock2.png?alt=media&token=f1752e4b-d89d-431f-983f-61e2208d6331",
    domain: "https://lubbock.wingspans.com",
    key: "lubbock",
    text: "Lubbock ISD",
    emails: ["@lubbockisd.org", "@lubbockisd.net", "@wingspans.com"],
    audience: "",
    value: "4828500",
    audience_type: "K-12",
    saml_provider_id: "saml.lubbock",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Flubbock-statue.jpg?alt=media&token=5d12ba39-7f43-4624-ad16-b17523e6504b",
    banner_position: "40%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "TX",
    petersons_institution_id: null,
  },
  {
    sso_methods: [],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fdallas3.jpg?alt=media&token=a7e5348b-1767-4bab-9a8e-cff6d32ca9ca",
    domain: "https://dallaswfs.wingspans.com",
    key: "dallaswfs",
    text: "Dallas Workforce Solutions",
    emails: [],
    audience: "",
    value: "wfsdallas",
    audience_type: "ADULT",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fdallas.jpg?alt=media&token=459fde92-2bcb-47bc-9b29-551617e8b884",
    banner_position: "60%",
    magic_link_type: "button",
    hide_name: true,
    reverse_names: false,
    state: "TX",
    petersons_institution_id: null,
  },
  {
    sso_methods: ["saml"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fwingspanslogo.png?alt=media&token=db601cc2-f784-4e04-9f5a-985bed9da723",
    domain: "https://team.wingspans.com",
    key: "team",
    text: "Wingspans Team",
    emails: ["@wingspans.com"],
    audience: "",
    value: "000000",
    audience_type: "ADULT",
    saml_provider_id: "saml.wingspans",
    openid_connect_provider_id: null,
    banner:
      "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fjuan-ramos-EWN0rrwbBIQ-unsplash%20(1).jpg?alt=media&token=7862983b-2666-4583-9310-d76c87f2f667",
    banner_position: "50%",
    magic_link_type: "button",
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    // use google for now, remove saml once confirmed
    sso_methods: ["google"],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FShawnee%20logo.png?alt=media&token=933bf33e-a268-4dd7-b263-711dfd48cff9",
    domain: "https://shawnee.wingspans.com",
    key: "shawnee",
    text: "Shawnee Community College",
    emails: ["@shawneecc.edu"],
    audience: "",
    value: "007693",
    audience_type: "ADULT",
    saml_provider_id: "saml.shawnee",
    openid_connect_provider_id: null,
    banner: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FShawnee%20campus.jpg?alt=media&token=7eee7ee8-ebbd-4348-ba5b-3f7ae4234e34",
    banner_position: "50%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "IL",
    petersons_institution_id: "8616",
  },
  {
    // k-12 demo school with no SSO
    sso_methods: ['email'],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fwingspanslogo.png?alt=media&token=db601cc2-f784-4e04-9f5a-985bed9da723",
    domain: "https://k12.wingspans.com",
    key: "k12",
    text: "K-12 demo",
    emails: [],
    audience: "",
    value: "k12",
    audience_type: "K-12",
    saml_provider_id: null,
    openid_connect_provider_id: null,
    banner: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2Fkids%20copy%20(1).gif?alt=media&token=fab7a71a-56fa-4537-b867-2dbb61d520d2",
    banner_position: "55%",
    magic_link_type: null,
    hide_name: false,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: null,
  },
  {
    sso_methods: ['saml'],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRIC_Logo_Square_Burgundy_RGB%20(1).png?alt=media&token=21627a20-65fd-4910-a301-5fcf09ce082a",
    domain: "https://ric.wingspans.com",
    key: "ric",
    text: "Rhode Island College",
    emails: ["@email.ric.edu", "@ric.edu"],
    audience: "",
    value: "003407",
    audience_type: "ADULT",
    saml_provider_id: "saml.ric",
    openid_connect_provider_id: null,
    banner: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FRIC%20Forman_Center%20(1)%20(4)%20(1).jpg?alt=media&token=11629c4c-623f-4d1e-bfa9-6fa527f4c14c",
    banner_position: "75%",
    magic_link_type: null,
    hide_name: true,
    reverse_names: false,
    state: "RI",
    petersons_institution_id: "8292",
  },
  {
    sso_methods: ['saml'],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FOCC%20Logo%20Black%20(1).png?alt=media&token=e769c9a4-d16e-48f2-a7a1-2b2382ebba93",
    domain: "https://onondaga.wingspans.com",
    key: "onondaga",
    text: "Onondaga Community College",
    emails: ["@sunyocc.edu", "@clasnet.sunyocc.edu"],
    audience: "",
    value: "160973001",
    audience_type: "ADULT",
    saml_provider_id: "saml.onondaga",
    openid_connect_provider_id: null,
    banner: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FOnondaga%20CC.jpg?alt=media&token=fcfd050c-b5a5-411d-a1fe-593c847797e5",
    banner_position: "40%",
    magic_link_type: "link",
    hide_name: true,
    reverse_names: false,
    state: "NY",
    petersons_institution_id: "7980",
  },
  {
    sso_methods: ['saml'],
    logo: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FOKSTATE%20logo%20(1).png?alt=media&token=46aef9f5-c482-41a0-ac13-2a9913824449",
    domain: "https://osu-okc.wingspans.com",
    key: "osu-okc",
    text: "OSU OKC",
    emails: ["@okstate.edu"],
    audience: "",
    value: "009647",
    audience_type: "ADULT",
    saml_provider_id: "saml.osu-okc",
    openid_connect_provider_id: null,
    banner: "https://firebasestorage.googleapis.com/v0/b/lucid-bond-288217.appspot.com/o/school-logos%2FOSUOKC%20Building%205%20(1).jpg?alt=media&token=91fe4800-2dd4-4c8a-b343-9b38fc1f8511",
    banner_position: "40%",
    magic_link_type: false,
    hide_name: true,
    reverse_names: false,
    state: "OK",
    petersons_institution_id: "7969",
  },
]
  .sort(function (a, b) {
    return a.text === b.text ? 0 : a.text < b.text ? -1 : 1
  })

