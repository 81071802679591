import React, { useState } from "react"
import FileDisplayer from "../../user-files/FileDisplayer"
import PublicPanelModal from "./PublicPanelModal"
import useStoryboardPanel from "../useStoryboardPanel"
import useUserFiles from "../../user-files/useUserFiles"
import { FormattedMessage } from "react-intl"
import "./PublicStoryboardPanel.css"

/**
 * Displays a single panel in the public view of a storyboard
 */
const PublicStoryboardPanel = ({userId, panelId}) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(null)
  const { title, description, dateString, fileIds } = useStoryboardPanel(userId, panelId)
  const { filesList } = useUserFiles(userId, "storyboard", fileIds)

  const thumbnailWidth = "14.125rem"
  const spaceBetweenThumbnails = "1.5rem"
  const horizontalPadding = "1.5rem"

  return (
    <div 
      className="public-storyboard-panel" 
      style={{
        // tries to distribute space based on the number of thumbnails in the panel
        flexGrow: filesList.length,
        flexShrink: `calc(1 / ${filesList.length})`,
        flexBasis: `calc((${filesList.length} * ${thumbnailWidth}) + (2 * ${horizontalPadding}) + (${filesList.length - 1} * ${spaceBetweenThumbnails}))`,
      }}
    >
      <div className="panel-heading">
        <div className="panel-title">{title}</div>
        {dateString && <div className="panel-date">{dateString}</div>}
      </div>
      <div className="panel-description">{description}</div>
      <div className="file-thumbnails-container">
        {filesList.map((f, index) => (
          <button 
            key={f.fileId} 
            className="thumbnail" 
            onClick={() => setSelectedFileIndex(index)}
          >
            <FileDisplayer file={f} isThumbnail={true}/>
          </button>
        ))}
      </div>
      <div className="instructions">
        <FormattedMessage 
          id="storyboard-click-on-thumbnail-to-view"
          defaultMessage="Click on a thumbnail to view"
        />
      </div>
      <PublicPanelModal
        isOpen={selectedFileIndex !== null}
        handleClose={() => setSelectedFileIndex(null)}
        title={title}
        description={description}
        openedFileIndex={selectedFileIndex}
        files={filesList}
      />
    </div>
  )
}

export default PublicStoryboardPanel
