import React, { useState, useContext } from "react"
import { FirebaseContext } from "../Firebase"
import { Modal } from "react-bootstrap"
import SubmitButton from "./SubmitButton"
import { FormattedMessage } from "react-intl"
import "./portfolio-editor-modal-styles.css"

/**
 * Modal used to edit a user's introduction section.
 */
const IntroductionEditModal = ({show, savedQuote, savedIntroduction, savedCity, savedState, onClose}) => {
  const {
    firebase,
    notifier,
    setWalkThroughStepValues,
  } = useContext(FirebaseContext)
  const [quote, setQuote] = useState("")
  const [introduction, setIntroduction] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")

  const resetFormValues = () => {
    setQuote(savedQuote || "")
    setIntroduction(savedIntroduction || "")
    setCity(savedCity || "")
    setState(savedState || "")
  }

  const handleSave = async () => {
    try {
      await firebase.updateProfile({ 
        data: {
          profileQuote: quote,
          introduction: introduction,
          city: city,
          state: state,
          // remove any deprecated introduction values
          aboutMe: "",
          myStrengths: "",
          myWeaknesses: "",
        } 
      })
      
      await firebase.saveWalkthroughStep("buildPortfolio", true)
      onClose()
      notifier.handlePersonalInfoSetting()
      firebase.getWalkthroughSteps().then(res => setWalkThroughStepValues(res))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal 
      className="portfolio-editor-modal" 
      show={show} 
      onShow={resetFormValues}
      onHide={onClose} 
      size="lg" 
      animation={false}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="portfolio-introduction-edit-modal-title"
            defaultMessage={"Edit Introduction Section"}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor="quote">
          <FormattedMessage
            id="portfolio-personal-info-form-quote"
            defaultMessage="Quote"
          />
        </label>
        <div className="input-instructions">
          <FormattedMessage
            id="portfolio-personal-info-form-default-quote"
            defaultMessage={`Choose a quote that captures the essence of who you
                             are. It can be your own or one that's well known.`}
          />
        </div>
        <textarea 
          id="quote" 
          maxLength={1000}
          rows={2}
          value={quote}
          onChange={(event) => setQuote(event.target.value)}
        />

        <label htmlFor="introduction">
          <FormattedMessage
            id="portfolio-personal-info-form-introduction"
            defaultMessage="Introduction"
          />
        </label>
        <div className="input-instructions">
          <FormattedMessage
            id="portfolio-personal-info-form-default-about-me"
            defaultMessage={`What makes you unique? Talk about your biggest wins, 
                             your most compelling experiences, and what inspires 
                             you to do what you do. Show, not tell.`}
          />
        </div>
        <textarea 
          id="introduction" 
          maxLength={2000}
          rows={3}
          value={introduction}
          onChange={(event) => setIntroduction(event.target.value)}
        />

        <label htmlFor="city">
          <FormattedMessage 
            id="portfolio-personal-info-form-city"
            defaultMessage="City"
          />
        </label>
        <input 
          id="city" 
          type="text" 
          maxLength={50}
          value={city}
          onChange={(event) => setCity(event.target.value)}
        />

        <label htmlFor="state">
          <FormattedMessage
            id="portfolio-personal-info-form-state"
            defaultMessage="State"
          />
        </label>
        <input 
          id="state" 
          type="text"
          maxLength={15}
          value={state}
          onChange={(event) => setState(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          label={<FormattedMessage id="resume-experience-editor-modal-save" defaultMessage="Save"/>}
          isFilled={true}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default IntroductionEditModal
