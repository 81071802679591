import Modal from "react-bootstrap/Modal"
import React, { useContext, useState, useEffect } from "react"
import { FirebaseContext } from "../Firebase"
import ExperienceInput from "./experience-input"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { checkRequiredFields } from "./utils/CheckRequiredFields"
import { FormattedMessage } from "react-intl"
import { sendStandardToast } from "../toasts/standard-toast"
import SubmitButton from "../portfolio/SubmitButton"
import SkillsInterestsEditor from "./SkillsInterestsEditor"
import { getExperienceAbbreviatedLabel, getExperienceInstructions, experienceTypes } from "./utils/experienceTypes"

const ResumeExperienceEditorModal = ({ experienceData, isNewExperience, show, closeTimelineModal, type }) => {
  const { firebase, notifier, profile } = useContext(FirebaseContext)

  /**
   *
   * State for all form data across every type of resume experience
   *
   */
  const [experience, setExperience] = useState({})
  const [isOptimizerModalOpen, setIsOptimizerModalOpen] = useState(false)

  useEffect(() => {
    let updatedExperienceData = {}
    if (experienceData) {
      Object.assign(updatedExperienceData, experienceData)
    }
    if (isNewExperience && type) {
      updatedExperienceData.type = type
    }
    setExperience(experience => ({...experience, ...updatedExperienceData}))
  }, [experienceData, isNewExperience, type])

  if (!experienceTypes.includes(type)) {
    return null
  }

  const setLocalExperienceKeyValue = (key, value) => {
    setExperience({
      ...experience,
      [key]: value,
    })
  }

  const dispatchHighlightAction = (type, payload, index) => {
    let highlightsCopy = experience.highlights ? [...(experience.highlights)] : []

    switch (type) {
      case "INITIALIZE_EMPTY_FIELDS":
        if (highlightsCopy.length === 0) {
          highlightsCopy = Array(3).fill("")
        }
        break
      case "UPDATE_INPUT_VALUE":
        highlightsCopy[index] = payload
        break
      case "DELETE_INPUT_FIELD":
        highlightsCopy.splice(index, 1)
        break
      case "CREATE_INPUT_FIELD":
        highlightsCopy.push("")
        break
      default:
        break
    }
    setExperience(oldData => ({
      ...oldData,
      highlights: highlightsCopy,
    }))
  }

  const toggleExperienceIsCurrent = (status) => {
    if (status) {
      setExperience({
        ...experience,
        endDateMonth: null,
        endDateYear: null,
      })
    } else {
      setExperience({
        ...experience,
        endDateMonth: "",
        endDateYear: "",
      })
    }
  }

  const handleCreateResumeExperience = async (experienceData) => {
    const errors = checkRequiredFields(experience, type)
    if (errors) {
      sendStandardToast({
        message: (
          <>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </>
        ),
        heading: <FormattedMessage id="resume-experience-editor-modal-submission-required-foll" defaultMessage="Submission requires the following" />,
      })
      return
    }

    try {
      await firebase.createResumeExperience(experienceData)
    } catch (e) {
      console.error(e)
    }
    handleCloseModal()
    await notifier.handleNewResumeExperience(profile)
  }

  const handleDeleteResumeExperience = async (experienceData) => {
    try {
      await firebase.deleteResumeExperience(experienceData.id)
    } catch (e) {
      console.error(e)
    }
    handleCloseModal()
  }

  const handleUpdateResumeExperience = async (experienceData) => {
    const errors = checkRequiredFields(experience, type)
    if (errors) {
      sendStandardToast({
        message: (
          <>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </>
        ),
        heading: <FormattedMessage id="resume-experience-editor-modal-submission-required-foll" defaultMessage="Submission requires the following" />,
      })
      return
    }
    try {
      await firebase.editResumeExperience(experienceData)
    } catch (e) {
      console.error(e)
    }
    handleCloseModal()
  }

  const handleCloseModal = () => {
    // might not have callback function avaliable
    if (closeTimelineModal) {
      closeTimelineModal()
    }

    setExperience(isNewExperience ? { ...experienceData, type: type } : experienceData)
  }

  return (
    <ExperienceEditorContext.Provider
      value={{
        experience,
        setLocalExperienceKeyValue,
        dispatchHighlightAction,
        checkRequiredFields,
        toggleExperienceIsCurrent,
        isOptimizerModalOpen,
        setIsOptimizerModalOpen,
      }}
    >
      <Modal className="editor-modal" show={show} onHide={() => handleCloseModal()} size="lg" animation={false}>
        <Modal.Header closeButton>
          <div className="eyebrow component-eyebrow">
            <FormattedMessage
              id={isNewExperience ? "resume-timeline-add" : "resume-experience-editor-modal-edit"}
              defaultMessage={isNewExperience ? "Add" : "Edit"}
            />
            {" "}
            {getExperienceAbbreviatedLabel(type)}
            {" "}
            <FormattedMessage
              id="resume-experience-editor-modal-experience"
              defaultMessage="Experience"
            />
          </div>
        </Modal.Header> 
        <Modal.Body>
          {isNewExperience && (
            <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
              {getExperienceInstructions(type)}
            </p>
          )}
          {(type === "skills" || type === "interests") && (
            <SkillsInterestsEditor
              currentItems={profile[type] || []}
              type={type}
            />
          )}
          {type !== "skills" && type !== "interests" && (
            <div className="form career-builder-milestones-form">
              <ExperienceInput type={type} dispatchHighlightAction={dispatchHighlightAction} />
            </div>
          )}
        </Modal.Body>
        {type !== "skills" && type !== "interests" && (
          <Modal.Footer>
            {!isNewExperience && (
              <SubmitButton
                label={<FormattedMessage id="resume-experience-editor-modal-delete" defaultMessage="Delete"/>}
                isFilled={false}
                onClick={() => handleDeleteResumeExperience(experience)}
              />
            )}
            <SubmitButton
              label={<FormattedMessage id="resume-experience-editor-modal-save" defaultMessage="Save"/>}
              isFilled={true}
              onClick={isNewExperience ? () => handleCreateResumeExperience(experience) 
                                        : () => handleUpdateResumeExperience(experience)}
            />
          </Modal.Footer>
        )}
      </Modal>
    </ExperienceEditorContext.Provider>
  )
}

export default ResumeExperienceEditorModal
