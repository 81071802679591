import React from "react"
import { useIntl } from "react-intl"
import { allowedMonths } from "../../careers/AllowedTypes"
import { renderMonthName } from "../../resume/utils/utils"
import { Dropdown } from "semantic-ui-react"
import "./DateInput.css"

/**
 * Allows a user to input a date.
 * 
 * @prop {string} month - the selected month value as a 2-digit numerical string 
 *                        (Ex: "01", "12", etc.), or "" if no month is selected
 * @prop {string} year - the inputted year
 * @prop {function} updateMonth - runs when selected month changes
 * @prop {function} updateYear - runs when inputted year changes
 */
const DateInput = ({month="", year="", updateMonth, updateYear}) => {
  const { formatMessage, locale } = useIntl()

  const monthOptions = allowedMonths.map(monthCode => ({
    key: monthCode,
    value: monthCode,
    text: renderMonthName(monthCode, locale)
  }))

  return (
    <div className="date-input">
      <Dropdown
        className="month-input"
        selection
        fluid={true}
        icon='chevron down'
        options={monthOptions}
        onChange={(e, d) => updateMonth(d.value)}
        selectOnBlur={false}
        clearable={true}
        placeholder={formatMessage({ id: "experience-date-input-month-placeholder", defaultMessage: "Month" })}
        value={month}
        scrolling
      />
      <input 
        type="text"
        className="year-input"
        maxLength={4}
        onChange={(event) => updateYear(event.target.value)} 
        placeholder={formatMessage({ id: "experience-dates-input-year", defaultMessage: "Year" })}
        value={year} 
      />
    </div>
  )
}

export default DateInput
