import React, { useState } from "react"
import convertMimeToExtension from "./convertMimeToExtension"
import { sendStandardToast } from "../toasts/standard-toast.js";
import "./FileUploadButton.css"

/**
 * Button that allows user's to upload files by either clicking on it, or dragging
 * onto it from desktop
 * @prop {string} instructions - message rendered within the button
 * @prop {function} processFiles - function that takes in an array of Javascript 
 * File objects, which will run after the user's files have finished uploading to the browser
 * @prop {string[]} allowedTypes - array of MIME type that are allowed to be uploaded 
 * using this button
 * @prop {string} id - unique identifier associated with this button (needed to prevent bugs)
 */
const FileUploadButton = ({instructions, processFiles, allowedTypes, id=""}) => {
  const [draggingOver, setDraggingOver] = useState(false)

  const plusSign = (
    <svg className="plus-sign" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-0.000488281 15.002H29.9995" stroke="#40777C" strokeWidth="2"/>
      <path d="M15 -0.00390625V29.9961" stroke="#40777C" strokeWidth="2"/>
    </svg>
  )

  const uploadFiles = (files) => {
    if (Array.isArray(allowedTypes)) {
      for (const f of files) {
        if (!allowedTypes.includes(f.type)) {
          sendStandardToast({
            message: `The supported file types are ${allowedTypes.map(t => convertMimeToExtension(t)).join(", ")}`,
            heading: "Unsupported File Type",
          })
          return
        }
      }
    }
    processFiles(files)
  }

  const dropHandler = (ev) => {
    console.log("dropped a file!")
    ev.preventDefault() // prevent file from being opened

    const uploadedFiles = []
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          console.log(`… file[${i}].name = ${file.name}`);
          uploadedFiles.push(file)
        }
      });
    } else {
      return
    }

    uploadFiles(uploadedFiles)
    setDraggingOver(false)
  }

  const dragOverHandler = (ev) => {
    setDraggingOver(true)
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault()
  }

  const handleFilesAdd = (event) => {
    event.persist()
    if (event.target.files) {
      uploadFiles(Array.from(event.target.files))
    }
  }

  const dragLeaveHandler = () => {
    setDraggingOver(false)
  }

  return (
    <label
      tabIndex={0}
      htmlFor={`fileUpload-${id}`}
      className="file-upload-button"
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
      style={{backgroundColor: (draggingOver ? "#EDFBFF" : undefined)}}
    >
      {plusSign}
      {instructions && (
        <div className="instructions">
          {instructions}
        </div>
      )}
      <input 
        id={`fileUpload-${id}`}
        type="file"
        accept={Array.isArray(allowedTypes) ? allowedTypes.join() : undefined}
        onChange={handleFilesAdd} 
        multiple 
      />
    </label>
  )
}

export default FileUploadButton
