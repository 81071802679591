import { useSortable } from '@dnd-kit/sortable'

/**
 * Make an element sortable by dragging and dropping.
 * 
 * @prop {string} id - a unique string that identifies this element
 * @prop {boolean} disableDragging - whether dragging should be disabled for this element
 * @prop {number} scale - how large the element should be during dragging
 * @prop {boolean} separateDragHandle - if only part of the element can be used to drag it
 * 
 * @returns itemProperties - properties to be attached to the sortable element
 * @returns handleProperties - if separateDragHandle is true, properties to be attached to the drag handle element
 * @returns activeIndex - index of the element currently being dragged
 * @returns index - index of this element, 
 * @returns isDragging - whether this element is currently being dragged
 * @returns isSorting - whether this element is currently being sorted (in response to another element being dragged)
 * @returns isOver - whether an element is currently being dragged over this element
 * */
const useSortableItem = ({
  id, 
  disableDragging=false, 
  scale=1.1, 
  separateDragHandle=false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    isOver,
    activeIndex,
    index,
  } = useSortable({id: id})

  const draggingScale = isDragging ? scale : 1

  const itemStyle = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0) scaleX(${draggingScale}) scaleY(${draggingScale})` : undefined,
    transition,
    zIndex: isDragging ? "1" : "0",
    boxShadow: isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : undefined,
  }

  const handleStyle = {
    cursor: isDragging ? "grabbing" : "grab",
    touchAction: "none",
  }

  let itemProperties = {}
  let handleProperties = {}

  if (!disableDragging && !separateDragHandle) {
    itemProperties = {
      ref: setNodeRef,
      style: {...itemStyle, ...handleStyle},
      ["data-dnd"]: "true",
      ...listeners,
      ...attributes,
    }
  } else if (!disableDragging && separateDragHandle) {
    itemProperties = {
      ref: setNodeRef,
      style: itemStyle,
    }
    handleProperties = {
      ["data-dnd"]: "true",
      ...listeners,
      ...attributes,
      style: handleStyle,
    }
  }

  return {
    itemProperties,
    handleProperties,
    activeIndex, 
    index, 
    isDragging, 
    isSorting,
    isOver,
  }
}

export default useSortableItem
