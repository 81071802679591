import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../Firebase/index.js"
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { pointerWithin, closestCenter } from "@dnd-kit/core";
import ResumeItem from "./ResumeItem.js";
import SortableContainer from "../drag-and-drop/SortableContainer.js";
import { combineCollisionAlgorithms } from "../drag-and-drop/customCollisionDetectionAlgorithms.js";
import "./ResumeColumns.css"

/**
 * A single column of a public or private resume section
 * 
 * @prop {string} userId
 * @prop {string} columnNumber - the columns number, used for styling (currently just "1" or "2")
 * @prop {string[]} defaultOrder - array of the experience types that belong in this column, in the default order they should be displayed
 * @prop {*} resumeData - object that maps experience types to JSX displaying that experience type's data
 * @prop {boolean} isPublicFacing - is the public view 
 */
const ResumeColumn = ({userId, columnNumber, defaultOrder, resumeData, isPublicFacing}) => {
  const { firebase } = useContext(FirebaseContext)
  const [potentialOrder, setPotentialOrder] = useState([])

  const updatePotentialOrder = (newOrder) => {   
    // ensure all items from defaultOrder, and none that aren't in defaultOrder,
    // are included (in case we eventually change which types go in which column)
    const allowedItemTypes = newOrder.filter(type => defaultOrder.includes(type))
    const missingItemTypes = defaultOrder.filter(type => !newOrder.includes(type))
    setPotentialOrder([...allowedItemTypes, ...missingItemTypes])
  }

  useEffect(() => {
    if (firebase && columnNumber) {
      const unsubscribeFunction = firebase.subscribeToResumeOrderChanges(userId, columnNumber, updatePotentialOrder)
      return () => unsubscribeFunction()
    }
  }, [firebase, columnNumber, userId])

  const setOrder = async (newOrder) => {
    updatePotentialOrder(newOrder)
    await firebase.updateResumeColumnOrder(columnNumber, newOrder)
  }

  const actualOrder = potentialOrder.filter(itemType => resumeData[itemType] !== undefined)
  if (actualOrder.length === 0) {
    return null
  }

  return (
    <SortableContainer
      className={`resume-timelines-column-${columnNumber}`}
      setOrder={setOrder}
      collisionDetectionAlgorithm={combineCollisionAlgorithms([pointerWithin, closestCenter])}
      sortingStrategy={verticalListSortingStrategy}
      idList={actualOrder}
    >
      {actualOrder.map(itemType => (
        <ResumeItem key={itemType} type={itemType} isPublicFacing={isPublicFacing}>
          {resumeData[itemType]}
        </ResumeItem>
      ))}
    </SortableContainer>
  )
}

export default ResumeColumn
