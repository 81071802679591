import React, {useState, useContext, useEffect} from "react"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { rectIntersection, closestCenter } from '@dnd-kit/core';
import { combineCollisionAlgorithms } from "../drag-and-drop/customCollisionDetectionAlgorithms.js";
import useSortableItem from "../drag-and-drop/useSortableItem.js";
import SortableContainer from "../drag-and-drop/SortableContainer.js";
import { localizeSkillInterest } from "./utils/skillAndInterestDefaultOptions.js";
import { useIntl } from "react-intl"
import "./ResumeList.css"

/**
 * A single, draggable item in a ResumeList
 */
const ListItem = ({content, onDelete, isPublicFacing}) => {
  const { itemProperties, activeIndex, index, isDragging, isOver} = useSortableItem({id: content, disableDragging: isPublicFacing})
  const { locale } = useIntl()
  const displayedText = localizeSkillInterest(locale, content)

  return (
    <div className="list-item" {...itemProperties}>
      <div className="label">{displayedText}</div>
      {!isPublicFacing && (
        <div className="delete-button-container">
          <button className="delete-button" onClick={onDelete}>
            <FontAwesomeIcon icon={faTimes} size="xs"/>
          </button>
        </div>
      )}
      {(isOver && !isDragging) && (
        <div className={`insert-indicator insert-indicator-${(activeIndex < index ? "right" : "left")}`}/>
      )}
    </div>
  )
}

/**
 * Section of a public/private resume that is just a list of things, like skills
 * or interests
 */
const ResumeList = ({type, items, isPublicFacing}) => {
  const { firebase } = useContext(FirebaseContext)
  const [list, setList] = useState([])

  useEffect(() => {
    if (items) {
      setList(items)
    }
  }, [items])

  const updateListOrder = (newOrder) => {
    setList(newOrder)
    firebase.updateProfile({
      data: {[type]: newOrder}
    })
  }

  return (
    <SortableContainer
      className="resume-list-container"
      setOrder={updateListOrder}
      collisionDetectionAlgorithm={combineCollisionAlgorithms([rectIntersection, closestCenter])}
      sortingStrategy={() => null}
      idList={list}
      indicatorPadding="1rem"
    >
      <div className="resume-list-container">
        {list.map(item => (
          <ListItem 
            key={item} 
            content={item} 
            onDelete={() => updateListOrder(list.filter(i => i !== item))}
            isPublicFacing={isPublicFacing}
          />
        ))}
      </div>
    </SortableContainer>
  )
}

export default ResumeList
