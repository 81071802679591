export const getSchoolSpecificCareers = (allCareers, schoolMajors) => {
  const schoolMajorCips = schoolMajors.map((major) => major.major_id)
  // filter out careers which do not have a related major found in the schools list of majors

  const careersWithReferencesToSchoolMajors = allCareers
    .filter((career) => {
      const relatedMajorsCips = career.career_related_major.map((relatedMajor) => relatedMajor.metadata?.major_cip)

      return relatedMajorsCips.some((relatedMajorCip) => schoolMajorCips.includes(relatedMajorCip))
    })
    .map((career) => {
      const relatedMajors = career.career_related_major.filter((relatedMajor) => {
        const relatedMajorCip = relatedMajor.metadata?.major_cip
        return schoolMajorCips.includes(relatedMajorCip)
      })

      return {
        ...career,
        career_related_major: relatedMajors,
      }
    })

  // remove related majors from career object which are not found in the schools list of majors
  return careersWithReferencesToSchoolMajors
}
