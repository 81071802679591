import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal"
import AddButton from "../portfolio/AddButton"
import useSortableItem from "../drag-and-drop/useSortableItem"
import { getExperienceLabel } from "./utils/experienceTypes"
import useCheckIfMobile from "../hooks/useCheckIfMobile"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAltV } from "@fortawesome/pro-solid-svg-icons"
import "./ResumeItem.css"

/**
 * Wrapper component for a single section (for example "Work Experience", 
 * "Education", etc.) of a public or private resume
 */
const ResumeItem = ({type, isPublicFacing, children}) => {
  const [currentlyAdding, setCurrentlyAdding] = useState(false)
  const { isMobile } = useCheckIfMobile()
  const { 
    itemProperties, 
    handleProperties, 
    isDragging,
  } = useSortableItem({id: type, disableDragging: isPublicFacing, scale: 1.04, separateDragHandle: isMobile})

  return (
    <>
      {!isPublicFacing && (
        <ResumeExperienceEditorModal
          isNewExperience={true}
          show={currentlyAdding}
          type={type}
          closeTimelineModal={() => setCurrentlyAdding(false)}
        />
      )}
      <div className="resume-item" {...itemProperties}>
        {isMobile && !isPublicFacing && (
          <div className={`drag-handle ${isDragging ? "dragging" : ""}`} {...handleProperties}>
            <FontAwesomeIcon icon={faArrowsAltV} size="lg" />
            <FormattedMessage
              id="resume-drag-and-drop"
              defaultMessage="Drag and Drop"
            />
          </div>
        )}
        <div className="item-content">
          <div className="item-header">
            <div className="item-label">
              {getExperienceLabel(type)}
            </div>
            {!isPublicFacing && (
              <AddButton 
                label={<FormattedMessage id="resume-timeline-add" defaultMessage="+ Add" />}
                onClick={() => setCurrentlyAdding(true)}
              />
            )}
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

export default ResumeItem
