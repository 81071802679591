import { Paragraph, TextRun, AlignmentType, Header } from "docx"
import { renderWorkSection } from "./renderWorkSection.js"
import { renderVolunteerSection } from "./renderVolunteerSection.js"
import { renderEducationSection } from "./renderEducationSection.js"
import { renderAwardSection } from "./renderAwardSection.js"
import { renderLifeSection } from "./renderLifeSection.js"
import { renderSkillsSection } from "./renderSkillsSection.js"
import { renderInterestsSection } from "./renderInterestsSection.js"
import { renderLeadershipSection } from "./renderLeadershipSection.js"

const classicTemplate = (
  name,
  city,
  state,
  email,
  work,
  education,
  volunteer,
  awards,
  life,
  leadership,
  skills,
  interests,
  sectionOrder,
) => {
  const typeToSection = {
    "education": renderEducationSection(education),
    "work": renderWorkSection(work),
    "leadership": renderLeadershipSection(leadership),
    "volunteer": renderVolunteerSection(volunteer),
    "award": renderAwardSection(awards),
    "life": renderLifeSection(life),
    "skills": renderSkillsSection(skills),
    "interests": renderInterestsSection(interests),
  }

  return {
    styles: {
      paragraphStyles: [
        {
          name: "Normal",
          id: "work-history",
          run: {
            font: "cambria",
            size: 20,
          },
          paragraph: {
            font: "cambria",
            size: 20,
          },
        },
      ],
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${name ?? "NAME"}`,
                    font: "cambria",
                    size: 32,
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.CENTER,
                bold: true,
              }),
              new Paragraph({
                text: `${city ? city : "CITY"}, ${
                  state ? state : "STATE"
                } - PHONE - ${email ?? "EMAIL"}`,
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        properties: {
          page: {
            margin: {
              top: 0,
              right: 720,
              bottom: 300,
              left: 720,
            },
          },
        },
        children: sectionOrder.map(type => typeToSection[type] || []).flat(),
      },
    ],
  }
}

export default classicTemplate
