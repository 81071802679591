import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import { FormattedMessage, useIntl } from "react-intl"
import ExperienceBulletsInput from "./experience-bullets-input"

const ExperienceInputEducation = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-education-form-school-placeholder', defaultMessage: "Ex. Barnard College" })}
        label={<FormattedMessage
          id="resume-experience-form-school"
          defaultMessage="School"
        />}
        dataKey="school"
        required={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-education-form-degree-placeholder', defaultMessage: "Ex. Bachelors" })}
        label={<FormattedMessage
          id="resume-experience-form-degree"
          defaultMessage="Degree"
        />}
        dataKey="degree"
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-education-form-field-of-study-placeholder', defaultMessage: "Ex. English, with a concentration in creative writing" })}
        label={<FormattedMessage
          id="resume-experience-form-field-of-study"
          defaultMessage="Field of study"
        />}
        required={true}
        dataKey="field"
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-education-form-location-placeholder', defaultMessage: "Ex. New York City" })}
        label={<FormattedMessage
          id="resume-experience-form-location"
          defaultMessage="Location"
        />}
        dataKey="location"
        required={true}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={false}
        isSingle={false}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-education-form-description-placeholder', defaultMessage: "Ex. Enrolled in 5-year, 3-2 program (2 degrees in 5 years, one in the humanities and one in engineering)" })}

        label={<FormattedMessage
          id="resume-experience-form-description"
          defaultMessage="Description"
        />}
        dataKey="description"
        inputType="textarea"
      />
      <ExperienceBulletsInput
        label="Activities"
        dataKey="highlights"
        placeholders={[
          formatMessage({ id: 'resume-experience-education-form-activities-placeholder-1', defaultMessage: "Ex. President of the student government class." }),
          formatMessage({ id: 'resume-experience-education-form-activities-placeholder-2', defaultMessage: "Ex. Writer for the Columbia Spectator newspaper." }),
        ]}
      />
    </>
  )
}

export default ExperienceInputEducation
