import { 
  PointerSensor, 
  useSensor,
  useSensors
} from '@dnd-kit/core';

/**
 * Determines whether clicking an element should initiate dragging and dropping.
 * If clickedElement does not satisfy any of the following conditions, uses the
 * closest parent element that does.
 * 
 * Dragging proceeds if the element's data-dnd attribute is "true"
 * 
 * Dragging is blocked if:
 *  1) the element is a button, input, or textareas.
 *  2) The data-dnd attribute is "false" (to allow fine-grained control of 
 *     dragging and dropping).
 *  3) The element is an open modal (to prevent accidentally dragging items in
 *     the background).
 *
 * @param {Element} clickedElement - element the user clicked
 * @returns {boolean} whether dragging can be initiated
 */
const customHandler = (clickedElement) => {
  let current = clickedElement;
  // This list can be expanded to include additional types of interactive elements
  const IGNORE_TAGS = ['BUTTON', 'INPUT', 'TEXTAREA'];

  while (current && !(current.dataset && current.dataset.dnd === "true")) {
    if (IGNORE_TAGS.includes(current.tagName) 
        || (current.dataset && current.dataset.dnd === "false") 
        || (current.classList && current.classList.contains("modal-open"))) {
      return false;
    }
    current = current.parentElement;
  }

  return true;
};

PointerSensor.activators = [
  {
    eventName: 'onPointerDown',
    handler: (event) => customHandler(event.target),
  },
];

/**
 * Use custom drag-and-drop sensors that allow better control of what initiates
 * dragging and dropping. See the documentation of "customHandler" for an 
 * in-depth description of this.
 */
const useCustomSensors = () => {
  return useSensors(
    useSensor(PointerSensor),
  )
}

export {
  PointerSensor as CustomPointerSensor,
  useCustomSensors,
}
