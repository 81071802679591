import React, { useState } from "react"
import PortfolioProfile from "../../../static/assets/images/portfolio-profile-default.jpg"
import ProfileImageModal from "../dashboard/portfolio-profile-image-modal"
import useModal from "../hooks/useModal"
import PortfolioShareModal from "../dashboard/portfolio-share-modal"
import { FormattedMessage } from "react-intl"
import ResumeDocModal from "../resume/docxExport/resume-doc-modal"
import AddButton from "./AddButton"
import ExperienceTypeSelector from "../resume/experience-type-selector-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShare } from "@fortawesome/pro-solid-svg-icons"
import "./portfolio-heading.css"

/**
 * The heading of a user's public or private portfolio
 */
const PortfolioHeading = ({profile = {}, isPublicFacing}) => {
  const [isShowingPortfolioShareModal, togglePortfolioShareModal] = useModal()
  const [selectorModalOpen, setSelectorModalOpen] = useState(false)

  const showLocation = isPublicFacing 
                       && (profile.city || profile.state) 
                       && !(profile.introduction || profile.aboutMe || profile.myWeaknesses || profile.myStrengths)

  return (
    <div className="portfolio-heading">
      <div className="profile-picture-container">
        <img
          className="profile-picture"
          src={profile.profileImageUrl || PortfolioProfile}
          alt="User's profile picture"
        />
        {!isPublicFacing && (
          <div className="profile-picture-edit image-edit-button-container">
            <ProfileImageModal currentImage={profile.profileImageUrl || PortfolioProfile} />
          </div>
        )}
      </div>
      {/* This div fills the space that the profile picture would fill if it 
        * wasn't absolutely positioned */}
      <div className="profile-picture-standin"/>
      {profile.firstName && (
        <h2>
          <strong>{profile.firstName}</strong>
          {profile.lastName ? ` ${profile.lastName}` : ""}
        </h2>
      )}
      {showLocation && (
        <div className="location">
          {`${profile.city}${profile.city && profile.state && ", "}${profile.state}`}
        </div>
      )}
      {!isPublicFacing && (
        <>
        <ExperienceTypeSelector
          isSelectorModalOpen={selectorModalOpen}
          closeSelectorModal={() => setSelectorModalOpen(false)}
        />
        <AddButton
          label={<FormattedMessage id="portfolio-heading-add-experience" defaultMessage="Add experience" />}
          onClick={() => setSelectorModalOpen(true)}
        />
        <div className="portfolio-heading-right">
          <button className="share-button" onClick={() => togglePortfolioShareModal()} title="Share">
            <FormattedMessage id="portfolio-share" defaultMessage="Share" />
            {" "}
            <FontAwesomeIcon icon={faShare} size="lg" color="#54757A" />
          </button>
          <PortfolioShareModal show={isShowingPortfolioShareModal} togglePortfolioShareModal={togglePortfolioShareModal} />
          <ResumeDocModal />
        </div>
        </>
      )}
    </div>
  )
}

export default PortfolioHeading
