import React from "react"
import MediaPanel from "./MediaPanel.js"
import { closestCenter } from '@dnd-kit/core';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import SortableContainer from "../../drag-and-drop/SortableContainer.js";
import { FormattedMessage } from "react-intl";
import useStoryboardPanelsOrder from "../useStoryboardPanelsOrder.js";
import "./StoryboardPanelsContainer.css"
import "../../portfolio/placeholder-section-styles.css"

/**
 * Displays the private/editable view of the current user's storyboard panels 
 */
const StoryboardPanelsContainer = () => {
  const {panelIds, updatePanelIdsOrder} = useStoryboardPanelsOrder(null)
  
  if (panelIds.length === 0) {
    return (
      <div className="no-panels">
        <div className="placeholder-title">
          <FormattedMessage
            id="storyboard"
            defaultMessage="Storyboard"
          />
        </div>
        <div className="placeholder-instructions">
          <FormattedMessage
            id="storyboard-placeholder-instructions"
            defaultMessage="All images, videos, and documents that you upload will be displayed here."
          />
        </div>
      </div>
    )
  } else {
    return (
      <SortableContainer
        className="has-panels"
        setOrder={updatePanelIdsOrder}
        collisionDetectionAlgorithm={closestCenter}
        sortingStrategy={rectSortingStrategy}
        idList={panelIds}
      >
        {panelIds.map(panelID => <MediaPanel key={panelID} id={panelID} />)}
      </SortableContainer>
    )
  }
}

export default StoryboardPanelsContainer
