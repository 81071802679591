import { faRocket } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ProgressBar } from "react-bootstrap"
import React, { useState, useContext, useEffect, Fragment } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import "./daily-quest.css"

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons"

import { faChevronCircleRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FirebaseContext } from "../Firebase"
import Link, { navigate } from "../../link"

const DailyQuest = () => {
  const { localFirestore, notifier, profile } = useContext(FirebaseContext)
  const [completionStatus, setCompletionStatus] = useState("ACTIVE")
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0)

  const todaysQuest = localFirestore.dailyQuest || []

  const handleSubmitResponse = async ({ response, promptId }) => {
    const isLast = currentPromptIndex === todaysQuest.length - 1

    await localFirestore.setDailyQuestResponse({ response, promptId })

    if (isLast) {
      const { pointsAwarded } = localFirestore.getDailyQuestResults()

      await notifier.handleDailyQuestCompletion(pointsAwarded, profile)
    }
  }

  useEffect(() => {
    const { currentQuestionIndex, questCompletionStatus } = localFirestore.getDailyQuestCompletionStatus()
    setCurrentPromptIndex(currentQuestionIndex)
    setCompletionStatus(questCompletionStatus)
  }, [])

  return (
    <div className="daily-quest dashboard-daily-quest">
      <div className="daily-quest-heading">
        <div className="eyebrow component-eyebrow">
          <FormattedMessage id="daily-quest-heading" defaultMessage="Daily Quest " />
          <FontAwesomeIcon icon={faRocket} color={"#B849CE"} style={{ marginLeft: ".5rem" }} />
        </div>
        <div className="daily-quest-progress">
          <span>
            {completionStatus === "ACTIVE" && (
              <>
                {" "}
                {currentPromptIndex + 1}/{todaysQuest.length}{" "}
              </>
            )}
          </span>
          <ProgressBar variant="info" now={completionStatus === "ACTIVE" ? (currentPromptIndex / todaysQuest.length) * 100 : 100} />
        </div>
      </div>

      <div className="daily-quest-body">
        {completionStatus === "ACTIVE" && (
          <PromptSlides
            setCompletionStatus={setCompletionStatus}
            handleSubmitResponse={handleSubmitResponse}
            currentPromptIndex={currentPromptIndex}
            setCurrentPromptIndex={setCurrentPromptIndex}
            todaysQuest={localFirestore.dailyQuest || []}
          />
        )}
        {completionStatus === "COMPLETE" && <CompletionSlide />}
      </div>
    </div>
  )
}

const StoryImage = ({ storyImage, storySlug }) => {
  if (!storyImage) {
    return <></>
  }

  return (
    <Link to={`/story/${storySlug}`}>
      <img src={`${storyImage}?w=400&h=300&q=100&fit=facearea&facepad=6&ar=13:2`} alt="story" style={{width: '200px'}}/>
    </Link>
  )
}

const PromptSlides = ({ todaysQuest, setCompletionStatus, currentPromptIndex, setCurrentPromptIndex, handleSubmitResponse }) => {
  const incrementPromptIndex = () => {
    if (currentPromptIndex < todaysQuest.length - 1) {
      setCurrentPromptIndex(currentPromptIndex + 1)
    } else {
      setCurrentPromptIndex(0)
      setCompletionStatus("COMPLETE")
    }
  }

  return (
    <>
      {todaysQuest.map((question, index) => {
        switch (question.question_type.value) {
          case "yes / no":
            return (
              <Fragment key={`question-${index}`}>
                <YesNoSlide
                  keyName={`question-${index}`}
                  isCurrent={index === currentPromptIndex}
                  incrementPromptIndex={incrementPromptIndex}
                  question={question}
                  handleSubmitResponse={handleSubmitResponse}
                />
              </Fragment>
            )
          case "multiple choice":
            return (
              <Fragment key={`question-${index}`}>
                <MultipleChoiceSlide
                  keyName={`question-${index}`}
                  isCurrent={index === currentPromptIndex}
                  incrementPromptIndex={incrementPromptIndex}
                  question={question}
                  handleSubmitResponse={handleSubmitResponse}
                />
              </Fragment>
            )
          case "open response":
            return (
              <Fragment key={`question-${index}`}>
                <OpenResponseSlide
                  keyName={`question-${index}`}
                  isCurrent={index === currentPromptIndex}
                  incrementPromptIndex={incrementPromptIndex}
                  question={question}
                  handleSubmitResponse={handleSubmitResponse}
                />
              </Fragment>
            )
        }
      })}
    </>
  )
}

// switch to DOMPurify
const YesNoSlide = ({ question, isCurrent, incrementPromptIndex, handleSubmitResponse, keyName }) => {
  const { locale } = useIntl()
  const { promptId } = question
  const [isNextEnabled, setIsNextEnabled] = useState(false)

  const [userResponse, setUserResponse] = useState(null)

  const handleYesNoClick = (response) => {
    setUserResponse(response)
    setIsNextEnabled(true)
  }

  const handleYesNoSubmit = () => {
    if (userResponse === null) return
    handleSubmitResponse({ response: userResponse, promptId: promptId })
  }

  return (
    <Fragment key={keyName}>
      {isCurrent && (
        <>
          <div className="daily-quest-content">
            <div className="daily-quest-story">
              <StoryImage storyImage={question.storyImage} storySlug={question.storySlug} />

              <div
                dangerouslySetInnerHTML={{
                  __html: locale !== "es-ES" ? question.question_prompt : question.question_prompt_es,
                }}
                className="daily-quest-prompt-text"
              />
            </div>

            <div className="daily-quest-option-grid">
              <button
                className={userResponse === "yes" ? "correct" : ""}
                onClick={() => {
                  handleYesNoClick("yes")
                }}
              >
                <FormattedMessage id="daily-quest-yes" defaultMessage="Yes" />
              </button>
              <button
                className={userResponse === "no" ? "correct" : ""}
                onClick={() => {
                  handleYesNoClick("no")
                }}
              >
                <FormattedMessage id="daily-quest-no" defaultMessage="No" />
              </button>
            </div>
          </div>
          <QuestFooter
            responseStatus={"NONE"}
            incrementPromptIndex={incrementPromptIndex}
            isNextEnabled={isNextEnabled}
            submissionCallback={handleYesNoSubmit}
          />
        </>
      )}
    </Fragment>
  )
}

const MultipleChoiceSlide = ({ question, isCurrent, incrementPromptIndex, handleSubmitResponse, keyName }) => {
  const { locale } = useIntl()
  const {
    question_prompt,
    question_prompt_es,
    multiple_choice_option_1,
    multiple_choice_option_2,
    multiple_choice_option_3,
    multiple_choice_option_4,
    multiple_choice_option_1_es,
    multiple_choice_option_2_es,
    multiple_choice_option_3_es,
    multiple_choice_option_4_es,
    multiple_choice_answer,
    promptId,
    storyImage,
    storySlug,
  } = question

  const [responseStatus, setResponseStatus] = useState("NONE")
  const [isNextEnabled, setIsNextEnabled] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const answerDictionary = {
    1: multiple_choice_option_1,
    2: multiple_choice_option_2,
    3: multiple_choice_option_3,
    4: multiple_choice_option_4,
  }

  const spanishAnswerDictionary = {
    1: multiple_choice_option_1_es,
    2: multiple_choice_option_2_es,
    3: multiple_choice_option_3_es,
    4: multiple_choice_option_4_es,
  }

  const handleMultipleChoiceSubmit = (responseIndex) => {
    if (responseStatus === "CORRECT" || responseStatus === "INCORRECT") return

    setSelectedOption(responseIndex)

    handleSubmitResponse({ response: responseIndex, promptId: promptId })

    if (responseIndex === multiple_choice_answer) {
      setResponseStatus("CORRECT")
    } else {
      setResponseStatus("INCORRECT")
    }

    setIsNextEnabled(true)
  }

  const renderClassName = (index) => {
    if (responseStatus === "CORRECT" && index === multiple_choice_answer) {
      return "correct"
    } else if (responseStatus === "INCORRECT" && index === selectedOption) {
      return "incorrect"
    } else {
      return ""
    }
  }

  return (
    <Fragment key={keyName}>
      {isCurrent && (
        <>
          <div className="daily-quest-content">
            <div className="daily-quest-story">
              <StoryImage storyImage={storyImage} storySlug={storySlug} />
              <div
                dangerouslySetInnerHTML={{
                  __html: locale !== "es-ES" ? question_prompt : question_prompt_es,
                }}
                className="daily-quest-prompt-text"
              />
            </div>

            <div className="daily-quest-option-grid">
              {multiple_choice_option_1 && (
                <button
                  className={renderClassName(1)}
                  onClick={() => {
                    handleMultipleChoiceSubmit(1)
                  }}
                >
                  {locale !== "es-ES" ? multiple_choice_option_1 : multiple_choice_option_1_es}
                </button>
              )}
              {multiple_choice_option_2 && (
                <button
                  className={renderClassName(2)}
                  onClick={() => {
                    handleMultipleChoiceSubmit(2)
                  }}
                >
                  {locale !== "es-ES" ? multiple_choice_option_2 : multiple_choice_option_2_es}
                </button>
              )}
              {multiple_choice_option_3 && (
                <button
                  className={renderClassName(3)}
                  onClick={() => {
                    handleMultipleChoiceSubmit(3)
                  }}
                >
                  {locale !== "es-ES" ? multiple_choice_option_3 : multiple_choice_option_3_es}
                </button>
              )}
              {multiple_choice_option_4 && (
                <button
                  className={renderClassName(4)}
                  onClick={() => {
                    handleMultipleChoiceSubmit(4)
                  }}
                >
                  {locale !== "es-ES" ? multiple_choice_option_4 : multiple_choice_option_4_es}
                </button>
              )}
            </div>
          </div>
          <QuestFooter
            responseStatus={responseStatus}
            isNextEnabled={isNextEnabled}
            correctAnswer={
              responseStatus === "INCORRECT"
                ? `${locale !== "es-ES" ? answerDictionary[multiple_choice_answer] : spanishAnswerDictionary[multiple_choice_answer]}`
                : ""
            }
            incrementPromptIndex={incrementPromptIndex}
          />
        </>
      )}
    </Fragment>
  )
}

const OpenResponseSlide = ({ question, isCurrent, incrementPromptIndex, handleSubmitResponse, keyName }) => {
  const { locale } = useIntl()
  const [userResponse, setUserResponse] = useState("")

  const handleChange = (event) => {
    setUserResponse(event.target.value)
  }

  const handleOpenResponseSubmit = () => {
    handleSubmitResponse({
      response: userResponse,
      promptId: question.promptId,
    })
  }

  return (
    <Fragment key={keyName}>
      {isCurrent && (
        <>
          <div className="daily-quest-content">
            <div className="daily-quest-story">
              <StoryImage storyImage={question.storyImage} storySlug={question.storySlug} />
              <div
                dangerouslySetInnerHTML={{
                  __html: locale !== "es-ES" ? question.question_prompt : question.question_prompt_es,
                }}
                className="daily-quest-prompt-text"
              />
            </div>
            <textarea onChange={handleChange} name="open-response" rows="4" cols="50" />
          </div>
          <QuestFooter
            responseStatus={"NONE"}
            isNextEnabled={userResponse.length > 0}
            submissionCallback={handleOpenResponseSubmit}
            incrementPromptIndex={incrementPromptIndex}
          />
        </>
      )}
    </Fragment>
  )
}

const CompletionSlide = () => {
  const { firebase, localFirestore } = useContext(FirebaseContext)
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [pointsAwarded, setPointsAwarded] = useState(0)

  useEffect(() => {
    const { totalCorrect, totalQuestions, pointsAwarded } = localFirestore.getDailyQuestResults()

    setTotalCorrectAnswers(totalCorrect)
    setTotalQuestions(totalQuestions)
    setPointsAwarded(pointsAwarded)
  }, [firebase])

  return (
    <div className="daily-quest-completion">
      <div className="daily-quest-score">
        <FontAwesomeIcon icon={faRocket} size="3x" color={"#B849CE"} />
        <div>
          <h6>
            <FormattedMessage id="daily-quest-scored" defaultMessage="You Scored" />
          </h6>
          <h3>
            {totalCorrectAnswers} / {totalQuestions}
          </h3>
        </div>
      </div>
      <span>
        <FormattedMessage id="daily-quest-congrats" defaultMessage="Congratulations on completing the task!" />
        <br />
        <FormattedMessage id="daily-quest-congrats-earned" defaultMessage="You have earned" /> {pointsAwarded}{" "}
        <FormattedMessage id="daily-quest-points" defaultMessage=" points." />
      </span>
    </div>
  )
}

// response status can be "CORRECT", "INCORRECT", or "NONE"
const QuestFooter = ({ responseStatus, submissionCallback, isNextEnabled, correctAnswer, incrementPromptIndex }) => {
  const handleNextClick = () => {
    if (submissionCallback) {
      submissionCallback()
    }
    incrementPromptIndex()
  }

  const [dynamicFeedback, setDynamicFeedback] = useState(null)

  const renderPositiveFeedback = () => {
    let randomInt = Math.floor(Math.random() * 5)

    switch (randomInt) {
      case 0:
        return <FormattedMessage id="daily-quest-feedback-correct-0" defaultMessage="Amazing!" />
      case 1:
        return <FormattedMessage id="daily-quest-feedback-correct-1" defaultMessage="Nicely done!" />
      case 2:
        return <FormattedMessage id="daily-quest-feedback-correct-2" defaultMessage="Correct!" />
      case 3:
        return <FormattedMessage id="daily-quest-feedback-correct-3" defaultMessage="Excellent!" />
      case 4:
        return <FormattedMessage id="daily-quest-feedback-correct-4" defaultMessage="Good!" />
      default:
        return <FormattedMessage id="daily-quest-feedback-correct" defaultMessage="Amazing!" />
    }
  }

  useEffect(() => {
    if (responseStatus === "CORRECT") {
      setDynamicFeedback(renderPositiveFeedback())
    }
  }, [responseStatus])

  return (
    <div className="daily-quest-footer">
      <div className="daily-quest-feedback">
        {responseStatus === "CORRECT" && (
          <>
            <FontAwesomeIcon icon={faCheckCircle} color={"#50A097"} />
            <span>{dynamicFeedback}</span>{" "}
          </>
        )}

        {responseStatus === "INCORRECT" && (
          <>
            <FontAwesomeIcon icon={faTimesCircle} color={"#94342A"} />
            <span>
              <FormattedMessage id="daily-quest-feedback-incorrect" defaultMessage="Oh no, that's a wrong answer!" />
            </span>{" "}
            {correctAnswer && (
              <>
                {" "}
                <br />
                <span className="daily-quest-feedback-correction">
                  <FormattedMessage id="daily-quest-correction-message" defaultMessage="Correct answer: " /> {correctAnswer}
                </span>
              </>
            )}
          </>
        )}
      </div>

      {isNextEnabled ? (
        <button onClick={handleNextClick}>
          <FontAwesomeIcon icon={faChevronCircleRight} size="lg" color={"#50A097"} />
        </button>
      ) : (
        <button disabled>
          <FontAwesomeIcon icon={faChevronCircleRight} size="lg" color={"#D3D3D3"} />
        </button>
      )}
    </div>
  )
}
export default DailyQuest
