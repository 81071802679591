import React, { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../Firebase"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImages, faSpinner } from "@fortawesome/free-solid-svg-icons"
import imageCompression from "browser-image-compression"
import { FormattedMessage, useIntl } from "react-intl"
import SubmitButton from "../portfolio/SubmitButton"
import { BarElement } from "chart.js"

let fileReader
if (typeof window !== "undefined") {
  fileReader = new FileReader()
}

const WallpaperImageModal = ({ currentImage }) => {
  const { firebase } = useContext(FirebaseContext)
  const [error, setError] = useState(null)
  const [fileUrl, setFileUrl] = useState(currentImage)
  const [fileBlob, setFileBlob] = useState(null)
  const [open, setOpen] = useState(false)
  const [isCompressing, setIsCompressing] = useState(false)
  const { formatMessage } = useIntl()
  const { locale } = useIntl()

  useEffect(() => {
    // set the preview image to current image, once available
    if (currentImage) {
      setFileUrl(currentImage)
    }
  }, [currentImage])

  /**
   * Validate image parameters and generate thumbnail.
   *
   * @param {SyntheticEvent} e
   */
  const handleImageChange = async e => {
    e.persist()
    const file = e.target.files[0]

    setError(null)

    if (!file) {
      return
    }

    const allowedTypes = ["image/jpg", "image/jpeg", "image/png"]

    if (!allowedTypes.includes(file.type)) {
      setError("File must be one of jpg, jpeg, png")
      return
    }

    const profileImageLabel = document.getElementById('profileImageLabel');
    if (file) {
      profileImageLabel.textContent = `${file.name}`;
    } else {
      profileImageLabel.textContent = locale === 'en-US' ? 'No file chosen' : 'Ningún archivo seleccionado';
    }
    try {
      const compressedImage = await imageCompression(file, {
        maxSizeMB: 2,
        onProgress: percent => {
          percent < 100 ? setIsCompressing(true) : setIsCompressing(false)
        },
      })
      setIsCompressing(false)

      // Triggered by readAsDataURL
      fileReader.onloadend = () => {
        setFileUrl(fileReader.result)

        // create image element in order to capture pixel dimensions
        const img = new Image()
        img.src = fileReader.result
      }

      fileReader.readAsDataURL(compressedImage)
      setFileBlob(compressedImage)
    } catch {
      setError("Something went wrong uploading your image")
    }
  }

  const handleSubmit = async () => {
    if (!fileBlob || error) {
      setError("You must upload a file before saving")
      return
    }

    // store the image
    try {
      const fileURL = await firebase.storeWallpaperImage(fileBlob)
      if (fileURL) {
        await firebase.updateProfile({ data: { wallpaperImageUrl: fileURL } })
      }
    } catch (e) {
      console.log(e)
      setError(
        "There was a problem updating your wallpaper. Please try again later."
      )
    }
    setOpen(false)
  }
  
  const handleClose = () => {
    setOpen(false)
    setFileUrl(currentImage)
  }

  return (
    <>
      <button
        style={{ backgroundColor: "transparent", display: "flex", justifyContent: "center", alignItems: "center" }}
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faImages} />
      </button>
      <Modal
        show={open}
        size="lg"
        onShow={() => {
          setError(null)
        }}
        onHide={handleClose}
        centered
        animation={false}
      >
        <Modal.Header
          style={{ justifyContent: "center", backgroundColor: "#F6F6F6" }}
          closeButton
        >
          <h3 style={{ marginBottom: "0" }}><FormattedMessage
            id="portfolio-wallpaper-image-change-wallpaper"
            defaultMessage="Change Wallpaper"
          /> </h3>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#F6F6F6" }}>
          <div>
            <h5><FormattedMessage
              id="portfolio-wallpaper-image-wallpaper-preview"
              defaultMessage="Wallpaper Preview"
            /></h5>
              {fileUrl && !isCompressing ? (
                <div
                  style={{
                    backgroundImage: `url(${fileUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    maxWidth: "560px",
                    height: "200px",
                    border: "1px dashed #bbb",
                    margin: "1rem auto",
                    overflow: "hidden",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "560px",
                    height: "200px",
                    border: "1px dashed #bbb",
                    backgroundColor: "white",
                    margin: "1rem auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="3x"
                    color="#4DA497"
                  />
                </div>
              )}
              <div className="form-element" style={{
                border: '1px solid #d3d3d3',
                padding: '10px',
                display: 'flex',
                alignItems: 'baseline',
                gap: '10px',
                borderColor: error ? "red" : ""
              }}>
                <label style={{
                  padding: '2px',
                  width: 'max-content',
                  border: '1px solid #565656',
                  cursor: 'pointer',
                  margin: '0px',
                  borderRadius: '3px'
                }}
                  className=" custom-file-input-label" htmlFor="profileImage">
                  {locale === 'en-US' ? 'Choose file' : 'Elija el archivo'}
                </label>
                <input
                  id="profileImage"
                  name="profileImage"
                  type="file"
                  onChange={handleImageChange}
                />
                <span id="profileImageLabel">{locale === 'en-US' ? 'No file chosen' : 'Ningún archivo seleccionado'}</span>
                {error && <small style={{ color: "red" }}>{error}</small>}
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            label={<FormattedMessage id="dashboard-cancel" defaultMessage="Cancel"/>}
            isFilled={false}
            onClick={handleClose}
          />
          <SubmitButton
            label={<FormattedMessage id="dashboard-this-week-save-button" defaultMessage="Save"/>}
            isFilled={true}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default WallpaperImageModal
