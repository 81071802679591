import React from "react"
import "./AddButton.css"

/**
 * Reusable "Add" button for the portfolio section
 */
const AddButton = ({label, onClick}) => {
  return (
    <button className="add-button" onClick={onClick}>
      {"+ "}{label}
    </button>
  )
}

export default AddButton
