import { Paragraph, TextRun, AlignmentType } from "docx";
import { renderEducationSection } from "./renderEducationSection";
import { renderLeadershipSection } from "./renderLeadershipSection";
import { renderWorkSection } from "./renderWorkSection";
import { renderSkillsInterestsSection } from "./renderSkillsInterestsSection";
import { renderVolunteerSection } from "./renderVolunteerSection";
import { renderAwardsSection } from "./renderAwardsSection";
import { renderLifeSection } from "./renderLifeSection";

const minimalistTemplate = (
  name,
  city,
  state,
  email,
  work,
  education,
  volunteer,
  awards,
  life,
  leadership,
  skills,
  interests,
  sectionOrder,
) => {
  const typeToSection = {
   "education": renderEducationSection(education),
    "work": renderWorkSection(work),
    "leadership": renderLeadershipSection(leadership),
    "volunteer": renderVolunteerSection(volunteer),
    "award": renderAwardsSection(awards),
    "life": renderLifeSection(life),
    "skills": renderSkillsInterestsSection("skills", skills),
    "interests": renderSkillsInterestsSection("interests", interests),
  }

  return {
    styles: {
      default: {
        document: {
          run: {
            font: "Cambria",
          },
        },
      },
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        children: [
          // Header section for Name and Contact Information
          new Paragraph({
            children: [
              new TextRun({
                text: `${name}`,
                size: 72,
                font: "Cambria",
                characterSpacing: 150,
              }),
              new TextRun({
                text: `${city || "CITY"}, ${state || "STATE"} | ${email}`,
                size: 20,
                break: 1,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          // Add an empty paragraph as a spacer
          new Paragraph({
            text: "",
            spacing: { after: 200 },
          }),
          ...(sectionOrder.map(type => typeToSection[type] || []).flat()),
        ],
      },
    ],
  }
}

export default minimalistTemplate
