import { useIntl } from "react-intl"

/**
 * If user profiles still contain "About Me", "My Strengths", or "My Weaknesses"
 * sections, combines them into a single localized introduction string. If the
 * user already has an introduction saved, just returns that.
 *
 * @param {string} introduction - the user's saved introduction
 * @param {string} aboutMe - the user's saved about me section
 * @param {string} strengths - the user's saved strengths section
 * @param {string} weaknesses - the user's saved weaknesses section
 * @returns {string}
 */
const useBackwardsCompatibleIntroduction = (introduction="", aboutMe, strengths, weaknesses) => {
  const { formatMessage } = useIntl()

  if (!introduction) {
    const nonEmptyFields = []
    if (aboutMe) {
      const aboutMeString = formatMessage({
        id: "portfolio-personal-info-form-about-me",
        defaultMessage: "A little about me",
      })
      nonEmptyFields.push(`${aboutMeString}: ${aboutMe}`)
    }
    if (strengths) {
      const myStrengthsString = formatMessage({
        id: "portfolio-personal-info-form-my-strengths",
        defaultMessage: "My Strengths",
      })
      nonEmptyFields.push(`${myStrengthsString}: ${strengths}`)
    }
    if (weaknesses) {
      const myWeaknessesString = formatMessage({
        id: "portfolio-personal-info-form-my-challenges",
        defaultMessage: "My Challenges",
      })
      nonEmptyFields.push(`${myWeaknessesString}: ${weaknesses}`)
    }
    introduction = nonEmptyFields.join("\n")
  }

  return introduction
}

export default useBackwardsCompatibleIntroduction
