import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { faFileExclamation } from "@fortawesome/pro-regular-svg-icons"
import { faVideo } from "@fortawesome/pro-solid-svg-icons"
import WordThumbnail from "../../../static/assets/images/microsoft-word-icon.svg"
import useCheckIfMobile from "../hooks/useCheckIfMobile"
import "./FileDisplayer.css"

/**
 * Displays a user-uploaded file
 * @param {userFile} file
 * @param {boolean} isThumbnail
 */
const FileDisplayer = ({file, isThumbnail}) => {
  const { isMobile } = useCheckIfMobile()

  const fileURL = file.fileURL
  const fileType = file.fileType
  const thumbnailURL = file.thumbnailURL
  const c = isThumbnail ? "-thumbnail" : ""

  let fileDisplayElement = <FontAwesomeIcon className="loading-spinner" icon={faSpinner} spin size="lg" />

  if (file.errorMessage) {
    fileDisplayElement = (
      <>
        <FontAwesomeIcon icon={faFileExclamation} color="#8B0000" size="4x"/>
        <div className="error-message">
          ERROR
        </div>
      </>
    )
  } else if (["image/jpg", "image/jpeg", "image/png"].includes(fileType)) {
    fileDisplayElement = <img className={`image${c}`} src={fileURL} alt="user uploaded content"/>
  } else if (fileType === "video/mp4" && fileURL && isThumbnail && isMobile) {
    // many mobile browsers won't render video thumbnails
    fileDisplayElement = <FontAwesomeIcon icon={faVideo} size="4x"/>
  } else if (fileType === "video/mp4" && fileURL){
    fileDisplayElement = <video className={`video${c}`} src={fileURL} {...(isThumbnail ? {} : {controls: true})}/>
  } else if (fileType === "application/pdf" && isThumbnail && thumbnailURL) {
    fileDisplayElement = <img className="image-thumbnail" src={file.thumbnailURL} alt="user uploaded content"/>
  } else if (fileType === "application/pdf" && !isThumbnail && fileURL) {
    // on mobile browsers that can't display pdfs, just display the thumbnail
    fileDisplayElement = (
      <object className="pdf" data={fileURL} width="60%" height="100%">
        <img className="image-thumbnail" src={file.thumbnailURL} alt="user uploaded content"/>
      </object>
    )
  } else if (["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(fileType)) {
    fileDisplayElement = <img className={`word-icon`} src={WordThumbnail} alt="user uploaded word document"/> 
  }

  return (
    <div className="file-displayer">
      {fileDisplayElement}
    </div>
  )
}

export default FileDisplayer
