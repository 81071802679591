import {
  Paragraph,
  TextRun,
} from "docx"
import { createDurationString } from "../../utils/utils"

export const renderEducationSection = educationExperiences => {
  if (!educationExperiences || educationExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "EDUCATION",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...educationExperiences
      .map(experience => {
        let fieldString = experience.field || "FIELD OF STUDY"
        const school = experience.school || "SCHOOL"
        const location = experience.location || "LOCATION"
        const bulletPoints = experience.bulletPoints || []
        const startDate = experience.startDate || ""
        let endDate = experience.endDate || ""

        // If the experience includes a degree, add it to the field string
        if (experience.degree) {
          fieldString = `${experience.degree}, ${fieldString}`
        }

        // If the experience includes a startDate but not an endDate, then it's 
        // currently ongoing
        if (startDate && !endDate) {
          endDate = "Present"
        }

        return [
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: `${school}`,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${fieldString} | ${location}`,
              }),
              new TextRun({
                text: (startDate ? ` | ${createDurationString(startDate, endDate)}` : ""),
              }),
            ],
          }),
          ...bulletPoints
            .map(
              bulletPoint =>
                new Paragraph({
                  bullet: {
                    level: 0,
                  },
                  children: [
                    new TextRun({
                      text: bulletPoint,
                      font: "cambria",
                      size: 20,
                    }),
                  ],
                })
            )
        ]
      })
      .flat(),
  ]
}
