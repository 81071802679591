import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import "./SubmitButton.css"

/**
 * Reusable submit button for portfolio edit modals. Displays a loading spinner
 * while the onClick function is running.
 */
const SubmitButton = ({label, isFilled, onClick=(async () => null)}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  const buttonState = loading ? "loading" : 
                      isFilled ? "filled" : "unfilled"

  return (
    <button
      type="submit"
      className={`submit-button ${buttonState}`}
      onClick={handleClick}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
      ) : (
        label
      )}
    </button>
  )
}

export default SubmitButton
