import { useStaticQuery, graphql } from "gatsby"

export const useCareers = locale => {
  let { allCosmicjsCareers } = useStaticQuery(graphql`
    query {
      allCosmicjsCareers {
        edges {
          node {
            slug
            id
            title
            locale
            metadata {
              career_banner_image {
                url
                imgix_url
              }
              career_pathways_filter
              career_education_required_filter
              career_display_top_match
              career_salary_entry_level
              career_salary_experienced
              career_dress {
                value
              }
              career_physical_activity {
                value
              }
              career_salary_median
              career_demand {
                value
              }
              career_related_major {
                slug
                title
                metadata {
                  major_cip
                }
              }
              career_job_categories {
                title
                slug
              }
            }
          }
        }
      }
    }
  `)
  return allCosmicjsCareers.edges
    .filter(career => career.node.locale === locale)
    .map((career, index) => ({
      key: index,
      slug: career.node.slug,
      id: career.node.id,
      title: career.node.title,
      career_display_top_match: career.node.metadata.career_display_top_match,
      career_education_level: career.node.metadata.career_education_required_filter,
      career_dress: career.node.metadata.career_dress.value,
      career_activity: career.node.metadata.career_physical_activity.value,
      career_salary_entry_level: career.node.metadata.career_salary_entry_level,
      career_salary_median: parseFloat(career.node.metadata.career_salary_median?.replaceAll(",", "")),
      career_salary_experienced: career.node.metadata.career_salary_experienced,
      career_demand: career.node.metadata.career_demand.value,
      career_job_categories: career.node.metadata.career_job_categories,
      type: career.node.type,
      career_banner_image: career.node.metadata.career_banner_image?.imgix_url,
      career_related_major: career.node.metadata.career_related_major,
      locale: career.node.locale,
      career_pathways_filter: career.node.metadata.career_pathways_filter,
    }))
}
