import { Paragraph, TextRun } from "docx"

export const renderSkillsSection = skills => {
  if (!skills || skills.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "SKILLS",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    new Paragraph({
      spacing: {
        before: 50,
      },
      children: [
        new TextRun({
          text: `${skills.join(', ')}`,
        }),
      ],
    }),
  ]
}
