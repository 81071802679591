import {
  Document,
  Packer,
} from "docx"
import { saveAs } from "file-saver"

import { filterExperiencesByType, renderMonthName } from "../utils/utils.js"
import classicTemplate from "./classic/classicTemplate.js"
import minimalistTemplate from "./minimalist/minimalistTemplate.js"
import accentTemplate from "./accent/accentTemplate.js"
import modernTemplate from "./modern/modernTemplate.js"


/**
 * Formats/Cleans-up an experience's data before the experience gets passed to
 * the resume builder functions.
 * - Converts an experience's dates into locale-specific strings. Stores 
 *   the strings as the *startDate* and *endDate* properties of the experience
 * - Combines the experience's description and highlights into a single array.
 *   Stores the array as the *bulletPoints* property of the experience.
 * 
 * @param {*} experience - an experience from the resume builder
 */
const formatExperienceData = (experience) => {
  // Combine descriptions and non-empty highlights into a single list of bullet points
  const bulletPoints = []
  if (experience.description) {
    bulletPoints.push(experience.description)
  }
  if (experience.highlights) {
    const actualHighlights = experience.highlights.filter(highlight => !!highlight)
    bulletPoints.push(...actualHighlights)
  }
  experience.bulletPoints = bulletPoints

  // Convert the start and end dates to locale-specific strings
  if (experience.startDateMonth && experience.startDateYear) {
    experience.startDate = renderMonthName(experience.startDateMonth) + " " + experience.startDateYear
  }
  if (experience.endDateMonth && experience.endDateYear) {
    experience.endDate = renderMonthName(experience.endDateMonth) + " " + experience.endDateYear
  }
}

const GenerateResumeDOCX = async ({
  name,
  email,
  experiences,
  skills,
  interests,
  city,
  state,
  template,
  sectionOrder,
}) => {
  const work = filterExperiencesByType(experiences, "work")
  const education = filterExperiencesByType(experiences, "education")
  const volunteer = filterExperiencesByType(experiences, "volunteer")
  const awards = filterExperiencesByType(experiences, "award")
  const life = filterExperiencesByType(experiences, "life")
  const leadership = filterExperiencesByType(experiences, "leadership")

  const experienceLists = [work, education, volunteer, awards, life, leadership]
  experienceLists.forEach(
    experiences => experiences.forEach(
      experience => formatExperienceData(experience))
  )

  const templateFunctions = {
    CLASSIC: classicTemplate,
    ACCENT: accentTemplate,
    MINIMALIST: minimalistTemplate,
    MODERN: modernTemplate,
  }

  const selectedTemplateFunction =
    templateFunctions[template] || templateFunctions["CLASSIC"]

  let doc = new Document(
    selectedTemplateFunction(
      name || "NAME",
      city || "CITY",
      state || "STATE",
      email || "EMAIL",
      work,
      education,
      volunteer,
      awards,
      life,
      leadership,
      skills,
      interests,
      sectionOrder,
    )
  )

  try {
    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${name.replace(/\s+/g, "-").toLowerCase()}-resume.docx`);
  } catch (error) {
    console.error('Failed to create blob or save file:', error);
  }
}

export default GenerateResumeDOCX
