import React, { useState } from "react"
import MediaUploadModal from "./MediaUploadModal"
import FileDisplayer from "../../user-files/FileDisplayer"
import EditButton from "../../portfolio/EditButton"
import useSortableItem from "../../drag-and-drop/useSortableItem";
import useStoryboardPanel from "../useStoryboardPanel"
import useUserFiles from "../../user-files/useUserFiles";
import "./MediaPanel.css"

/**
 * If a description is too long to fit in the panel, replaces the excess text 
 * with "...". Calculations rely on the fact that the text section of a MediaPanel
 * has the same width/max-height on all screen sizes, and use measurements in rem
 * from the css file
 *  
 * @param {string} description
 * @param {string} title 
 * @param {string} date 
 * @return {string} 
 */
const shortenDescription = (description, title, date) => {
  const zeroesPerTitleLine = 17.5
  const zeroesPerDescLine = 21
  const charsPerZero = 1.25
  const boxHeight = 11.375
  const titleLineHeight = 1.5
  const dateHeight = date ? (1.3125) : 0
  const descLineHeight = 1.22
  const unusableSpace = 0.65

  const titleHeight = titleLineHeight * Math.ceil(title.length / (charsPerZero * zeroesPerTitleLine))
  const verticalSpaceLeft = boxHeight - titleHeight - dateHeight - unusableSpace
  const maxDescLines = Math.floor(verticalSpaceLeft / descLineHeight)
  const maxDescChars = maxDescLines * (charsPerZero * zeroesPerDescLine)

  if (description.length < maxDescChars) {
    return description
  } else {
    return description.substring(0, maxDescChars - 2) + "..."
  }
}

/**
 * A single panel of a storyboard
 * @param {string} id - the unique id for this panel
 */
const MediaPanel = ({id}) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { title, description, month, year, dateString, fileIds} = useStoryboardPanel(null, id)
  const { filesList } = useUserFiles(null, "storyboard", fileIds.slice(0, 1))
  const { itemProperties } = useSortableItem({id: id})
  
  const shortenedDescription = shortenDescription(description, title, dateString)

  return (
    <div className="media-panel" {...itemProperties}>
      <div className="column image">
        {filesList.length > 0 && <FileDisplayer file={filesList[0]} isThumbnail={true}/>}
      </div>
      <div className="column text">
        <div className="title">{title}</div>
        {dateString && <div className="date">{dateString}</div>}
        {shortenedDescription && <div className="description">{shortenedDescription}</div>}
      </div>
      <EditButton onClick={() => setEditModalOpen(true)}/>
      <MediaUploadModal 
        isOpen={editModalOpen} 
        handleClose={() => setEditModalOpen(false)} 
        id={id}
        savedTitle={title}
        savedDescription={description}
        savedMonth={month}
        savedYear={year}
        savedFileIds={fileIds}
      />
    </div>
  )
}

export default MediaPanel
