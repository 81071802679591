import React from "react"
import Modal from "react-bootstrap/Modal"
import { FormattedMessage } from "react-intl"
import SubmitButton from "../../portfolio/SubmitButton"

const ResumeTipsModal = ({ show, onClose }) => {
  return (
    <Modal
      className="resume-tips-modal"
      show={show}
      onHide={() => onClose()}
      size="lg"
      animation={false}
    >
      <Modal.Header closeButton>
        <div className="eyebrow component-eyebrow"><FormattedMessage
          id="portfolio-tips-modal-tips"
          defaultMessage="Tips"
        /></div>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-1"
            defaultMessage="Tailor your resume for every job application."
          /></li>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-2"
            defaultMessage={`Use action words! For example, "Managed a crew of 8 technicians,"
                not "Was in charge of a crew of 8 technicians."`}
          />
          </li>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-3"
            defaultMessage={`Be as specific as possible. For example, "Managed a team of 8
                technicians in completing X projects, resulting in a Y% increase in
                productivity."`}
          />
          </li>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-4"
            defaultMessage={`Add keywords to your resume from the job posting.`}
          /></li>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-5"
            defaultMessage={`Craft compelling bullet points using the highlights fields.`}
          /></li>
          <li><FormattedMessage
            id="portfolio-tips-modal-list-6"
            defaultMessage={`Don't focus on tasks, instead explain what those tasks achieved.`}
          />
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <SubmitButton
          label={<FormattedMessage id="portfolio-tips-modal-back" defaultMessage="back"/>}
          isFilled={false}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ResumeTipsModal
