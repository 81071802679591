import React from "react"
import "./EditButton.css"

/**
 * Reusable edit button for the portfolio section
 */
const EditButton = ({onClick}) => {
  const penSVG = (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8614 1.00001C16.2231 0.998937 16.5815 1.06962 16.9157 1.20797C17.2499 1.34632 17.5533 1.54959 17.8084 1.80601L19.1954 3.19601C19.7108 3.71251 20.0003 4.41236 20.0003 5.14201C20.0003 5.87167 19.7108 6.57152 19.1954 7.08801L17.1374 9.14601L11.8544 3.86401L14.0544 1.66401H14.0694C14.5673 1.23416 15.2036 0.998395 15.8614 1.00001ZM17.1374 7.73101L18.4874 6.38201C18.8155 6.05335 18.9998 5.60792 18.9998 5.14351C18.9998 4.67911 18.8155 4.23368 18.4874 3.90501L17.0994 2.51401C16.9372 2.35092 16.7443 2.22161 16.5318 2.13355C16.3193 2.0455 16.0915 2.00045 15.8614 2.00101C15.6313 2.00037 15.4032 2.04538 15.1906 2.13343C14.9779 2.22149 14.7848 2.35084 14.6224 2.51401L13.2694 3.86401L17.1374 7.73101ZM11.8544 3.86401L17.2064 9.06401L8.48443 17.794C8.05836 18.2181 7.53366 18.5297 6.95743 18.701L2.72143 19.947C2.59919 19.9822 2.47263 20 2.34543 20C2.13616 20.0002 1.92973 19.9516 1.74258 19.8579C1.55543 19.7643 1.39274 19.6282 1.26745 19.4606C1.14217 19.293 1.05776 19.0984 1.02095 18.8924C0.984145 18.6864 0.99595 18.4747 1.05543 18.274L2.29943 14.04C2.47068 13.463 2.78271 12.9375 3.20743 12.511L11.8544 3.86401ZM16.3994 8.39701L12.5514 4.57201L3.91643 13.219C3.60962 13.5273 3.38421 13.907 3.26043 14.324L2.01543 18.556C1.99743 18.6153 1.99573 18.6784 2.01051 18.7385C2.02529 18.7987 2.056 18.8538 2.09943 18.898C2.144 18.9412 2.19913 18.972 2.25932 18.9873C2.3195 19.0026 2.38264 19.0018 2.44243 18.985L6.67343 17.741C7.08854 17.6168 7.46644 17.3918 7.77343 17.086L16.3994 8.39701Z" fill="currentColor"/>
      <path d="M11.8544 3.86401L17.1374 9.14601L19.1954 7.08801C19.7108 6.57152 20.0003 5.87167 20.0003 5.14201C20.0003 4.41236 19.7108 3.71251 19.1954 3.19601L17.8084 1.80601C17.5533 1.54959 17.2499 1.34632 16.9157 1.20797C16.5815 1.06962 16.2231 0.998937 15.8614 1.00001C15.2036 0.998395 14.5673 1.23416 14.0694 1.66401H14.0544L11.8544 3.86401ZM11.8544 3.86401L17.2064 9.06401L8.48443 17.794C8.05836 18.2181 7.53366 18.5297 6.95743 18.701L2.72143 19.947C2.59919 19.9822 2.47263 20 2.34543 20C2.13616 20.0002 1.92973 19.9516 1.74258 19.8579C1.55543 19.7643 1.39274 19.6282 1.26745 19.4606C1.14217 19.293 1.05776 19.0984 1.02095 18.8924C0.984145 18.6864 0.99595 18.4747 1.05543 18.274L2.29943 14.04C2.47068 13.463 2.78271 12.9375 3.20743 12.511L11.8544 3.86401ZM17.1374 7.73101L18.4874 6.38201C18.8155 6.05335 18.9998 5.60792 18.9998 5.14351C18.9998 4.67911 18.8155 4.23368 18.4874 3.90501L17.0994 2.51401C16.9372 2.35092 16.7443 2.22161 16.5318 2.13355C16.3193 2.0455 16.0915 2.00045 15.8614 2.00101C15.6313 2.00037 15.4032 2.04538 15.1906 2.13343C14.9779 2.22149 14.7848 2.35084 14.6224 2.51401L13.2694 3.86401L17.1374 7.73101ZM16.3994 8.39701L12.5514 4.57201L3.91643 13.219C3.60962 13.5273 3.38421 13.907 3.26043 14.324L2.01543 18.556C1.99743 18.6153 1.99573 18.6784 2.01051 18.7385C2.02529 18.7987 2.056 18.8538 2.09943 18.898C2.144 18.9412 2.19913 18.972 2.25932 18.9873C2.3195 19.0026 2.38264 19.0018 2.44243 18.985L6.67343 17.741C7.08854 17.6168 7.46644 17.3918 7.77343 17.086L16.3994 8.39701Z" stroke="currentColor"/>
    </svg>
  )
  
  return (
    <button className="edit-button" onClick={onClick}>
      {penSVG}
    </button>
  )
}

export default EditButton
