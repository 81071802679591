import React, { useContext, useState } from "react"
import { Dropdown } from "semantic-ui-react"
import { allowedMonthsNamesEs, allowedMonthsNames, allowedMonths } from "../careers/AllowedTypes"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { FormattedMessage, useIntl } from "react-intl"

/**
 * A time period component for experiences.
 *
 * @param experience
 *   The experience to render and update must be a `ExperienceDates` instance.
 *
 * @return {JSX.Element}
 */
const ExperienceDatesInput = ({ isSingle, required }) => {
  const {
    experience,
    setLocalExperienceKeyValue,
    toggleExperienceIsCurrent,
  } = useContext(ExperienceEditorContext)
  const { formatMessage, locale } = useIntl()

  const checkIfCurrent = experience =>
    experience?.endDateMonth === null && experience?.endDateYear === null

  const [isCurrentChecked, setIsCurrentChecked] = useState(
    checkIfCurrent(experience)
  )

  /**
   * Keys that map to internal experience date period.
   *
   * @type {string[]}
   *   Key names for dates.
   */
  const dateKeys = [
    "startDateMonth",
    "startDateYear",
    "endDateMonth",
    "endDateYear",
  ]

  /**
   * Default error messaging for each date.
   *
   * @type {{endDateYear: string, startDateMonth: string, startDateYear: string, endDateMonth: string}}
   */
  const defaultErrors = {
    startDateMonth: <FormattedMessage
      id="resume-experience-form-start-date-month-error"
      defaultMessage="The start date month entered is invalid please make sure it occurs before the end date."
    />,
    startDateYear: <FormattedMessage
      id="resume-experience-form-start-date-year-error"
      defaultMessage="The start date year entered is invalid please make sure it occurs before the end date and is a valid year."
    />,
    endDateMonth: <FormattedMessage
      id="resume-experience-form-end-date-month-error"
      defaultMessage="The end date month entered is invalid please make sure it occurs after the start date."
    />,
    endDateYear: <FormattedMessage
      id="resume-experience-form-end-date-year-error"
      defaultMessage="The end date year entered is invalid please make sure it occurs after the start date and is a valid year."
    />,
  }

  /**
   * Errors that should display for time period.
   */

  const [errors, setErrors] = useState({})

  /**
   * Current form date options.
   */
  const [dates, setDates] = useState({
    startDateMonth: experience?.startDateMonth ?? "",
    startDateYear: experience?.startDateYear ?? "",
    endDateMonth: experience?.endDateMonth ?? "",
    endDateYear: experience?.endDateYear ?? "",
  })

  /**
   * Gets the options list for months.
   *
   * @return {JSX.Element[]}
   *   Array of month options for a select list.
   */
  const getMonthOptions = () => {
    const options = []
    for (let i = 0; i < 12; i++) {
      options.push({
        key: `${i + 1}`,
        value: `${allowedMonths[i]}`,
        text: locale === 'en-US' ? `${allowedMonthsNames[i]}` : `${allowedMonthsNamesEs[i]}`,
      })
    }
    return options
  }

  /**
   * The number of current errors in the time period form.
   *
   * @return {number}
   *   The number of errors.
   */
  const errorCount = () => {
    let errorCounter = 0
    dateKeys.forEach(key => {
      if (errors[key]) {
        errorCounter++
      }
    })
    return errorCounter
  }

  /**
   * Checks if the error messaging can be cleared, and the experience dates corrected.
   *
   * @param exceptKey
   *   Check all values except the key provided.
   */
  const clearErrors = exceptKey => {
    if (errorCount() === 0) {
      return
    }
    dateKeys.forEach(key => {
      try {
        if (dates[key] && key !== exceptKey) {
          //   experience.period[key] = dates[key];
          errors[key] = null
        }
      } catch {
        errors[key] = defaultErrors[key]
      }
    })
    setErrors({ ...errors })
  }

  /**
   * Changes the start month and applies error messaging if needed.
   *
   * @param event
   *   The event.
   */
  const changeStartMonth = month => {
    setDates({ ...dates, startDateMonth: month })
    setLocalExperienceKeyValue("startDateMonth", month)
    try {
      //   experience.period.startDateMonth = month;
      if (errors.startDateMonth) {
        errors.startDateMonth = null
      }
      clearErrors("startDateMonth")
    } catch {
      //   experience.period.clearStartDateMonth();
      errors.startDateMonth = defaultErrors.startDateMonth
      setErrors({ ...errors })
    }
  }

  /**
   * Changes the end month applies error messaging if needed.
   *
   * @param event
   *   The event.
   */
  const changeEndMonth = month => {
    setDates({ ...dates, endDateMonth: month })
    setLocalExperienceKeyValue("endDateMonth", month)
    try {
      //   experience.period.endDateMonth = month;
      if (errors.endDateMonth) {
        errors.endDateMonth = null
      }
      clearErrors("endDateMonth")
    } catch {
      //   experience.period.clearEndDateMonth();
      errors.endDateMonth = defaultErrors.endDateMonth
      setErrors({ ...errors })
    }
  }

  /**
   * Changes the start year and applies error messaging if needed.
   *
   * @param event
   *   The event.
   */
  const changeStartYear = event => {
    const year = event.target.value
    setDates({ ...dates, startDateYear: year })
    setLocalExperienceKeyValue("startDateYear", year)
    if (year.length < 4) {
      //   experience.period.clearStartDateYear();
      return
    }
    try {
      //   experience.period.startDateYear = year;
      if (errors.startDateYear) {
        errors.startDateYear = null
      }
      clearErrors("startDateYear")
    } catch {
      //   experience.period.clearStartDateYear();
      errors.startDateYear = defaultErrors.startDateYear
      setErrors({ ...errors })
    }
  }

  /**
   * Changes the end year and applies error messaging if needed.
   *
   * @param event
   *   The event.
   */
  const changeEndYear = event => {
    const year = event.target.value
    dates.endDateYear = year
    setDates({ ...dates })
    setLocalExperienceKeyValue("endDateYear", year)
    if (year.length < 4) {
      //   experience.period.clearEndDateYear();
      return
    }
    try {
      //   experience.period.endDateYear = year;
      if (errors.endDateYear) {
        errors.endDateYear = null
      }
      clearErrors("endDateYear")
    } catch {
      //   experience.period.clearEndDateYear();
      errors.endDateYear = defaultErrors.endDateYear
      setErrors({ ...errors })
    }
  }

  const handleSetCurrent = e => {
    if (e.target.checked) {
      setIsCurrentChecked(true)
      toggleExperienceIsCurrent(true)
    } else {
      setIsCurrentChecked(false)
      toggleExperienceIsCurrent(false)
    }
  }

  /**
   * Renders the date errors that currently exist.
   *
   * @return {JSX.Element|null}
   *   List of error messaging.
   */
  const getErrors = () => {
    const formErrors = []
    if (errors.startDateMonth) {
      formErrors.push(
        <div key="start-month-error">{errors.startDateMonth}</div>
      )
    }
    if (errors.endDateMonth) {
      formErrors.push(<div key="end-month-error">{errors.endDateMonth}</div>)
    }
    if (errors.startDateYear) {
      formErrors.push(<div key="start-year-error">{errors.startDateYear}</div>)
    }
    if (errors.endDateYear) {
      formErrors.push(<div key="end-year-error">{errors.endDateYear}</div>)
    }
    return formErrors.length > 0 ? (
      <div className="message message-error">{formErrors}</div>
    ) : null
  }

  return (
    <>
      <label><FormattedMessage
        id="resume-experience-form-time-period"
        defaultMessage="Time Period"
      /> {required && "*"}</label>

      {!isSingle && (
        <div
          className="form-element-date-groups"
          style={{ marginBottom: "1rem" }}
        >
          <span style={{ marginRight: "1rem" }}><FormattedMessage
            id="experience-dates-input-currently-ongoing"
            defaultMessage="Currently ongoing"
          />?</span>
          <input
            className="custom-checkbox"
            style={{ height: "1.2rem", width: "1.2rem" }}
            type="checkbox"
            checked={isCurrentChecked}
            onChange={handleSetCurrent}
          />
        </div>
      )}

      <div className="form-element form-element-date">
        <div className="form-element-date-groups">
          {!isSingle && (
            <>
              <Dropdown
                fluid
                selection
                icon="chevron down"
                options={getMonthOptions()}
                onChange={(e, d) => changeStartMonth(d.value)}
                className="ais-MenuSelect"
                placeholder={formatMessage({ id: "experience-dates-input-month", defaultMessage: "Month" })}
                style={{ minWidth: "10em" }}
                value={dates.startDateMonth}
              />
              <input
                onChange={changeStartYear}
                value={dates.startDateYear ?? ""}
                type="text"
                placeholder={formatMessage({ id: "experience-dates-input-year", defaultMessage: "Year" })}
              />
            </>
          )}

          {!isCurrentChecked && !isSingle && <span className="label"><FormattedMessage
            id="experience-dates-input-to"
            defaultMessage="to"
          /></span>}

          {!isCurrentChecked && (
            <>
              <Dropdown
                fluid
                selection
                icon="chevron down"
                options={getMonthOptions()}
                onChange={(e, d) => changeEndMonth(d.value)}
                className="ais-MenuSelect"
                placeholder={formatMessage({id: "experience-dates-input-month", defaultMessage: "Month"})}
                style={{ minWidth: "10em" }}
                value={dates.endDateMonth}
              />
              <input
                onChange={changeEndYear}
                value={dates.endDateYear ?? ""}
                type="text"
                placeholder={formatMessage({ id: "experience-dates-input-year", defaultMessage: "Year" })}
              />
            </>
          )}

          {getErrors()}
        </div>
      </div>
    </>
  )
}

export default ExperienceDatesInput
