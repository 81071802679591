import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal"
import ResumeTipsModal from "./tips/tips-modal"
import SubmitButton from "../portfolio/SubmitButton"
import { FormattedMessage } from "react-intl"
import { experienceTypeData } from "./utils/experienceTypes"

/**
 * Modal for adding a new resume experience of any type. Contains three modals
 * in total:
 * 1) A modal for selecting which type of experience to add
 * 2) A ResumeTipsModal
 * 3) A ResumeExperienceEditorModal for actually adding the new resume experience
 */
const ExperienceTypeSelector = ({ isSelectorModalOpen, closeSelectorModal }) => {
  const [selectedType, setSelectedType] = useState(null)
  const [tipsModalOpen, setTipsModalOpen] = useState(false)

  const handleClose = () => {
    setSelectedType(null)
    closeSelectorModal()
  }

  return (
    <>
      {/* first modal, for selecting */}
      <Modal
        className="selector-modal"
        show={isSelectorModalOpen && (selectedType === null) && !tipsModalOpen}
        onHide={handleClose}
        size="md"
        animation={false}
      >
        <Modal.Header closeButton>
          <div className="eyebrow component-eyebrow"> 
            <FormattedMessage
              id="portfolio-experience-selector-add-experience"
              defaultMessage="Add experience"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {experienceTypeData.map(section => (
            <div
              key={section.type}
              className="experience-type-row"
              onClick={() => setSelectedType(section.type)}
            >
              <span style={{ color: "#54d1c1" }}>
                <FontAwesomeIcon icon={section.icon} />
              </span>
              {" "}
              {section.label}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            label={<FormattedMessage id="portfolio-experience-selector-resume-tips" defaultMessage="Resume Tips"/>}
            isFilled={false}
            onClick={() => setTipsModalOpen(true)}
          />
        </Modal.Footer>
      </Modal>

      {/* second modal, for adding a new experience */}

      <ResumeTipsModal 
        show={tipsModalOpen}
        onClose={() => setTipsModalOpen(false)}
      />

      <ResumeExperienceEditorModal
        isNewExperience={true}
        show={selectedType !== null}
        type={selectedType}
        closeTimelineModal={() => handleClose()}
      />
    </>
  )
}

export default ExperienceTypeSelector
