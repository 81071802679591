import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import FileDisplayer from "../../user-files/FileDisplayer"
import FileDownloadLink from "../../user-files/FileDownloadLink"
import "./PublicPanelModal.css"

/**
 * Modal when a user clicks on a file thumbnail from a public storyboard
 * @prop {userFile[]} files - all files from this storyboard panel
 * @prop {number} openedFileIndex - index of the file that was clicked when opening this modal
 */
const PublicPanelModal = ({isOpen, handleClose, title, description, files, openedFileIndex}) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(null)
  
  const onClose = () => {
    handleClose()
    setSelectedFileIndex(null)
  }

  const displayIndex = (selectedFileIndex !== null) ? selectedFileIndex : openedFileIndex

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
      animation={false}
      size="xl"
      className="public-panel-modal"
    >
      <Modal.Header closeButton>
        <div className="heading">
          {title && <div className="title">{title}</div>}
          {description && <div className="description">{description}</div>}
        </div>
      </Modal.Header>

      <Modal.Body>
        {files.length !== 0 &&
          <div className="interactive-file">
            <FileDisplayer file={files[displayIndex]}/>
          </div>
        } 
      </Modal.Body>

      <Modal.Footer>
        <div className="thumbnail-selection">
          {files.map((f, index) => (
            <button 
              key={f.fileId}
              className={`file-selector ${(displayIndex === index) ? "" : "unselected"}`} 
              onClick={() => setSelectedFileIndex(index)}
            >
              <FileDisplayer file={f} isThumbnail={true}/>
            </button>
          ))}
        </div>
        <FileDownloadLink file={files[displayIndex]}/>
      </Modal.Footer>
    </Modal>
  )
}

export default PublicPanelModal
