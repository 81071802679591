import React, { useState } from "react"
import MediaUploadModal from "./MediaUploadModal.js";
import StoryboardPanelsContainer from "./StoryboardPanelsContainer.js";
import FileUploadButton from "../../user-files/FileUploadButton.js";
import allowedStoryboardFileTypes from "../allowedStoryboardFileTypes.js";
import { FormattedMessage } from "react-intl";
import "./Storyboard.css"

/**
 * The private/editable view of the current user's storyboard section
 */
const Storyboard = () => {
  const [newFiles, setNewFiles] = useState([])

  return (
    <div className="storyboard">
      <div className="upload-button-container">
        <div className="title">
          <FormattedMessage
            id="storyboard"
            defaultMessage="Storyboard"
          />
        </div>
        <div className="description">
          <FormattedMessage
            id="storyboard-instructions"
            defaultMessage="Upload images, videos, and documents that represent your projects."
          />
        </div>
        <FileUploadButton 
          processFiles={(files) => setNewFiles(files)}
          allowedTypes={allowedStoryboardFileTypes}
          id="storyboard"
        />
      </div>
      <StoryboardPanelsContainer/>
      <MediaUploadModal 
        isOpen={newFiles.length > 0} 
        handleClose={() => setNewFiles([])}
        newFiles={newFiles}
      />
    </div>
  )
}

export default Storyboard
