import { createHeader, createSubHeader, createBulletPoint, createSpacingLine } from "./componentHelpers"

export const renderWorkSection = (workExperiences) => {
  if (!workExperiences || workExperiences.length === 0) {
    return []
  }

  return [
    ...createHeader("WORK EXPERIENCE"),
    ...workExperiences
      .filter((experience) => experience)
      .flatMap(experience => {

        const title = experience.title || 'JOB TITLE'
        const employer = experience.employer || 'EMPLOYER'
        const location = experience.location || 'LOCATION'
        const startDate = experience.startDate || 'STARTDATE'
        const endDate = experience.endDate || 'Present'
        const bulletPoints = experience.bulletPoints || []

        return [
          createSubHeader(
            title,
            employer,
            location,
            startDate,
            endDate,
          ),
          bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ].flat()
      }),
  ]
}
