import {
  Paragraph,
  TextRun,
} from "docx"
import { createDurationString } from "../../utils/utils"

export const createHeader = text => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 28,
        }),
      ],
      border: {
        bottom: {
          color: "#000000",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
  ]
}

export const createSubHeader = (
  role,
  organization,
  location,
  startDate,
  endDate
) => {
  const duration = createDurationString(startDate, endDate)

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: role, 
          size: 24,
          bold: true,
        })
      ]
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: organization,
          size: 22,
        }),
        new TextRun({
          text: " | ",
          size: 22,
        }),
        new TextRun({
          text: location,
          size: 22,
        }),
        new TextRun({
          text: (duration ? ` | ${duration}` : ""),
          size: 22,
        }),
      ],
      spacing: { after: 100 },
    }),
  ]
}

export const createLifeSubHeader = (
  milestone,
  time,
) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: milestone, 
          size: 24,
        })
      ]
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: time,
          size: 22,
        }),
      ],
      spacing: { after: 100 },
    }),
  ]
}

export const createBulletPoint = text => {
  if (!text.trim()) return null

  return new Paragraph({
    text: text,
    bullet: {
      level: 0,
    },
    size: 20,
  })
}

export const createSpacingLine = new Paragraph({
  text: "",
})

export const createSkillsInterestsBullet = (text) => {
  if (!text.trim()) return null

  return new Paragraph({
    children: [
      new TextRun({
        text: text,
        size: 20,
      }),
    ],
  })
}
