import React from "react"
import { IntlProvider } from "react-intl"

function SimpleLocalize(props) {
  const messages = props.pageContext.messages
  const language = props.pageContext.language
  return (
    <IntlProvider 
      locale={language} 
      messages={messages}
      defaultRichTextElements={{
        i: (text) => <i>{text}</i>,
      }}
    >
      {props.children}
    </IntlProvider>
  )
}

export default SimpleLocalize
