import React from "react"
import PublicStoryboardPanel from "./PublicStoryboardPanel"
import useStoryboardPanelsOrder from "../useStoryboardPanelsOrder"
import "./PublicStoryboard.css"

/**
 * Public view of a storyboard
 */
const PublicStoryboard = ({userId}) => {
  const { panelIds } = useStoryboardPanelsOrder(userId)

  if (!panelIds || !panelIds.length) {
    return null
  }

  return (
    <div className="public-storyboard">
      {panelIds.map(panelId => (
        <PublicStoryboardPanel
          key={panelId} 
          userId={userId} 
          panelId={panelId} 
        />
      ))}
    </div>
  )
}

export default PublicStoryboard
