import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from "react-intl";
import "./FileDownloadLink.css"

/**
 * Link for downloading a user-uploaded file
 * @param {userFile} file 
 */
const FileDownloadLink = ({file}) => {
  if (!file || !file.fileURL) {
    return null
  }

  return (
    <a 
      className="file-download-link"
      href={file.fileURL} 
      download 
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faArrowToBottom} size="lg" color="#54757A" />
      <FormattedMessage id="download-file" defaultMessage="Download File"/>
    </a>
  )
}

export default FileDownloadLink
