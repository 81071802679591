import { useEffect, useState, useContext } from "react"
import { FirebaseContext } from "../Firebase"
import { renderMonthName } from "../resume/utils/utils"
import { useIntl } from "react-intl"

/**
 * Fetch the data for a single storyboard panel
 * 
 * @param {string} userId 
 * @param {string} panelId 
 * 
 * @returns {string} title
 * @returns {string} description
 * @returns {string} month - the month, formatted as a two character numeric string (for example, "01" for January)
 * @returns {string} year
 * @returns {string} dateString - a localized date string of the form "Month Year"
 * @returns {string[]} fileIds - ordered array of all file ids for this panel
 */
const useStoryboardPanel = (userId, panelId) => {
  const { firebase } = useContext(FirebaseContext)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [fileIds, setFileIds] = useState([])
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const { locale } = useIntl()

  useEffect(() => {
    if (firebase && panelId) {
      const unsubscribeFunction = firebase.subscribeToPanelChanges(userId, panelId, setTitle, setDescription, setMonth, setYear, setFileIds)
      return () => unsubscribeFunction()
    }
  }, [firebase, panelId, userId])

  const dateString = (month && year) ? `${renderMonthName(month, locale)} ${year}` : ""

  return {
    title,
    description,
    month,
    year,
    dateString,
    fileIds,
  }
}

export default useStoryboardPanel
