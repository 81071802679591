const allowedStoryboardFileTypes = [
  "image/jpg", 
  "image/jpeg", 
  "image/png",
  "video/mp4",
  "application/pdf",
  "application/msword", 
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
]

export default allowedStoryboardFileTypes
