import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EditButton from "./EditButton.js"
import IntroductionEditModal from "./IntroductionEditModal.js"
import useBackwardsCompatibleIntroduction from "./useBackwardsCompatibleIntroduction.js"
import "./PortfolioIntroduction.css"
import "./placeholder-section-styles.css"

/**
 * The introduction section of a user's public or private portfolio
 */
const PortfolioIntroduction = ({profile = {}, isPublicFacing}) => {
  const [editing, setEditing] = useState(false)

  const profileQuote = profile.profileQuote || ""
  const city = profile.city || ""
  const state = profile.state || ""
  const aboutMe = profile.aboutMe || ""
  const myStrengths = profile.myStrengths || ""
  const myWeaknesses = profile.myWeaknesses || ""
  const introduction = useBackwardsCompatibleIntroduction(profile.introduction, aboutMe, myStrengths, myWeaknesses)

  if (isPublicFacing && !introduction && !profileQuote) {
    return null
  }

  return (
    <div className="portfolio-introduction-container">
      <IntroductionEditModal
        show={editing}
        savedQuote={profileQuote}
        savedIntroduction={introduction}
        savedCity={city}
        savedState={state}
        onClose={() => setEditing(false)}
      />
      {profileQuote && (
        <div className="editable-text-container">
          <q>{profileQuote}</q>
          {!isPublicFacing && <EditButton onClick={() => setEditing(true)}/>}
        </div>
      )}
      {introduction && (
        <div className="blue-container">
          <div className="introduction-header">
            <h3>
              <FormattedMessage
                id="portfolio-personal-info-form-introduction"
                defaultMessage="Introduction"
              />
            </h3>
            {(city || state) && (
              <div className="location">
                {`${city}${city && state && ", "}${state}`}
              </div>
            )}
          </div>
          <div className="editable-text-container">
            <div className="introduction-text">{introduction}</div>
            {!isPublicFacing && <EditButton onClick={() => setEditing(true)}/>}
          </div>
        </div>
      )}
      {!isPublicFacing && !introduction && !profileQuote && (
        <>
          <EditButton onClick={() => setEditing(true)}/>
          <div className="placeholder-title">
            <FormattedMessage
              id="portfolio-personal-info-form-introduction"
              defaultMessage="Introduction"
            />
          </div>
          <div className="placeholder-instructions">
            <FormattedMessage
              id="portfolio-introduction-placeholder-instructions"
              defaultMessage="Describe what makes you unique or share a meaningful quote."
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PortfolioIntroduction
