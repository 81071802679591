import { Paragraph, TextRun } from "docx"

export const renderLifeSection = lifeExperiences => {
  if (!lifeExperiences || lifeExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "LIFE EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...lifeExperiences
      .map(experience => {
        const {
          milestone = "MILESTONE",
          bulletPoints = [],
          endDate = "",
        } = experience
        return [
          new Paragraph({
            children: [
              new TextRun({
                text: `${milestone}`,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${endDate}`,
                size: 20,
              }),
            ],
          }),
          ...bulletPoints.map(bulletPoint =>
            new Paragraph({
              bullet: {
                level: 0,
              },
              children: [
                new TextRun({
                  text: bulletPoint,
                  font: "cambria",
                  size: 20,
                }),
              ],
            }),
          )
        ]
      })
      .flat(),
  ]
}
