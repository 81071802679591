import { createHeader, createSubHeader, createBulletPoint, createSpacingLine } from "./componentHelpers"

export const renderAwardsSection = (awardsExperiences) => {
  if (!awardsExperiences || awardsExperiences.length === 0) {
    return []
  }

  return [
    ...createHeader("AWARDS"),
    ...awardsExperiences
      .filter((experience) => experience)
      .flatMap((experience) => {
        const title = experience.title || "AWARD TITLE"
        const associatedOrg = experience.associatedOrg || "ASSOCIATED ORGANIZATION"
        const issuer = experience.issuer || "ISSUER"
        const date = experience.endDate || "DATE AWARDED"
        const bulletPoints = experience.bulletPoints || []

        return [
          ...createSubHeader(title, issuer, associatedOrg, "", date),
          ...bulletPoints.map(b => createBulletPoint(b)),
          createSpacingLine,
        ]
      }),
  ]
}
