import React from "react"
import PortfolioHeading from "./portfolio-heading"
import PortfolioIntroduction from "./PortfolioIntroduction"
import PortfolioBanner from "../../../static/assets/images/portfolio-banner-default.jpg"
import WallpaperImageModal from "../dashboard/portfolio-wallpaper-image-modal"
import Resume from "../resume/Resume"
import Storyboard from "../storyboard/editable-view/Storyboard"
import PublicStoryboard from "../storyboard/public-view/PublicStoryboard"
import useStoryboardPanelsOrder from "../storyboard/useStoryboardPanelsOrder"
import { FormattedMessage } from "react-intl"
import "./PortfolioContent.css"
import "./portfolio-heading.css"

/**
 * Displays the contents of a user's public or private portfolio
 * @prop {Object} profile - the profile of the user whose portfolio is being displayed
 * @prop {boolean} isPublicFacing - whether this is the public view of the portfolio
 * @prop {string} userId - the id of the user whose portfolio is being displayed
 */
const PortfolioContent = ({profile, isPublicFacing, userId}) => {
  const { panelIds } = useStoryboardPanelsOrder(userId)

  return (
    <div className="portfolio-content">
      <div className="wallpaper-container">
        <img 
          className="wallpaper"
          src={profile.wallpaperImageUrl || PortfolioBanner}
          alt="User's uploaded wallpaper"
        />
        {!isPublicFacing && (
          <div className="wallpaper-edit image-edit-button-container">
            <WallpaperImageModal currentImage={profile.wallpaperImageUrl || PortfolioBanner} />
          </div>
        )}
      </div>
      <div className="resume-section">
        <PortfolioHeading 
          profile={profile}
          isPublicFacing={isPublicFacing}
        /> 
        <PortfolioIntroduction 
          profile={profile}
          isPublicFacing={isPublicFacing}
        />
        <Resume
          userId={userId}
          isPublicFacing={isPublicFacing}
        />
      </div>
      {!isPublicFacing ? (
        <div className="editable-storyboard-section">
          <Storyboard />
        </div>
      ) : 
      panelIds.length > 0 ? (
        <div className="public-storyboard-section">
          <div className="storyboard-title">
            <FormattedMessage
              id="storyboard"
              defaultMessage="Storyboard"
            />
          </div>
          <PublicStoryboard userId={userId} />
        </div>
      ) : null}
      
    </div>
  )
}

export default PortfolioContent
