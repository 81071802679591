import { Paragraph, TextRun } from "docx"

export const renderLeadershipSection = leadershipExperiences => {
  if (!leadershipExperiences || leadershipExperiences.length === 0) {
    return []
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "LEADERSHIP EXPERIENCE",
          bold: true,
          size: 21,
        }),
      ],
      spacing: {
        before: 200,
      },
      border: {
        bottom: {
          color: "#d3d3d3",
          space: 1,
          style: "single",
          size: 6,
        },
      },
    }),
    ...leadershipExperiences
      .map(experience => {
        const {
          title = "TITLE",
          location = "LOCATION",
          affiliation = "AFFILIATION",
          bulletPoints = [],
          startDate = "",
          endDate = "current",
        } = experience

        return [
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: title,
                bold: true,
                size: 21,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${affiliation} | ${location} | ${startDate} - ${endDate}`,
              }),
            ],
          }),
          ...bulletPoints
            .map(
              bulletPoint =>
                new Paragraph({
                  bullet: {
                    level: 0,
                  },
                  children: [
                    new TextRun({
                      text: bulletPoint,
                      font: "cambria",
                      size: 20,
                    }),
                  ],
                })
            )
        ]
      })
      .flat(),
  ]
}
