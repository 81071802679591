import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import classicTemplateImage from '../../../../static/assets/images/resume-templates/classic-resume.png';
import accentTemplateImage from '../../../../static/assets/images/resume-templates/accent-resume.png';
import modernTemplateImage from '../../../../static/assets/images/resume-templates/modern-resume.png';
import minimalistTemplateImage from '../../../../static/assets/images/resume-templates/minimalist-resume.png';
import { FormattedMessage } from "react-intl"
import SubmitButton from '../../portfolio/SubmitButton';
import GenerateResumeDOCX from './generateDOCX';
import { FirebaseContext } from '../../Firebase';
import { defaultResumeColumnOneOrder, defaultResumeColumnTwoOrder } from '../utils/experienceTypes';
import './resume-doc-modal.css'

const ResumeDocModal = () => {
  const { firebase, profile, user } = useContext(FirebaseContext)
  const [selectedTemplate, setSelectedTemplate] = useState("CLASSIC")
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const determineSectionOrder = async () => {
    const savedColumnOneOrder = await firebase.getResumeColumnOrder("1")
    const savedColumnTwoOrder = await firebase.getResumeColumnOrder("2")

    const firstHalfOrder = savedColumnOneOrder || defaultResumeColumnOneOrder
    const secondHalfOrder = savedColumnTwoOrder || defaultResumeColumnTwoOrder

    return [...firstHalfOrder, ...secondHalfOrder]
  }

  const handleExportToWord = async () => {
    const resumeData = await firebase.getResumeData()
    if (!(resumeData?.experiences?.filter(e => !!e.id).length)
        && !(profile?.skills?.length)
        && !(profile?.skills?.length)) {
      alert("Cannot export: No resume items found")
      return
    }
    const allResumeExperiences = resumeData?.experiences?.filter(e => !!e.id) || []
    
    const sectionOrder = await determineSectionOrder()

    await GenerateResumeDOCX({
      name: `${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`,
      email: user?.email,
      experiences: allResumeExperiences,
      skills: profile?.skills || [], 
      interests: profile?.interests || [],
      city: profile?.city,
      state: profile?.state,
      template: selectedTemplate,
      sectionOrder: sectionOrder,
    })
    handleClose()
  }

  const templateImages = {
    CLASSIC: classicTemplateImage,
    ACCENT: accentTemplateImage,
    MINIMALIST: minimalistTemplateImage,
    MODERN: modernTemplateImage,
  };
  
  const templateNames = {
    CLASSIC: "Classic",
    ACCENT: "Accent",
    MINIMALIST: "Minimalist",
    MODERN: "Modern",
  }

  const selectTemplate = (template) => {
    setSelectedTemplate(template);
  };

  const renderTemplateSelection = () => (
    <div className="template-section">
      {Object.entries(templateImages).map(([key, src]) => (
        <div key={key} className="template-object">
          <p>
            <FormattedMessage 
              id={`resume-template-${templateNames[key].toLowerCase()}`} 
              defaultMessage={templateNames[key]} 
            />
          </p>
          <img
            key={key}
            src={src}
            alt={`${key} template`}
            className={`template-image ${
              selectedTemplate === key ? "template-image-selected" : ""
            }`}
            onClick={() => selectTemplate(key)}
          />
        </div>
      ))}
    </div>
  )

  return (
    <>
      <button
        className="export-button"
        onClick={handleShow}
        title="Export"
      >
        <FormattedMessage id="portfolio-export" defaultMessage="Export" />
        {" "}
        <FontAwesomeIcon
          icon={faArrowToBottom}
          size="lg"
          color="#54757A"
        />
      </button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="no-margin-title">
            <FormattedMessage id="resume-choose-template" defaultMessage="Choose a Resume Template" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderTemplateSelection()}
          <div className="export-buttons">
            <SubmitButton 
              label={<FormattedMessage id="resume-export-to-word" defaultMessage="Export to Word" />}
              isFilled={true}
              onClick={handleExportToWord}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default ResumeDocModal;
