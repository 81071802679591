import React from "react"
import { FormattedMessage } from "react-intl"
import {
  faBriefcase,
  faSchool,
  faHandsHelping,
  faUser,
  faUsers,
  faMedal,
  // faBook,
} from "@fortawesome/free-solid-svg-icons"
import {
  faStars,
  faHeartCircle,
  // faFileCertificate,
} from "@fortawesome/pro-solid-svg-icons"

/* ------------------------- READ ME ------------------------------------------
 * This file attempts to make it easier to add/modify experience types or make 
 * components that iterate through all possible resume experience types. Patrick
 * plans to finish it up when adding "Coursework" and "Certifications and Licenses"
 * as new experience types */

/**
 * @typedef {Object} ExperienceType
 * @property {string} type
 * @property {JSX} icon
 * @property {JSX} label
 * @property {JSX} abbreviatedLabel
 * @property {JSX} instructions
 */

/**
 * @type {ExperienceType[]}
 */
const experienceTypeData = [
  {
    type: "education",
    icon: faSchool,
    label: (
      <FormattedMessage
        id="portfolio-experience-selector-education"
        defaultMessage="Education"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="portfolio-experience-selector-education"
        defaultMessage="Education"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-education-instructions"
        defaultMessage="Don't hold back. Highlight your degrees, certificates, and any
                relevant coursework."
      />
    ),
  },
  {
    type: "work",
    icon: faBriefcase,
    label: (
      <FormattedMessage
        id="resume-timeline-work-experience"
        defaultMessage="Work Experience"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="resume-timeline-work"
        defaultMessage="Work"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-work-instructions"
        defaultMessage="List all of your past work experiences to highlight your job
                skills. Include job titles, company names, location, and dates of
                employment."
      />
    ),
  },
  {
    type: "volunteer",
    icon: faHandsHelping,
    label: (
      <FormattedMessage
        id="portfolio-experience-selector-volunteer"
        defaultMessage="Volunteer"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="portfolio-experience-selector-volunteer"
        defaultMessage="Volunteer"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-volunteer-instructions"
        defaultMessage="Your volunteer experience speaks volumes about the kind of person
                you are. Include the organization's name, your role, and the dates
                of your involvement."
      />
    )
  },
  {
    type: "leadership",
    icon: faUsers,
    label: (
      <FormattedMessage
        id="portfolio-experience-selector-leadership"
        defaultMessage="Leadership"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="portfolio-experience-selector-leadership"
        defaultMessage="Leadership"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-leadership-instructions"
        defaultMessage=" Let the world know you are a natural-born leader by listing your
                previous leadership roles or accomplishments."
      />
    )
  },
  {
    type: "award",
    icon: faMedal,
    label: (
      <FormattedMessage
        id="portfolio-experience-selector-honors-and-awards"
        defaultMessage="Honors and Awards"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="resume-timeline-award"
        defaultMessage="Award"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-award-instructions"
        defaultMessage="Set yourself apart by listing your accomplishments. This includes
                academic awards, professional recognitions, or anything else that
                makes you shine."
      />
    )
  },
  {
    type: "life",
    icon: faUser,
    label: (
      <FormattedMessage
        id="portfolio-experience-selector-life"
        defaultMessage="Life"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="portfolio-experience-selector-life"
        defaultMessage="Life"
      />
    ),
    instructions: (
      <FormattedMessage
        id="resume-experience-editor-modal-life-instructions"
        defaultMessage="Don't underestimate the value of your life experiences. List any
                non-traditional experiences that demonstrate your skills and
                qualifications. It could be anything from running a business to
                moving to a new city."
      />
    )
  },
  {
    type: "skills",
    icon: faStars,
    label: (
      <FormattedMessage
        id="profile-skills"
        defaultMessage="Skills"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="profile-skills"
        defaultMessage="Skills"
      />
    ),
    instructions: (
      <FormattedMessage
        id="portfolio-skills-skills-description"
        defaultMessage="Ask yourself, what am I good at? Sit with yourself and 
                        see what surfaces. Try to aim for no more than 20 skills."
      />
    )
  },
  {
    type: "interests",
    icon: faHeartCircle,
    label: (
      <FormattedMessage
        id="profile-interests"
        defaultMessage="Interests"
      />
    ),
    abbreviatedLabel: (
      <FormattedMessage
        id="profile-interests"
        defaultMessage="Interests"
      />
    ),
    instructions: (
      <FormattedMessage
        id="portfolio-interests-intrest-description"
        defaultMessage="Ask yourself, what do I like? Listen to your inner voice 
                        and honor it."
      />
    )
  },
  // {
  //   type: "certificate",
  //   icon: faFileCertificate,
  //   label: (
  //     <FormattedMessage
  //       id="resume-timeline-licenses-and-certifications"
  //       defaultMessage="Licenses and Certifications"
  //     />
  //   ),
  //   abbreviatedLabel: (
  //     <FormattedMessage
  //       id="resume-timeline-license-or-certification"
  //       defaultMessage="License/Certification"
  //     />
  //   ),
  //   instructions: (
  //     <FormattedMessage
  //       id="resume-experience-editor-modal-certificate-instructions"
  //       defaultMessage="certificate instructions"
  //     />
  //   )
  // },
  // {
  //   type: "coursework",
  //   icon: faBook,
  //   label: (
  //     <FormattedMessage
  //       id="resume-timeline-coursework"
  //       defaultMessage="Coursework"
  //     />
  //   ),
  //   abbreviatedLabel: (
  //     <FormattedMessage
  //       id="resume-timeline-coursework"
  //       defaultMessage="Coursework"
  //     />
  //   ),
  //   instructions: (
  //     <FormattedMessage
  //       id="resume-experience-editor-modal-coursework-instructions"
  //       defaultMessage="coursework instructions"
  //     />
  //   )
  // },
]

const experienceTypes = experienceTypeData.map(e => e.type)

const defaultResumeColumnOneOrder = ["education", "work", "life", "volunteer"]
const defaultResumeColumnTwoOrder = ["skills", "interests", "award", "leadership"]

const expMap = {}
experienceTypeData.forEach(t => expMap[t.type] = t)

const getExperienceLabel = (experienceType) => expMap[experienceType].label
const getExperienceAbbreviatedLabel = (experienceType) => expMap[experienceType].abbreviatedLabel
const getExperienceInstructions = (experienceType) => expMap[experienceType].instructions

export {
  experienceTypeData,
  experienceTypes,
  getExperienceLabel,
  getExperienceAbbreviatedLabel,
  getExperienceInstructions,
  defaultResumeColumnOneOrder,
  defaultResumeColumnTwoOrder,
}
