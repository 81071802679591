import { useState, useEffect, useContext } from "react"
import { FirebaseContext } from "../Firebase/index.js"

/**
 * Fetch a user's resume data
 * 
 * @param {string} userId - the id of the user whose resume data is being fetched
 * 
 * @returns {Object[]} experiences - array of the user's resume experiences
 * @returns {string[]} skills - ordered array of the user's skills
 * @returns {string[]} interests - ordered array of the user's interests
 */
const useResumeData = (userId) => {
  const { firebase } = useContext(FirebaseContext)
  const [experiences, setExperiences] = useState([])
  const [skills, setSkills] = useState([])
  const [interests, setInterests] = useState([])

  useEffect(() => {
    const updateResumeExperiences = (updatedExperiences) => {
      setExperiences(updatedExperiences.filter(exp => !!exp.id))
    }
    if (firebase && userId) {
      const unsubscribe = firebase.subscribeToResumeExperienceChanges(userId, updateResumeExperiences)
      return () => unsubscribe()
    }
  }, [firebase, userId])

  useEffect(() => {
    if (firebase && userId) {
      const unsubscribe = firebase.subscribeToSkillsInterestsChanges(userId, setSkills, setInterests)
      return () => unsubscribe()
    }
  }, [firebase, userId])

  return {
    experiences,
    skills,
    interests,
  }
}

export default useResumeData
