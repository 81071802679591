import { 
  Paragraph, 
  TextRun, 
  Tab,
  TabStopType,
} from "docx"
import { renderEducationSection } from "./renderEducationSection"
import { renderAwardsSection } from "./renderAwardsSection"
import { renderWorkSection } from "./renderWorkSection"
import { renderLeadershipSection } from "./renderLeadershipSection"
import { renderSkillsInterestsSection } from "./renderSkillsInterestsSection"
import { renderVolunteerSection } from "./renderVolunteerSection"
import { renderLifeSection } from "./renderLifeSection"
import { createSpacingLine } from "./componentHelpers"

const modernTemplate = (name, city, state, email, work, education, volunteer, awards, life, leadership, skills, interests, sectionOrder) => {
  const typeToSection = {
    "education": renderEducationSection(education),
    "work": renderWorkSection(work),
    "leadership": renderLeadershipSection(leadership),
    "volunteer": renderVolunteerSection(volunteer),
    "award": renderAwardsSection(awards),
    "life": renderLifeSection(life),
    "skills": renderSkillsInterestsSection("skills", skills),
    "interests": renderSkillsInterestsSection("interests", interests),
  }

  return {
    styles: {
      default: {
        document: {
          run: {
            font: "Calibri",
          },
        },
      },
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        children: [
          // Header section for Name and Contact Information
          ...createResumeHeader({
            name: name,
            location: `${city || "CITY"}, ${state || "STATE"}`,
            email: email,
            phone: "PHONE",
            website: "WEBSITE",
          }),
          createSpacingLine,
          ...(sectionOrder.map(type => typeToSection[type] || []).flat()),
        ],
      },
    ],
  }
}

/**
 * Generates the header section for the modern template
 */
const createResumeHeader = ({ name, location, email, phone, website }) => {
  return [
    new Paragraph({
      children: [
        new TextRun({ 
          text: name, 
          size: 50 
        }),
      ]
    }),
    new Paragraph({
      tabStops: [
        {
          position: 2612.5,
          type: TabStopType.LEFT,
        },
        {
          position: 5225,
          type: TabStopType.LEFT,
        },
        {
          position: 7837.5,
          type: TabStopType.LEFT,
        },
      ],
      children: [
        new TextRun({ 
          children: [
            location,
            new Tab(),
            email,
            new Tab(),
            phone,
            new Tab(),
            website,
          ]
        }),
      ]
    })
  ]
}

export default modernTemplate
