import React from "react"
import ExperienceDatesInput from "./experience-dates-input"
import ExperienceTextInput from "./experience-text-input"
import ExperienceBulletsInput from "./experience-bullets-input"
import { FormattedMessage, useIntl } from "react-intl"

const ExperienceInputLeadership = ({ type }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-leadership-form-title-placeholder', defaultMessage: "Ex. President of Student Government" })}
        label={<FormattedMessage
          id="resume-experience-form-title"
          defaultMessage="Title"
        />}
        dataKey="title"
        required={true}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-leadership-form-affiliation-placeholder', defaultMessage: "Ex. Hope High School" })}
        label={<FormattedMessage
          id="resume-experience-form-affiliation"
          defaultMessage="Affiliation"
        />}
        dataKey="affiliation"
        required={true}
      />
      <ExperienceDatesInput
        label={<FormattedMessage
          id="resume-experience-form-time-period"
          defaultMessage="Time Period"
        />}
        dataKey="timePeriod"
        required={true}
        isSingle={false}
      />
      <ExperienceTextInput
        placeholder={formatMessage({ id: 'resume-experience-leadership-form-location-placeholder', defaultMessage: "Ex. Providence, RI" })}
        label={<FormattedMessage
          id="resume-experience-form-location"
          defaultMessage="Location"
        />}
        dataKey="location"
        required={true}
        type={type}
      />
      <ExperienceBulletsInput
        label={<FormattedMessage
          id="resume-experience-form-bullet-points"
          defaultMessage="Bullet Points"
        />}
        dataKey="highlights"
        type={type}
        placeholders={[
          formatMessage({ id: 'resume-experience-leadership-form-highlights-placeholder-1', defaultMessage: "Ex. Addressed concerns projected by the class including the cost of room and board." }),
          formatMessage({ id: 'resume-experience-leadership-form-highlights-placeholder-2', defaultMessage: "Ex. Started a recycling program." })
        ]}
      />
    </>
  )
}

export default ExperienceInputLeadership
