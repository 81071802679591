import React, { useState, useEffect, useRef, useContext, useReducer } from "react"
import _ from "lodash"
import CardCareers from "../cards/cards-careers"
import "./algolia.css"
import "./algolia-explore.css"
import { useQueryParam, JsonParam, NumberParam, BooleanParam } from "use-query-params"
import Link from "../../link"
import { FirebaseContext } from "../../components/Firebase"
import Pagination from "react-js-pagination"
import FuzzySearch from "fuzzy-search"
import LoadingSpinner from "../../components/loading/LoadingSpinner.js"
import { removeMissingCareers } from "../../components/assessment/utils/utils.js"
import OutsideClickHandler from "react-outside-click-handler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import EyeBrow from "../eyebrow/eyebrow"
import { FormattedMessage, useIntl } from "react-intl"
import { useCareers } from "../assessment/useCareers.js"
import { SelectButton } from "primereact/selectbutton"
import { getSchoolSpecificCareers } from "../../components/assessment/getSchoolSpecificCareers.js"

// reducer used to track state of career explore component and handle actions regarding pagination, search, and filters
const exploreReducer = (state, action) => {
  // incoming payloads
  const { newPage, newSearchState, newAllMatches, newStartingPage, isSchoolFilterOn } = action
  // inital state

  // activePage: 1,
  // searchState: "",
  // allMatches: [],
  // displayMatches: []

  switch (action.type) {
    case "SET_PAGE":
      const startingPoint = (newPage - 1) * 10
      const endingPoint = startingPoint + 10
      const subset = state.filteredMatches.slice(startingPoint, endingPoint)
      return { ...state, activePage: newPage, displayMatches: subset }
    case "SET_SEARCH_STATE":
      const searcher = new FuzzySearch(state.allMatches, ["title"], {
        caseSensitive: false,
      })
      const results = searcher.search(newSearchState.trim())

      const displayResults = results.slice(0, 10)
      return {
        ...state,
        searchState: newSearchState,
        filteredMatches: results,
        displayMatches: displayResults,
        activePage: 1,
      }
    case "LOAD_ALL_MATCHES":
      const initialStartingPoint = (newStartingPage - 1) * 10
      const initialEndingPoint = initialStartingPoint + 10
      const initialSubset = newAllMatches.slice(initialStartingPoint, initialEndingPoint)

      return {
        ...state,
        allMatches: newAllMatches,
        filteredMatches: newAllMatches,
        activePage: newStartingPage,
        displayMatches: initialSubset,
      }
    case "SET_SCHOOL_FILTER":
      return {
        ...state,
        isSchoolFilterOn: isSchoolFilterOn,
      }

    default:
      throw new Error("Action type not set")
  }
}

/*
 * title = The title of the page. ie - Financial Manager, Architect, etc.
 * filterReference = The Algolia filter reference. ie - story_school_reference
 * classContext = A class to override default styles. ie - career, company, major, etc.
 */

const AlgoliaCareersPersonalityExplore = (props) => {
  const { locale, formatMessage } = useIntl()
  const { profile, subdomainConfig, firebase } = useContext(FirebaseContext)
  const allCosmicjsCareers = useCareers(locale)
  const { subdomainPetersonsInstitutionId, subdomainSchoolName } = subdomainConfig

  const [state, dispatch] = useReducer(exploreReducer, {
    activePage: 1,
    searchState: "",
    allMatches: [],
    filteredMatches: [],
    displayMatches: [],
    isSchoolFilterOn: subdomainPetersonsInstitutionId ? true : false,
  })

  const { activePage, searchState, displayMatches, filteredMatches, isSchoolFilterOn } = state
  const [schoolFilterValueIndex, setSchoolFilterValueIndex] = useState(isSchoolFilterOn ? 0 : 1)
  const [schoolSpecificCareers, setSchoolSpecificCareers] = useState([])
  const [isLoadingScreen, setIsLoadingScreen] = useState(false)
  const [dislikedCareerSlugs, setDislikedCareerSlugs] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  const [filters, setFilters] = useState({
    salary: {},
    education: {},
    dress: {},
    activity: {},
  })


  const [pageParam, setPageParam] = useQueryParam("page", NumberParam)
  const [queryFilters, setQueryFilters] = useQueryParam("queryFilters", JsonParam)
  const [schoolFilterParam, setSchoolFilterParam] = useQueryParam("schoolFilter", BooleanParam)

  const ref = useRef(null)

  /**
   * Select button display option to toggle school filtering
   */
  const schoolFilterOptions = [`${subdomainSchoolName}`, locale === 'es-ES' ? "Todos los Programas" : "All Programs"]

  /**
   * handles school filter change when checkboxes toggled, updates URL, and sets state
   */
  const handleSchoolFilterChange = (e) => {
    const value = e.value
    setSchoolFilterValueIndex(schoolFilterOptions.indexOf(value))
    setQueryFilters(null)
    setPageParam(1)

    if (value === "All Programs" || "Todos los Programas") {
      // setDisplayCareers(allCosmicjsCareers)
      dispatch({
        type: "SET_SCHOOL_FILTER",
        isSchoolFilterOn: false,
      })
    } else {
      dispatch({
        type: "SET_SCHOOL_FILTER",
        isSchoolFilterOn: true,
      })
    }

    setSchoolFilterParam(value === "All Programs" || value === "Todos los Programas" ? false : true)
  }

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== activePage) {
      scrollToElementWithOffset(ref.current, 225, "smooth")
      setPageParam(pageNumber)
      dispatch({
        type: "SET_PAGE",
        newPage: pageNumber,
      })
    }
  }

  /**
   * opens and closes filter dropdown
   **/
  const handleFiltersOnClick = () => {
    if (!showFilters) {
      setShowFilters(true)
    } else {
      handleOutsideFilterClick()
      setShowFilters(false)
    }
  }

  /**
   * handles toggle off of showFilters and updates URL when clicking outside of filter dropdown
   * @param {*} e
   * @returns
   */
  const handleOutsideFilterClick = (e) => {
    // prevents click from firing when clicking scroll bar
    if (e?.target?.tagName === "HTML") return

    // reformat filters object into queryFilters refinementList format
    const newRefinements = Object.entries(filters).reduce((acc, [key, valueObj]) => {
      const trueValues = Object.keys(valueObj).filter((k) => valueObj[k])

      if (trueValues.length > 0) {
        acc[key] = trueValues
      }

      return acc
    }, {})

    // update `queryFilters` with the new refinement lists
    const updatedQueryFilters = {
      ...queryFilters,
      refinementList: newRefinements,
    }

    // check to see if filters changed, prevents URL setting when they are the same as current params
    const queryRefinementList = queryFilters ? queryFilters.refinementList : {}
    const filtersHaveChanged = !_.isEqual(queryRefinementList, updatedQueryFilters.refinementList) && !_.isEmpty(updatedQueryFilters.refinementList)

    // use the setQueryFilters to update the state
    showFilters && filtersHaveChanged && setQueryFilters(updatedQueryFilters)

    setShowFilters(false)
  }

  /**
   * updates filters state when checkbox is toggled and resets page to 1
   */
  const updateFilters = (filterType, filter, isChecked) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: {
        ...prevFilters[filterType],
        [filter]: isChecked,
      },
    }))
    setPageParam(1)
  }

  const inSalaryFilters = (career) => {
    const salaryDict = {
      "Not that Important": [0, 51000],
      Important: [51000, 150000],
      "Very Important": [150000, Infinity],
    }

    for (const salaryFilter in filters.salary) {
      if (filters.salary[salaryFilter]) {
        if (salaryDict[salaryFilter][0] <= career.career_salary_median && career.career_salary_median < salaryDict[salaryFilter][1]) {
          return true
        }
      }
    }

    return false
  }

  const inEducationFilters = (career) => {
    for (const educationFilter in filters.education) {
      if (filters.education[educationFilter]) {
        if (career.career_education_level?.includes(educationFilter)) {
          return true
        }
      }
    }

    return false
  }

  const inDressFilters = (career) => {
    for (const dressFilter in filters.dress) {
      if (filters.dress[dressFilter]) {
        if (career.career_dress == dressFilter) {
          return true
        }
      }
    }
  }

  const inActivityFilters = (career) => {
    for (const activityFilter in filters.activity) {
      if (filters.activity[activityFilter]) {
        if (career.career_activity == activityFilter) {
          return true
        }
      }
    }
  }

  useEffect(() => {
    setDislikedCareerSlugs(profile?.assessmentRecord?.dislikedCareers || [])
  }, [profile])

  // triggers when filters, disliked careers, or school filter changes, used to reset state
  useEffect(() => {
    let filteredCareers = allCosmicjsCareers.filter((career) => !dislikedCareerSlugs.includes(career.slug))

    // filters out careers that are not in the school specific careers list
    if (isSchoolFilterOn) {
      filteredCareers = filteredCareers.filter((career) => schoolSpecificCareers.some((schoolCareer) => schoolCareer.slug === career.slug))
    }

    // checks if at least one filter is applied
    const hasAtLeastOneFilter = Object.values(filters).some((filterType) => Object.values(filterType).some((filter) => filter))

    if (hasAtLeastOneFilter) {
      filteredCareers = filteredCareers.filter((career) => {
        return inSalaryFilters(career) || inEducationFilters(career) || inDressFilters(career) || inActivityFilters(career)
      })
    }

    if (profile?.assessmentRecord?.careerMatches) {
      // load only careers that are current avaliable in cosmic since results may be out of sync
      const currentCosmicCareers = removeMissingCareers(profile.assessmentRecord.careerMatches, filteredCareers)

      let startingPage = 1

      if (pageParam && pageParam !== activePage) {
        startingPage = pageParam
      }

      dispatch({
        type: "LOAD_ALL_MATCHES",
        newAllMatches: currentCosmicCareers,
        newStartingPage: startingPage,
      })
      dispatch({
        type: "SET_SEARCH_STATE",
        newSearchState: searchState,
      })
    }
  }, [filters, dislikedCareerSlugs, schoolSpecificCareers, isSchoolFilterOn])

  // syncs page with URL, filteredMatches load async so need to wait for them to load before page set
  useEffect(() => {
    if (pageParam) {
      dispatch({
        type: "SET_PAGE",
        newPage: pageParam,
      })
    }
  }, [pageParam, filteredMatches])

  // syncs school filtering with URL
  useEffect(() => {
    if (schoolFilterParam === true) {
      setSchoolFilterValueIndex(0)

      dispatch({
        type: "SET_SCHOOL_FILTER",
        isSchoolFilterOn: true,
      })
    } else if (schoolFilterParam === false) {
      setSchoolFilterValueIndex(1)

      dispatch({
        type: "SET_SCHOOL_FILTER",
        isSchoolFilterOn: false,
      })
    }
  }, [schoolFilterParam])

  // syncs filters with queryFilters if url changes
  useEffect(() => {
    let newFilters = {
      salary: {},
      education: {},
      dress: {},
      activity: {},
    }

    // parse queryFilters to update filters
    for (let key in queryFilters?.refinementList) {
      if (newFilters[key]) {
        queryFilters.refinementList[key].forEach((filterValue) => {
          newFilters[key][filterValue] = true
        })
      }
    }

    setFilters(newFilters)
  }, [queryFilters])

  // will display loading screen and scroll to component, need to use timeout to give loading time and mask scrolling
  // only fires if state is passed to link from "see more matches" button
  // looking for better solution
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.history.state.scrollToExplore === true || pageParam) {
        setIsLoadingScreen(true)
        setTimeout(() => {
          ref.current !== null && scrollToElementWithOffset(ref.current, 225, "auto")
        }, 1000)
        setTimeout(() => {
          setIsLoadingScreen(false)
        }, 1000)
      }
    }
  }, [ref.current])

  useEffect(() => {
    // gets school specific careers based on petersons majors data and cosmicjs careers which have linked majors
    if (!!firebase) {
      const getSchoolData = async () => {
        // get petersons school data, might be nothing
        const res = await firebase.getPetersonsSchoolData(subdomainPetersonsInstitutionId)
        const { majorsData = [] } = res?.data || {}

        const schoolSpecificCareers = getSchoolSpecificCareers(allCosmicjsCareers, majorsData)
        setSchoolSpecificCareers(schoolSpecificCareers)
      }

      getSchoolData()
    }
  }, [firebase])

  /**
   * Maps over the displayMatches and returns a list of CardCareers components, if the career is found in allCosmicjsCareers else returns null
   **/
  const FilteredMatchCards = displayMatches.map((orderedCareer) => {
    // find and merge career with career ID and correlation
    const foundCareer = allCosmicjsCareers.find((allCareer) => allCareer.slug === orderedCareer.slug)

    if (foundCareer) {
      return (
        <li className="ais-Hits-item" key={orderedCareer.id}>
          <CardCareers
            objectID={orderedCareer.id}
            img={foundCareer.career_banner_image}
            title={foundCareer.title}
            slug={foundCareer.slug}
            demand={foundCareer.career_demand}
            category={foundCareer?.career_job_categories?.title}
            contentType={"careers"}
            salaryEntry={foundCareer.career_salary_entry_level}
            salaryExperienced={foundCareer.career_salary_experienced}
            correlation={orderedCareer.correlation}
            thumbsFeedback={true}
            setDislikedCareerSlugs={setDislikedCareerSlugs}
          />
        </li>
      )
    } else {
      return null
    }
  }).filter((match) => match !== null)

  return (
    <>
      {isLoadingScreen && <LoadingSpinner />}
      <div
        className="ais-SearchBox algolia-careers-personality-explore"
        style={{ display: "flex", justifyContent: "space-between", maxWidth: "unset", flexWrap: "wrap", gap: "1rem" }}
      >
        <form noValidate="" className="ais-SearchBox-form" action="" role="search" onSubmit={(e) => e.preventDefault()} style={{maxWidth: '400px', minHeight: '45px'}}>
          <input
            type="search"
            placeholder={formatMessage({ id: "algolia-careers-personality-search-by-job-title", defaultMessage: "Search by job title" })}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            required=""
            maxLength="512"
            className="ais-SearchBox-input"
            onChange={(e) =>
              dispatch({
                type: "SET_SEARCH_STATE",
                newSearchState: e.target.value,
              })
            }
            value={searchState}
          ></input>
          <button type="submit" title="Submit your search query." className="ais-SearchBox-submit" style={{height:"45px"}}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="search"
              className="svg-inline--fa fa-search fa-w-16 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
              ></path>
            </svg>
          </button>
          {searchState.length > 0 && (
            <button
              type="reset"
              onClick={(e) =>
                dispatch({
                  type: "SET_SEARCH_STATE",
                  newSearchState: "",
                })
              }
              title="Clear the search query."
              className="ais-SearchBox-reset"
              hidden=""
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          )}
        </form>
        <div>
          {subdomainPetersonsInstitutionId && (
            <div className="results-school-toggle">
              <SelectButton
                value={schoolFilterOptions[schoolFilterValueIndex]}
                options={schoolFilterOptions}
                onChange={handleSchoolFilterChange}
                className="no-transition-selectbutton"
              />
            </div>
          )}{" "}
        </div>
      </div>

      <div className="stream stream-careers stream-careers-explore" ref={ref}>
        <OutsideClickHandler onOutsideClick={handleOutsideFilterClick}>
          <div className="stream-explore-filters stream-explore-filters-matches">
            <button className="stream-explore-filters-button" onClick={handleFiltersOnClick}>
              <FormattedMessage id="more-filters" defaultMessage="More Filters" />
              <FontAwesomeIcon
                className={`${!showFilters ? "show" : "hide"}`}
                icon={faChevronDown}
                style={{
                  color: "#363636",
                  marginLeft: ".5rem",
                }}
              />
              <FontAwesomeIcon
                className={`${showFilters ? "show" : "hide"}`}
                icon={faChevronUp}
                style={{
                  color: "#363636",
                  marginLeft: ".5rem",
                }}
              />
            </button>

            <div className={`explore-context-filter ${showFilters ? "show" : "hide"}`}>
              <h3>
                <FormattedMessage id="algolia-careers-personality-filter-careers-by" defaultMessage="Filter Careers by" />:
              </h3>
              <div className="filter filter-four">
                <EyeBrow
                  text={<FormattedMessage id="results-filter-salary" defaultMessage="Salary" />}
                  style={{
                    marginBottom: "1.5rem",
                  }}
                />
                <div className="ais-RefinementList">
                  <ul className="ais-RefinementList-list">
                    {salaryOptions.map((option, index) => (
                      <li key={index} className="ais-RefinementList-item">
                        <label className="ais-RefinementList-label">
                          <input
                            className="ais-RefinementList-checkbox"
                            type="checkbox"
                            checked={filters.salary[option.value] || false}
                            onChange={(event) => updateFilters("salary", option.value, event.target.checked)}
                          />
                          <span className="ais-RefinementList-labelText">{option.text}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="filter filter-four">
                <EyeBrow
                  text={<FormattedMessage id="results-filter-education" defaultMessage="Education" />}
                  style={{
                    marginBottom: "1.5rem",
                  }}
                />
                <ul className="ais-RefinementList-list">
                  {educationOptions.map((option, index) => (
                    <li key={index} className="ais-RefinementList-item">
                      <label className="ais-RefinementList-label">
                        <input
                          className="ais-RefinementList-checkbox"
                          type="checkbox"
                          checked={filters.education[option.value] || false}
                          onChange={(event) => updateFilters("education", option.value, event.target.checked)}
                        />
                        <span className="ais-RefinementList-labelText">{option.text}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="filter filter-four">
                <EyeBrow
                  text={<FormattedMessage id="results-filter-dress" defaultMessage="Dress" />}
                  style={{
                    marginBottom: "1.5rem",
                  }}
                />
                <ul className="ais-RefinementList-list">
                  {dressOptions.map((option, index) => (
                    <li key={index} className="ais-RefinementList-item">
                      <label className="ais-RefinementList-label">
                        <input
                          className="ais-RefinementList-checkbox"
                          type="checkbox"
                          checked={filters.dress[option.value] || false}
                          onChange={(event) => updateFilters("dress", option.value, event.target.checked)}
                        />
                        <span className="ais-RefinementList-labelText">{option.text}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="filter filter-four filter-last">
                <EyeBrow
                  text={<FormattedMessage id="algolia-careers-personality-physical-activity" defaultMessage="Physical Activity" />}
                  style={{
                    marginBottom: "1.5rem",
                  }}
                />
                <ul className="ais-RefinementList-list">
                  {activityOptions.map((option, index) => (
                    <li key={index} className="ais-RefinementList-item">
                      <label className="ais-RefinementList-label">
                        <input
                          className="ais-RefinementList-checkbox"
                          type="checkbox"
                          checked={filters.activity[option.value] || false}
                          onChange={(event) => updateFilters("activity", option.value, event.target.checked)}
                        />
                        <span className="ais-RefinementList-labelText">{option.text}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <ul className="explore-filters-list">
              <li className="explore-filters-list-item explore-filters-list-item-sort">
                <FormattedMessage id="sort-by" defaultMessage="Sort by" />:
              </li>
              <li className="explore-filters-list-item explore-filters-list-item-newest">
                <Link to="/dashboard?category=Careers&showSaved=0">
                  <FormattedMessage id="all" defaultMessage="All" />
                </Link>
              </li>

              <li className="explore-filters-list-item explore-filters-list-item-newest">
                <Link to="/dashboard?category=PersonalityCareers&personality=true" className={"active"}>
                  <FormattedMessage id="matches" defaultMessage="Matches" />
                </Link>
              </li>

              <li className="explore-filters-list-item explore-filters-list-item-saved">
                <Link to="/dashboard?category=Careers&showSaved=1">
                  <FormattedMessage id="algolica-careers-explore-saved" defaultMessage="Saved" />
                </Link>
              </li>
            </ul>
          </div>
        </OutsideClickHandler>

        <div className="ais-Hits">
          <ul className="ais-Hits-list">{FilteredMatchCards}</ul>
        </div>

        <div className="ais-Pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={filteredMatches.length}
            pageRangeDisplayed={7}
            onChange={handlePageChange}
            activeLinkClass="ais-Pagination-link ais-Pagination-link--selected"
            linkClass="ais-Pagination-link"
            innerClass="ais-Pagination-list"
            itemClass="ais-Pagination-item ais-Pagination-item--page"
            itemClassPrev="ais-Pagination-item ais-Pagination-item--disabled ais-Pagination-item--previousPage"
            hideFirstLastPages
            prevPageText={
              <span className="ais-Pagination-link">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-left"
                  className="svg-inline--fa fa-chevron-left fa-w-10 fa-sm "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                  ></path>
                </svg>
              </span>
            }
            nextPageText={
              <a className="ais-Pagination-link" aria-label="Next page" href="#">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-right"
                  className="svg-inline--fa fa-chevron-right fa-w-10 fa-sm "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  ></path>
                </svg>
              </a>
            }
          />
        </div>
      </div>
    </>
  )
}

const salaryOptions = [
  // Very Important, Important, Not that Important
  {
    key: "Not that Important",
    text: <FormattedMessage id="results-filter-not-that-important" defaultMessage="Not that Important" />,
    value: "Not that Important",
  },
  {
    key: "Important",
    text: <FormattedMessage id="results-filter-important" defaultMessage="Important" />,
    value: "Important",
  },
  {
    key: "Very Important",
    text: <FormattedMessage id="results-filter-very-important" defaultMessage="Very Important" />,
    value: "Very Important",
  },
]

const educationOptions = [
  // License, Certificate, Apprenticeship, 2-Year College, 4-Year College, Graduate School
  {
    key: "High School",
    text: <FormattedMessage id="results-filter-high-school" defaultMessage="High School" />,
    value: "High School",
  },
  {
    key: "License",
    text: <FormattedMessage id="results-filter-license" defaultMessage="License" />,
    value: "License",
  },
  {
    key: "Certificate",
    text: <FormattedMessage id="results-filter-certificate" defaultMessage="Certificate" />,
    value: "Certificate",
  },
  {
    key: "Apprenticeship",
    text: <FormattedMessage id="results-filter-apprenticeship" defaultMessage="Apprenticeship" />,
    value: "Apprenticeship",
  },
  {
    key: "2-year College",
    text: <FormattedMessage id="results-filter-2-year-college" defaultMessage="2-year College" />,
    value: "2-year College",
  },
  {
    key: "4-year College",
    text: <FormattedMessage id="results-filter-4-year-college" defaultMessage="4-year College" />,
    value: "4-year College",
  },
  {
    key: "Graduate School",
    text: <FormattedMessage id="results-filter-graduate-school" defaultMessage="Graduate School" />,
    value: "Graduate School",
  },
]

const dressOptions = [
  // Business, Business Casual, Casual
  {
    key: "casual",
    text: <FormattedMessage id="results-filter-casual" defaultMessage="Casual" />,
    value: "casual",
  },
  {
    key: "business casual",
    text: <FormattedMessage id="results-filter-business-casual" defaultMessage="Business Casual" />,
    value: "business casual",
  },
  {
    key: "business",
    text: <FormattedMessage id="results-filter-business" defaultMessage="Business" />,
    value: "business",
  },
  {
    key: "uniform",
    text: <FormattedMessage id="results-filter-uniform" defaultMessage="Uniform" />,
    value: "uniform",
  },
]

const activityOptions = [
  // Stationary, Moderately Active, Active
  {
    key: "stationary",
    text: <FormattedMessage id="results-filter-stationary" defaultMessage="Stationary" />,
    value: "stationary",
  },
  {
    key: "moderately active",
    text: <FormattedMessage id="results-filter-moderately-active" defaultMessage="Moderately Active" />,
    value: "moderately active",
  },
  {
    key: "active",
    text: <FormattedMessage id="results-filter-active" defaultMessage="Active" />,
    value: "active",
  },
]

function scrollToElementWithOffset(element, offset, behavior) {
  if (typeof window === "undefined") return

  const rect = element.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const elementTop = rect.top + scrollTop

  // Scroll to the element with the offset
  window.scrollTo({
    top: elementTop - offset,
    behavior: behavior || "smooth",
  })
}

export default AlgoliaCareersPersonalityExplore
