import React, { useState } from "react"
import ResumeExperienceEditorModal from "./ResumeExperienceEditorModal"
import { renderMonthName } from "./utils/utils.js"
import { useIntl } from "react-intl"
import EditButton from "../portfolio/EditButton.js"
import "./ResumeTimelineExperience.css"

/**
 * Displays a single resume experience
 */
const ResumeTimelineExperience = ({
  experienceData,
  isPublicFacing,
  isLastExperience,
}) => {
  const {
    type,
    employer = "",
    title = "",
    milestone = "",
    location = "",
    description = "",
    highlights = [],
    degree = "",
    issuer = "",
    associatedOrg = "",
    affiliation = "",
    school = "",
    field = "",
    startDateMonth,
    startDateYear,
  } = experienceData

  let endDateMonth = experienceData.endDateMonth
  let endDateYear = experienceData.endDateYear

  const collapseIcon = (
    <svg width="20" height="20" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z" fill="white"/>
      <path d="M9.5 17C13.9183 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.08172 17 9.5 17Z" stroke="currentColor" strokeWidth="2"/>
      <path d="M6.5 9H12.5" stroke="currentColor" strokeWidth="2"/>
    </svg>
  )

  const expandIcon = (
    <svg width="20" height="20" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 18C14.4706 18 18.5 13.9706 18.5 9C18.5 4.02944 14.4706 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18Z" fill="white"/>
      <path d="M9.5 17C13.9183 17 17.5 13.4183 17.5 9C17.5 4.58172 13.9183 1 9.5 1C5.08172 1 1.5 4.58172 1.5 9C1.5 13.4183 5.08172 17 9.5 17Z" stroke="currentColor" strokeWidth="2"/>
      <path d="M6.5 9H12.5" stroke="currentColor" strokeWidth="2"/>
      <path d="M9.5 6.05 V11.95" stroke="currentColor" strokeWidth="2"/>
    </svg>
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { locale, formatMessage } = useIntl()

  const closeTimelineModal = () => {
    setIsModalOpen(false)
  }

  // Date Processing
  let startDate = ""
  if (startDateMonth && startDateYear) {
    startDate = `${renderMonthName(startDateMonth, locale)} ${startDateYear}`
  }
  let endDate = ""
  if (endDateMonth && endDateYear) {
    endDate = `${renderMonthName(endDateMonth, locale)} ${endDateYear}`
  }
  if (startDate && !endDate) {
    endDate = formatMessage({id: "resume-timeline-present", defaultMessage: "Present"})
    const currentDate = new Date()
    endDateMonth = currentDate.getMonth() + 1
    endDateYear = currentDate.getFullYear()
  }
  let displayDuration = ""
  if (startDate && endDate) {
    const month = formatMessage({id: "resume-timeline-month", defaultMessage: "month"})
    const months = formatMessage({id: "resume-timeline-months", defaultMessage: "month"})
    const year = formatMessage({id: "resume-timeline-year", defaultMessage: "year"})
    const years = formatMessage({id: "resume-timeline-years", defaultMessage: "years"})

    const totalMonths = (endDateYear * 12) + parseInt(endDateMonth, 10) - (startDateYear * 12) - parseInt(startDateMonth, 10)
    const numYears = Math.floor(totalMonths / 12)
    const numMonths = totalMonths % 12

    const yearsString = (numYears > 0) ? `${numYears} ${numYears > 1 ? years : year}` : ""
    const monthsString = (numMonths > 0) ? `${numMonths} ${numMonths > 1 ? months : month}` : ""

    displayDuration = `${yearsString}${yearsString && monthsString && " "}${monthsString}`
  }
  let displayDateRange = `${startDate}${startDate && endDate && " - "}${endDate}`

  // Title processing
  let displayTitle = title
  if (type === "education") {
    displayTitle = `${degree}${degree && ", "}${field}`
  } else if (type === "life") {
    displayTitle = milestone
  }
  
  // Subtitle processing
  let displaySubtitle = ""
  let subtitle = ""
  if (type === "education" && school) {
    subtitle = school
  } else if ((type === "work" || type === "volunteer") && employer) {
    subtitle = employer
  } else if (type === "award" && (issuer || associatedOrg)) {
    const issuedBy = formatMessage({id: "resume-timeline-issued-by", defaultMessage: "Issued by"})
    const issuerString = issuer && `${issuedBy} ${issuer}`

    const associatedWith = formatMessage({id: "resume-timeline-associated-with", defaultMessage: "Associated with"})
    const associatedString = associatedOrg && `${associatedWith} ${associatedOrg}`
    
    subtitle = `${issuerString}${issuer && associatedOrg && " | "}${associatedString}`
  } else if (type === "leadership" && affiliation) {
    subtitle = affiliation
  }
  displaySubtitle = `${subtitle}${subtitle && location && " | "}${location}`

  // highlight processing
  const actualHighlights = highlights.filter(highlight => highlight)

  return (
    <div className={`resume-timeline-experience ${isLastExperience ? "resume-timeline-experience-final" : ""}`}>
      {!isPublicFacing && (
        <ResumeExperienceEditorModal
          type={type}
          experienceData={experienceData}
          show={isModalOpen}
          closeTimelineModal={closeTimelineModal}
          isNewExperience={false}
        />
      )}
      <button className="collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed ? expandIcon : collapseIcon}
      </button>
      <div className="experience-header">
        <div className="header-text-container">
          <div className="title-container">
            <div className="title">
              {displayTitle}
            </div>
            <div className="subtitle">
              {displaySubtitle}
            </div>
          </div>
          <div className="date-container">
            <div className="date-range">{displayDateRange}</div>
            <div className="duration">{displayDuration}</div>
          </div>
        </div>
        {!isPublicFacing && <EditButton onClick={() => setIsModalOpen(true)}/>}
      </div>
      {!isCollapsed && (
        <>
        {description && (
          <p className="description-container description-text">
            {description}
          </p>
        )}
        {actualHighlights.length > 0 && (
          <ul className="bullet-points-container">
            {actualHighlights.map((highlight, index) => (
              <li key={`highlight-${index}`} className="bullet-point description-text">
                {highlight}
              </li>
            ))}
          </ul>
        )}
        </>
      )}
    </div>
  )
}

export default ResumeTimelineExperience
